import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloLink, createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client';

import { Auth } from 'aws-amplify';
import config from '../api_config';

const staffUrl = config.AppSync.Staff.graphqlEndpoint;
const staffRegion = config.AppSync.Staff.region;

const publicUrl = config.AppSync.Public.graphqlEndpoint;
const publicRegion = config.AppSync.Public.region;

const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
};

const privateHttpLink = createHttpLink({ uri: staffUrl });
const publicHttpLink = createHttpLink({ uri: publicUrl });

const privateLink = ApolloLink.from([
  createAuthLink({ url: staffUrl, region: staffRegion, auth }),
  createSubscriptionHandshakeLink({ url: staffUrl, region: staffRegion, auth }, privateHttpLink),
]);

const publicLink = ApolloLink.from([
  createAuthLink({
    url: publicUrl,
    region: publicRegion,
    auth: {
      type: AUTH_TYPE.AWS_LAMBDA,
      token: 'public',
    },
  }),
  createSubscriptionHandshakeLink({ url: publicUrl, region: publicRegion, auth }, publicHttpLink),
]);

export const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'public',
    publicLink,
    privateLink
  ),
  cache: new InMemoryCache(),
});
