export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function pluralizationHelper(string: string, count: number) {
  const fullString = count + ' ' + string;
  const isOne = count === 1;
  if (!isOne) {
    return fullString;
  }
  return fullString.slice(0, -1);
}
