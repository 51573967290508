import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SetupLayout from '../layouts/setup';

import SettingsLayout from 'src/layouts/settings';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import MemberLayout from 'src/layouts/member';
import ClubLayout from 'src/layouts/club';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'confirm',
          element: (
            <GuestGuard>
              <Confirm />
            </GuestGuard>
          ),
        },
        {
          path: 'oauth',
          element: (
            <AuthGuard>
              <OAuth />
            </AuthGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    //Sign up Routes
    {
      path: 'setup',
      element: (
        <AuthGuard>
          <SetupLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'company', element: <SetupCompany />, index: true },
        { path: 'club', element: <SetupClub /> },
        { path: 'payment', element: <SetupPayment /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'members',
          children: [
            { path: '', element: <MemberList />, index: true },
            { path: 'new', element: <MemberCreate /> },
            {
              path: ':id',
              element: <MemberLayout />,
              children: [
                { path: 'home', element: <MemberHome />, index: true },
                { path: 'detail', element: <MemberDetail /> },
                { path: 'billing', element: <MemberBilling /> },
                { path: 'membership', element: <MemberMembership /> },
                { path: 'history', element: <MemberHistory /> },
                { path: 'accessControl', element: <AccessControl /> },
                { path: 'membership/select', element: <MembershipSelect /> },
                { path: 'membership/payment', element: <MembershipPayment /> },
                { path: 'membership/signature', element: <MembershipSignature /> },
              ],
            },
          ],
        },
        {
          path: 'class/types',
          children: [
            { path: 'list', element: <ClassTypeList />, index: true },
            { path: 'new', element: <ClassTypeCreate /> },
            { path: ':id', element: <ClassTypeEdit /> },
          ],
        },
        {
          path: 'class',
          children: [
            { path: 'schedule', element: <ClassSchedule />, index: true },
            { path: 'schedule/:clubId', element: <ClassSchedule /> },
          ],
        },
        {
          path: 'memberships',
          children: [
            { path: 'list', element: <MembershipList />, index: true },
            { path: ':id', element: <MembershipEdit /> },
            { path: 'new', element: <MembershipCreate /> },
          ],
        },
        {
          path: 'templates',
          children: [
            { element: <Templates />, index: true },
            { path: ':id', element: <MessageTemplate /> },
          ],
        },
        {
          path: 'settings',
          element: <SettingsLayout />,
          children: [
            { path: 'profile', element: <Profile />, index: true },
            { path: 'company', element: <Company /> },
            { path: 'team', element: <Team /> },
            { path: 'team/newInvite', element: <NewInvite /> },
            { path: 'billing', element: <Billing /> },
            { path: 'notifications', element: <Notifications /> },
            { path: 'changePassword', element: <ChangePassword /> },
            { path: 'emailDomain', element: <EmailDomain /> },
            {
              path: 'clubs',
              children: [
                { path: '', element: <ClubList />, index: true },
                { path: 'new', element: <NewClubPage /> },
                {
                  path: ':id',
                  element: <ClubLayout />,
                  children: [
                    { path: 'detail', element: <ClubDetail />, index: true },
                    { path: 'directDebit', element: <ClubDirectDebit /> },
                    { path: 'webPortal', element: <ClubWebPortal /> },
                    { path: 'sms', element: <ClubSms /> },
                  ],
                },
              ],
            },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const OAuth = Loadable(lazy(() => import('../pages/auth/OAuth')));
const Confirm = Loadable(lazy(() => import('../pages/auth/Confirm')));

// SETUP
const SetupCompany = Loadable(lazy(() => import('../pages/setup/SetupCompany')));
const SetupClub = Loadable(lazy(() => import('../pages/setup/SetupClub')));
const SetupPayment = Loadable(lazy(() => import('../pages/setup/SetupPayment')));

// DASHBOARD
// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// Classes
const ClassTypeList = Loadable(lazy(() => import('../pages/dashboard/ClassTypeList')));
const ClassTypeCreate = Loadable(lazy(() => import('../pages/dashboard/ClassTypeCreate')));
const ClassTypeEdit = Loadable(lazy(() => import('../pages/dashboard/ClassTypeEdit')));

const ClassSchedule = Loadable(lazy(() => import('../pages/dashboard/ClassSchedule')));

// Members
const MemberList = Loadable(lazy(() => import('../pages/dashboard/MemberList')));
const MemberCreate = Loadable(lazy(() => import('../pages/dashboard/MemberCreate')));
const MemberHome = Loadable(lazy(() => import('../pages/member/MemberHome')));
const MemberDetail = Loadable(lazy(() => import('../pages/member/MemberDetail')));
const MemberBilling = Loadable(lazy(() => import('../pages/member/MemberBilling')));
const MemberMembership = Loadable(lazy(() => import('../pages/member/MemberMembership')));
const MemberHistory = Loadable(lazy(() => import('../pages/member/MemberHistory')));
const AccessControl = Loadable(lazy(() => import('../pages/member/AccessControl')));

const MembershipSelect = Loadable(
  lazy(() => import('../pages/member/MembershipSelect/MembershipSelect'))
);
const MembershipPayment = Loadable(
  lazy(() => import('../pages/member/MembershipSelect/MembershipPayment'))
);
const MembershipSignature = Loadable(
  lazy(() => import('../pages/member/MembershipSelect/MembershipSignature'))
);

// MEMBERSHIPS
const MembershipList = Loadable(lazy(() => import('../pages/dashboard/MembershipsList')));
const MembershipEdit = Loadable(lazy(() => import('../pages/dashboard/MembershipEdit')));
const MembershipCreate = Loadable(lazy(() => import('../pages/dashboard/MembershipCreate')));

// Templates
const Templates = Loadable(lazy(() => import('../pages/dashboard/Templates')));
const MessageTemplate = Loadable(lazy(() => import('../pages/dashboard/MessageTemplate')));

// SETTINGS
const Profile = Loadable(lazy(() => import('../pages/settings/Profile')));
const Company = Loadable(lazy(() => import('../pages/settings/Company')));
const ClubList = Loadable(lazy(() => import('../pages/settings/ClubList')));
const NewClubPage = Loadable(lazy(() => import('../pages/settings/NewClub')));
const Team = Loadable(lazy(() => import('../pages/settings/Team')));
const NewInvite = Loadable(lazy(() => import('../pages/settings/NewInvite')));
const EmailDomain = Loadable(lazy(() => import('../pages/settings/EmailDomain')));

// Club Settings
const ClubDetail = Loadable(lazy(() => import('../pages/club/ClubDetail')));
const ClubDirectDebit = Loadable(lazy(() => import('../pages/club/ClubDirectDebit')));
const ClubWebPortal = Loadable(lazy(() => import('../pages/club/ClubWebPortal')));
const ClubSms = Loadable(lazy(() => import('../pages/club/ClubSms')));

const Billing = Loadable(lazy(() => import('../pages/settings/Billing')));
const Notifications = Loadable(lazy(() => import('../pages/settings/Notifications')));
const ChangePassword = Loadable(lazy(() => import('../pages/settings/ChangePassword')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
