// @mui
import * as Yup from 'yup';
import { Box, Button, Typography, DialogTitle, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFEditor } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Member, MemberSms, SendSmsInput, useSendSmsMutation } from 'src/graphql/generated';
import Iconify from 'src/components/Iconify';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type SendSmsModalProps = {
  onCancel?: VoidFunction;
  onComplete: (memberSms?: MemberSms) => void;
  member: Member;
};

export default function SendSmsModal({ onCancel, onComplete, member }: SendSmsModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [sendSmsMutation] = useSendSmsMutation();

  const onSubmit = async (data: SendSmsInput) => {
    try {
      const result = await sendSmsMutation({
        variables: {
          memberId: member.id,
          input: data,
        },
      });
      const memberSms = result.data?.sendSms.memberSms as MemberSms;
      const error = result.data?.sendSms.errors?.[0];
      if (memberSms) {
        enqueueSnackbar('Sms sent');
        onComplete(memberSms);
      } else if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        onComplete();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultValues = {
    body: '',
  };

  const SendSmsSchema = Yup.object().shape({
    body: Yup.string().required('What should the message say?'),
  });

  const methods = useForm<SendSmsInput>({
    resolver: yupResolver(SendSmsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Send SMS</DialogTitle>
      <Box p={3}>
        <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
          Send {member.givenName} a message directly.
        </Typography>
        <RHFEditor name="body" label="Message Body" />
      </Box>

      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          startIcon={<Iconify icon={'subway:sms-5'} />}
          type="submit"
          loading={isSubmitting}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
