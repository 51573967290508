// @mui
import { Avatar, Typography, Grid, useTheme } from '@mui/material';
// @types

import { SearchResultItem } from 'src/graphql/generated';

// ----------------------------------------------------------------------

type Props = {
  searhcResult: SearchResultItem;
  onSelectRow: VoidFunction;
};

export default function SearchResultRow({ searhcResult, onSelectRow }: Props) {
  const { title, thumbnailUrl } = searhcResult;
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        cursor: 'pointer',
        py: 1,
        marginTop: '2px',
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
      }}
      onClick={onSelectRow}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
      >
        <Avatar alt={title} src={thumbnailUrl || ''} sx={{ mr: 2 }} />
        <Typography variant="subtitle1" noWrap>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
