import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Tabs, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { useLocation } from 'react-router-dom';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Club, useGetClubQuery } from 'src/graphql/generated';
import LoadingScreen from 'src/components/LoadingScreen';
import ClubCover from './ClubCover';

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

export type ClubLayoutContext = {
  club: Club;
};

export default function ClubLayout() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const { data, loading } = useGetClubQuery({
    variables: {
      id: id || '',
    },
  });

  const ACCOUNT_TABS = [
    {
      value: 'detail',
      icon: <Iconify icon={'clarity:details-line'} width={20} height={20} />,
      path: 'detail',
    },
    {
      value: 'direct debit',
      icon: <Iconify icon={'ic:outline-credit-card'} width={20} height={20} />,
      path: 'directDebit',
    },
    {
      value: 'sms',
      icon: <Iconify icon={'bi:phone'} width={20} height={20} />,
      path: 'sms',
    },
    {
      value: 'webPortal',
      icon: <Iconify icon={'bi:globe'} width={20} height={20} />,
      path: 'webPortal',
    },
  ];

  const defaultTab = () => {
    const matchingTab = ACCOUNT_TABS.find((tab) => location.pathname.includes(tab.path));
    return matchingTab?.value || 'detail';
  };

  const { currentTab, onChangeTab } = useTabs(defaultTab());

  if (loading) {
    return <LoadingScreen />;
  }

  const club: Club | undefined = data?.getClub as Club;

  return (
    <Page title={`Club: ${club?.name}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card
          sx={{
            mb: 3,
            height: { xs: 288, md: 180 },
            position: 'relative',
          }}
        >
          <ClubCover club={club} />

          <TabsWrapperStyle>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {ACCOUNT_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalCase(tab.value)}
                  icon={tab.icon}
                  value={tab.value}
                  onClick={() => navigate(tab.path)}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        <Outlet context={{ club }} />
      </Container>
    </Page>
  );
}
