import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  Skeleton,
  Typography,
  Stack,
} from '@mui/material';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { SearchDocumentType, SearchResultItem, useSearchLazyQuery } from 'src/graphql/generated';
import SearchResultRow from './SearchResultRow';
import { titleForSearchType } from 'src/utils/enumHelpers';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const SearchbarResultsStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur({
    opacity: 0.99,
  }),
  top: 64,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 3),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3, 5),
  },
  paddingTop: '12px',
  paddingBottom: '12px',
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchedTerm, setSearchedTerm] = useState('');

  const [searchQuery, { data, loading }] = useSearchLazyQuery();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setShowResults(false);
  };

  const handleSearch = () => {
    if (searchTerm.length === 0) {
      return;
    }
    setSearchedTerm(searchTerm);
    searchQuery({
      variables: {
        input: {
          query: searchTerm,
          page: 0,
        },
      },
    });
    setSearchTerm('');
    setShowResults(true);
  };

  const handleSearchItemClicked = (id: string, type: SearchDocumentType) => {
    setShowResults(false);
    setOpen(false);
    switch (type) {
      case SearchDocumentType.Member:
        navigate(PATH_DASHBOARD.member.home(id));
        break;
      case SearchDocumentType.Membership:
        navigate(PATH_DASHBOARD.membership.edit(id));
        break;
      case SearchDocumentType.Club:
        navigate(PATH_DASHBOARD.settings.clubDetail(id));
        break;
      case SearchDocumentType.Company:
        navigate(PATH_DASHBOARD.settings.company);
        break;
      case SearchDocumentType.Staff:
        navigate(PATH_DASHBOARD.settings.team);
        break;
      case SearchDocumentType.ClassType:
        navigate(PATH_DASHBOARD.class.editClassType(id));
        break;
      default:
        break;
    }
  };

  const searchResults = (data?.search?.items as SearchResultItem[]) || [];
  const groupedResults: { [key: string]: SearchResultItem[] } = searchResults.reduce(function (
    r,
    a
  ) {
    r[a.type] = r[a.type] || [];
    r[a.type].push(a);
    return r;
  },
  Object.create(null));

  const groupTypes = Object.keys(groupedResults);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleSearch} disabled={searchTerm.length === 0}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
        <Slide direction="down" in={showResults} mountOnEnter unmountOnExit>
          <SearchbarResultsStyle>
            <Typography variant="h4" gutterBottom>
              {`Results for '${searchedTerm}'`}
            </Typography>
            {loading && (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
            {!loading &&
              groupTypes.map((groupType) => (
                <SearchSection
                  key={groupType}
                  type={groupType}
                  results={groupedResults[groupType]}
                  handleClick={handleSearchItemClicked}
                />
              ))}
            {!loading && groupTypes.length === 0 && (
              <Typography variant="h6" gutterBottom>
                No results found
              </Typography>
            )}
          </SearchbarResultsStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

const SearchSection = ({
  type,
  results,
  handleClick,
}: {
  type: string;
  results: SearchResultItem[];
  handleClick: (id: string, type: SearchDocumentType) => void;
}) => (
  <Stack sx={{ mt: '12px' }}>
    <Typography variant="h6" sx={{ pb: 1 }}>
      {titleForSearchType(type, true)}
    </Typography>
    {results.map((searchResultItem) => (
      <SearchResultRow
        key={searchResultItem.id}
        searhcResult={searchResultItem}
        onSelectRow={() => handleClick(searchResultItem.id, searchResultItem.type)}
      />
    ))}
  </Stack>
);
