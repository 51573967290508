// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components

import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  membership: <Iconify icon={'ic:baseline-card-membership'} width={20} height={20} />,
  trial: <Iconify icon={'akar-icons:clock'} width={20} height={20} />,
  bundle: <Iconify icon={'fluent:stack-16-regular'} width={20} height={20} />,
  inventory: <Iconify icon={'clarity:bundle-line'} width={20} height={20} />,
  templates: <Iconify icon={'fluent:library-20-filled'} width={20} height={20} />,
  banking: getIcon('ic_banking'),
  class: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  plus: <Iconify icon={'ant-design:plus-outlined'} width={20} height={20} />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboards',
    items: [
      { title: 'Overview', path: PATH_DASHBOARD.dashboard.overall, icon: ICONS.dashboard },
      { title: 'Add Dashboard', path: PATH_DASHBOARD.dashboard.sales, icon: ICONS.plus },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'members',
        path: PATH_DASHBOARD.member.list,
        icon: ICONS.user,
      },
      {
        title: 'classes',
        path: PATH_DASHBOARD.class.root,
        icon: ICONS.class,
        children: [
          { title: 'Class types', path: PATH_DASHBOARD.class.classTypes },
          { title: 'Schedule', path: PATH_DASHBOARD.class.classSchedule },
        ],
      },
      {
        title: 'memberships',
        path: PATH_DASHBOARD.membership.list,
        icon: ICONS.membership,
      },
      {
        title: 'trials',
        path: PATH_DASHBOARD.trial.list,
        icon: ICONS.trial,
      },
      {
        title: 'packages',
        path: PATH_DASHBOARD.package.list,
        icon: ICONS.bundle,
      },
    ],
  },

  // COMMS
  // ----------------------------------------------------------------------
  {
    subheader: 'Messaging',
    items: [
      {
        title: 'inbox',
        path: PATH_DASHBOARD.inbox.root,
        icon: ICONS.mail,
      },
      {
        title: 'templates',
        path: PATH_DASHBOARD.templates.root,
        icon: ICONS.templates,
      },
    ],
  },

  // // POS
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'Shop',
  //   items: [
  //     {
  //       title: 'inventory',
  //       path: PATH_DASHBOARD.inventory.root,
  //       icon: ICONS.inventory,
  //     },
  //     {
  //       title: 'sales',
  //       path: PATH_DASHBOARD.sales.root,
  //       icon: ICONS.cart,
  //     },
  //   ],
  // },
];

export default navConfig;
