import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';

// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from '../@types/auth';
//
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { Staff, useGetMeStaffLazyQuery } from 'src/graphql/generated';

// ----------------------------------------------------------------------

// CAUTION: User Cognito is slily difference from firebase, so be sure to read the doc carefully.

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  auth = 'AUTHENTICATE',
  register = 'REGISTER',
  confirm = 'CONFIRM',
  logout = 'LOGOUT',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.register]: {
    registeringFirstName: string;
    registeringLastName: string;
    registeredEmail: string;
  };
  [Types.confirm]: {
    confirmedEmail: string;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      registeredEmail: undefined,
      confirmedEmail: undefined,
      isInitialized: true,
      user,
    };
  }
  if (action.type === 'REGISTER') {
    const { registeredEmail, registeringFirstName, registeringLastName } = action.payload;
    return {
      ...state,
      registeringFirstName,
      registeringLastName,
      registeredEmail,
    };
  }
  if (action.type === 'CONFIRM') {
    const { confirmedEmail } = action.payload;
    return {
      ...state,
      registeringEmail: undefined,
      confirmedEmail,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      registeredEmail: undefined,
      confirmedEmail: undefined,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getMe] = useGetMeStaffLazyQuery();

  const getSession = useCallback(async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const userAttributes = await getMe();
    const user = userAttributes.data?.getMeStaff as Staff;
    dispatch({
      type: Types.auth,
      payload: { isAuthenticated: true, user },
    });
    return {
      user,
      session,
      headers: { Authorization: token },
    };
  }, [getMe]);

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  const reloadUser = useCallback(async () => {
    const userAttributes = await getMe();
    const user = userAttributes.data?.getMeStaff as Staff;
    dispatch({
      type: Types.auth,
      payload: { isAuthenticated: true, user },
    });
    return {
      user,
    };
  }, [getMe]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    async (email, password) => {
      await Auth.signIn({ username: email, password });
      await getSession();
    },
    [getSession]
  );

  // same thing here
  const logout = async () => {
    await Auth.signOut();
    dispatch({ type: Types.logout });
  };

  const googleSignIn = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    dispatch({
      type: Types.register,
      payload: {
        registeredEmail: email,
        registeringFirstName: firstName,
        registeringLastName: lastName,
      },
    });
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        given_name: firstName,
        family_name: lastName,
      },
    });
  };

  const confirmAccount = async (email: string, code: string) => {
    dispatch({
      type: Types.confirm,
      payload: { confirmedEmail: email },
    });
    await Auth.confirmSignUp(email, code);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: state.user,
        reloadUser,
        login,
        register,
        googleSignIn,
        logout,
        confirmAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
