import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 54, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <path
          d="M536.881 232.987H119.584C108.556 232.987 99.6232 223.733 99.6232 212.347C99.6232 200.926 108.556 191.706 119.584 191.706H536.881C547.91 191.706 556.842 200.96 556.842 212.347C556.842 223.768 547.91 232.987 536.881 232.987Z"
          fill="#6690C8"
        />
        <path
          d="M564.002 163.842H39.8266C28.4068 163.842 19.1196 154.588 19.1196 143.202C19.1196 131.781 28.3724 122.561 39.8266 122.561H564.002C575.422 122.561 584.709 131.815 584.709 143.202C584.675 154.623 575.422 163.842 564.002 163.842Z"
          fill="#4C7BBD"
        />
        <path
          d="M587.449 304.196H216.607C205.575 304.196 196.64 294.942 196.64 283.556C196.64 272.135 205.575 262.915 216.607 262.915H587.449C598.48 262.915 607.415 272.169 607.415 283.556C607.415 294.977 598.48 304.196 587.449 304.196Z"
          fill="#5B9B4E"
        />
        <path
          d="M499.731 333.093H220.725C209.699 333.093 200.769 342.346 200.769 353.733C200.769 365.154 209.699 374.373 220.725 374.373H499.731C510.756 374.373 519.687 365.119 519.687 353.733C519.687 342.312 510.756 333.093 499.731 333.093Z"
          fill="#6DBA80"
        />
        <path
          d="M374.849 400.174H256.846C245.821 400.174 236.892 409.427 236.892 420.814C236.892 432.235 245.821 441.454 256.846 441.454H374.849C385.874 441.454 394.803 432.201 394.803 420.814C394.803 409.393 385.874 400.174 374.849 400.174Z"
          fill="#F7AC02"
        />
        <path
          d="M391.355 463.126H327.037C316.008 463.126 307.075 472.38 307.075 483.767C307.075 495.188 316.008 504.407 327.037 504.407H391.355C402.383 504.407 411.317 495.153 411.317 483.767C411.317 472.38 402.383 463.126 391.355 463.126Z"
          fill="#FFDE00"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
