// ----------------------------------------------------------------------

import { MessageTemplateId, MessageTemplateType } from 'src/graphql/generated';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_SETUP = '/setup';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/signin'),
  register: path(ROOTS_AUTH, '/register'),
  confirm: path(ROOTS_AUTH, '/confirm'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  oauth: path(ROOTS_AUTH, '/oauth'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_SETUP = {
  root: ROOTS_SETUP,
  company: path(ROOTS_SETUP, '/company'),
  club: path(ROOTS_SETUP, '/club'),
  payment: path(ROOTS_SETUP, '/payment'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: {
    overall: path(ROOTS_DASHBOARD, '/app'),
    sales: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  // mail: {
  //   root: path(ROOTS_DASHBOARD, '/mail'),
  //   all: path(ROOTS_DASHBOARD, '/mail/all'),
  // },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  // },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    profile: path(ROOTS_DASHBOARD, '/settings/profile'),
    billing: path(ROOTS_DASHBOARD, '/settings/billing'),
    notifications: path(ROOTS_DASHBOARD, '/settings/notifications'),
    changePassword: path(ROOTS_DASHBOARD, '/settings/changePassword'),
    company: path(ROOTS_DASHBOARD, '/settings/company'),
    clubs: path(ROOTS_DASHBOARD, '/settings/clubs'),
    clubNew: path(ROOTS_DASHBOARD, '/settings/clubs/new'),
    team: path(ROOTS_DASHBOARD, '/settings/team'),
    inviteNew: path(ROOTS_DASHBOARD, '/settings/team/newInvite'),
    emailDomain: path(ROOTS_DASHBOARD, '/settings/emailDomain'),
    clubDetail: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/detail`),
    clubDirectDebit: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/directDebit`),
    clubWebPortal: (id: string) => path(ROOTS_DASHBOARD, `/settings/clubs/${id}/webPortal`),
  },
  member: {
    root: path(ROOTS_DASHBOARD, '/members'),
    new: path(ROOTS_DASHBOARD, '/members/new'),
    list: path(ROOTS_DASHBOARD, '/members'),
    home: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/home`),
    details: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/details`),
    history: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/history`),
    billing: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/billing`),
    membership: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership`),
    membershipSelect: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/select`),
    membershipPayment: (id: string) => path(ROOTS_DASHBOARD, `/members/${id}/membership/payment`),
    membershipSignature: (id: string) =>
      path(ROOTS_DASHBOARD, `/members/${id}/membership/signature`),
  },
  membership: {
    root: path(ROOTS_DASHBOARD, '/memberships'),
    new: path(ROOTS_DASHBOARD, '/memberships/new'),
    list: path(ROOTS_DASHBOARD, '/memberships/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/memberships/${id}`),
  },
  templates: {
    root: path(ROOTS_DASHBOARD, '/templates'),
    template: (template: MessageTemplateId) => path(ROOTS_DASHBOARD, `/templates/${template}`),
    templateType: (template: MessageTemplateId, type: MessageTemplateType) =>
      path(ROOTS_DASHBOARD, `/templates/${template}/${type}`),
    emailLayout: path(ROOTS_DASHBOARD, `/templates/emailLayout`),
  },
  inbox: {
    root: path(ROOTS_DASHBOARD, '/inbox'),
  },
  trial: {
    root: path(ROOTS_DASHBOARD, '/trials'),
    new: path(ROOTS_DASHBOARD, '/trials/new'),
    list: path(ROOTS_DASHBOARD, '/trials/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/trials/${id}/edit`),
  },
  package: {
    root: path(ROOTS_DASHBOARD, '/package'),
    new: path(ROOTS_DASHBOARD, '/package/new'),
    list: path(ROOTS_DASHBOARD, '/package/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/package/${id}/edit`),
  },
  class: {
    root: path(ROOTS_DASHBOARD, '/class'),
    new: path(ROOTS_DASHBOARD, '/class/new'),
    classSchedule: path(ROOTS_DASHBOARD, `/class/schedule`),
    clubClassSchedule: (clubId: string) => path(ROOTS_DASHBOARD, `/class/schedule/${clubId}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/class/${id}`),
    classTypes: path(ROOTS_DASHBOARD, '/class/types/list'),
    newClassType: path(ROOTS_DASHBOARD, '/class/types/new'),
    editClassType: (id: string) => path(ROOTS_DASHBOARD, `/class/types/${id}`),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    new: path(ROOTS_DASHBOARD, '/inventory/new'),
    list: path(ROOTS_DASHBOARD, '/inventory/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/inventory/${id}/edit`),
  },
  sales: {
    root: path(ROOTS_DASHBOARD, '/sales'),
    new: path(ROOTS_DASHBOARD, '/sales/new'),
    list: path(ROOTS_DASHBOARD, '/sales/list'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/sales/${id}`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
