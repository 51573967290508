import { LabelColor } from 'src/components/Label';
import { MemberStatus } from 'src/graphql/generated';

export const statusLabelColor = (status?: string | MemberStatus | null): LabelColor => {
  switch (status) {
    case MemberStatus.Lead:
      return 'warning';
    case MemberStatus.CasualMember:
      return 'secondary';
    case MemberStatus.LongTermMember:
      return 'success';
    case MemberStatus.TrialMember:
      return 'primary';
    case MemberStatus.SigningUp:
      return 'info';
    default:
      return 'success';
  }
};

export const statusTitle = (status?: string | MemberStatus | null): string => {
  switch (status) {
    case 'all':
      return 'All';
    case MemberStatus.Lead:
      return 'Lead';
    case MemberStatus.CasualMember:
      return 'Casual';
    case MemberStatus.LongTermMember:
      return 'Member';
    case MemberStatus.TrialMember:
      return 'Trial';
    case MemberStatus.SigningUp:
      return 'Signing Up';
    default:
      return 'Unknown';
  }
};
