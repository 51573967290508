export const Config = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_6HPvX636B",
    userPoolWebClientId: "45um1ntqis82720c3u6tmgl692",
    oauth: {
      domain: "staff-identity.gymxapp.net",
      redirectSignIn: "http://localhost:3000/auth/oauth",
      redirectSignOut: "http://localhost:3000/signin",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
  AppSync: {
    Staff: {
      graphqlEndpoint:  "https://api.staff.gymxapp.net/graphql",
      region: "ap-southeast-2",
    },
    Public: {
      graphqlEndpoint:  "https://api.public.gymxapp.net/graphql",
      region: "ap-southeast-2",
    },
 }
};
export default Config;
