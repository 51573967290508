import { sentenceCase } from 'change-case';
import {
  MembershipPaymentFrequency,
  MembershipLength,
  LimitFrequency,
  SearchDocumentType,
  Gender,
  PaymentMethodType,
} from 'src/graphql/generated';

export enum SupportedCountry {
  AU = 'AU',
}

export const titleForCountryCode = (countryCode: SupportedCountry) => {
  switch (countryCode) {
    case SupportedCountry.AU:
      return 'Australia';
    default:
      return 'Unknown';
  }
};

export const SUPPORTED_COUNTRIES = [SupportedCountry.AU];

export const MEMBERSHIP_PAYMENT_FREQUENCIES = [
  MembershipPaymentFrequency.Weekly,
  MembershipPaymentFrequency.Fortnightly,
  MembershipPaymentFrequency.Monthly,
  MembershipPaymentFrequency.Yearly,
];

export const titleForMembershipPaymentFrequency = (
  freq: MembershipPaymentFrequency | string | undefined | null
) => {
  if (!freq) return '';
  return sentenceCase(freq);
};

export const MEMBERSHIP_LENGTHS = [
  MembershipLength.Day,
  MembershipLength.Week,
  MembershipLength.Fortnight,
  MembershipLength.Month,
  MembershipLength.Year,
];

export const titleForMembershipLengths = (
  length: MembershipPaymentFrequency | string | undefined | null
) => {
  if (!length) return '';
  return `${sentenceCase(length)}s`;
};

export const LIMIT_FREQUENCIES = [
  LimitFrequency.Daily,
  LimitFrequency.Weekly,
  LimitFrequency.Fortnightly,
  LimitFrequency.Monthly,
  LimitFrequency.Yearly,
];

export const titleForLimitFrequency = (length: LimitFrequency | string | undefined | null) => {
  if (!length) return '';
  return `${sentenceCase(length)}`;
};

export const titleForSearchType = (
  searchType: SearchDocumentType | string | undefined | null,
  plural = false
) => {
  if (!searchType) return '';
  const pluralCharacter = plural ? 's' : '';
  return `${sentenceCase(searchType, {
    delimiter: '_',
  }).replace('_', ' ')}${pluralCharacter}`;
};

export const ALL_GENDERS = [Gender.Male, Gender.Female, Gender.Unknown];

export const titleForGender = (gender: Gender | string | undefined | null) => {
  if (!gender) return '';
  return sentenceCase(gender);
};

export const titleForPaymentMethodType = (paymentMethodType: PaymentMethodType) => {
  switch (paymentMethodType) {
    case PaymentMethodType.BankAccount:
      return 'Bank Account';
    case PaymentMethodType.CreditCard:
      return 'Card';
    default:
      return 'Unknown';
  }
};
