import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function SettingsLayout() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const ACCOUNT_TABS = [
    {
      value: 'profile',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      path: 'profile',
    },
    {
      value: 'company',
      icon: <Iconify icon={'ic:round-business'} width={20} height={20} />,
      path: 'company',
    },
    {
      value: 'clubs',
      icon: <Iconify icon={'carbon:location-filled'} width={20} height={20} />,
      path: 'clubs',
    },
    {
      value: 'team',
      icon: <Iconify icon={'ic:baseline-people-alt'} width={20} height={20} />,
      path: 'team',
    },
    {
      value: 'billing',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      path: 'billing',
    },
    {
      value: 'notifications',
      icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
      path: 'notifications',
    },
    {
      value: 'email domain',
      icon: <Iconify icon={'eva:email-outline'} width={20} height={20} />,
      path: 'emailDomain',
    },
    {
      value: 'changePassword',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      path: 'changePassword',
    },
  ];

  const defaultTab = () => {
    const matchingTab = ACCOUNT_TABS.find((tab) => location.pathname.includes(tab.path));
    return matchingTab?.value || 'profile';
  };

  const { currentTab, onChangeTab } = useTabs(defaultTab());

  return (
    <Page title="Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Settings"
          links={[{ name: 'Settings', href: PATH_DASHBOARD.root }]}
        />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
              onClick={() => navigate(tab.path)}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        <Outlet />
      </Container>
    </Page>
  );
}
