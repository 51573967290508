import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: string;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
};

export type AddClassInput = {
  calendarColor: Scalars['String'];
  clubId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  length?: InputMaybe<Scalars['Int']>;
  maximumCapacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  staffId: Scalars['String'];
  startsAt: Scalars['String'];
  thumbnailImageUrl?: InputMaybe<Scalars['String']>;
  useCheckIn?: InputMaybe<Scalars['Boolean']>;
  useWaitlist?: InputMaybe<Scalars['Boolean']>;
  waitlistConfirmationWindow?: InputMaybe<Scalars['Int']>;
};

export type AddClassScheduleRuleInput = {
  calendarColor: Scalars['String'];
  defaultStaffMemberId?: InputMaybe<Scalars['String']>;
  endsAt: Scalars['String'];
  length: Scalars['Int'];
  name: Scalars['String'];
  startsFrom: Scalars['String'];
  times: Array<InputMaybe<Scalars['String']>>;
  weekDays: Array<InputMaybe<WeekDay>>;
};

export type AddClassTypeInput = {
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  defaultCalendarColor?: InputMaybe<Scalars['String']>;
  defaultLength?: InputMaybe<Scalars['Int']>;
  defaultMaximumCapacity?: InputMaybe<Scalars['Int']>;
  defaultUseCheckIn?: InputMaybe<Scalars['Boolean']>;
  defaultUseWaitlist?: InputMaybe<Scalars['Boolean']>;
  defaultWaitlistConfirmationWindow?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  name: Scalars['String'];
  thumbnailImageUrl?: InputMaybe<Scalars['String']>;
};

export type AddClubInput = {
  address?: InputMaybe<Scalars['AWSJSON']>;
  baseCurrencyCode: Scalars['String'];
  countryCode: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  timezone: Scalars['String'];
};

export type AddCompanyInput = {
  brandName: Scalars['String'];
  description: Scalars['String'];
  headOfficeAddress: Scalars['AWSJSON'];
  name: Scalars['String'];
};

export type AddCompanyPaymentMethodInput = {
  bankAccount?: InputMaybe<BankAccountInput>;
  creditCard?: InputMaybe<CreditCardInput>;
  isDefault: Scalars['Boolean'];
  type: PaymentMethodType;
};

export type AddDirectDebitAgreementInput = {
  signatureData: Scalars['String'];
  signedDate: Scalars['String'];
};

export type AddMemberInput = {
  address?: InputMaybe<Scalars['AWSJSON']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  givenName: Scalars['String'];
  homeClubId: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePictureUrl?: InputMaybe<Scalars['String']>;
};

export type AddMemberPaymentMethodInput = {
  bankAccount?: InputMaybe<BankAccountInput>;
  creditCard?: InputMaybe<CreditCardInput>;
  isDefault: Scalars['Boolean'];
  type: PaymentMethodType;
};

export type AddMembershipInput = {
  adminFee?: InputMaybe<Scalars['Int']>;
  availablePaymentPeriods?: InputMaybe<Array<InputMaybe<MembershipPaymentFrequency>>>;
  baseCurrencyCode: Scalars['String'];
  cancellationFee?: InputMaybe<Scalars['Int']>;
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  fobFee?: InputMaybe<Scalars['Int']>;
  joinFee?: InputMaybe<Scalars['Int']>;
  lengthAmount?: InputMaybe<Scalars['Int']>;
  lengthFrequency?: InputMaybe<MembershipLength>;
  name: Scalars['String'];
  paymentAmount?: InputMaybe<Scalars['Float']>;
  paymentFrequency?: InputMaybe<MembershipPaymentFrequency>;
  suspensionFee?: InputMaybe<Scalars['Int']>;
  thumbnailImageUrl?: InputMaybe<Scalars['String']>;
  visitLimit?: InputMaybe<Scalars['Int']>;
  visitLimitFrequency?: InputMaybe<LimitFrequency>;
};

export type AddMembershipResponse = {
  __typename?: 'AddMembershipResponse';
  error?: Maybe<Error>;
  membership?: Maybe<Membership>;
};

export type AddStaffInviteInput = {
  clubIds: Array<InputMaybe<Scalars['String']>>;
  email: Scalars['String'];
  givenName: Scalars['String'];
  role: StaffRole;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  center?: Maybe<Array<Maybe<Scalars['Float']>>>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullText: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  streetOne: Scalars['String'];
  streetTwo?: Maybe<Scalars['String']>;
  zipPostcode?: Maybe<Scalars['String']>;
};

export type AddressSearchResult = {
  __typename?: 'AddressSearchResult';
  items?: Maybe<Array<Maybe<Address>>>;
};

export type AgreeToMembershipInput = {
  signatureData: Scalars['String'];
};

export type AssignMembershipInput = {
  adminFee?: InputMaybe<Scalars['Float']>;
  cancellationFee?: InputMaybe<Scalars['Float']>;
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dishonorFee?: InputMaybe<Scalars['Float']>;
  fobFee?: InputMaybe<Scalars['Float']>;
  hasCustomFees?: InputMaybe<Scalars['Boolean']>;
  joinFee?: InputMaybe<Scalars['Float']>;
  lengthAmount?: InputMaybe<Scalars['Int']>;
  lengthFrequency?: InputMaybe<MembershipLength>;
  membershipId: Scalars['String'];
  merchantFeePercentage?: InputMaybe<Scalars['Float']>;
  paymentAmount?: InputMaybe<Scalars['Float']>;
  paymentFrequency?: InputMaybe<MembershipPaymentFrequency>;
  startDate: Scalars['String'];
  suspensionFee?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  visitLimit?: InputMaybe<Scalars['Int']>;
  visitLimitFrequency?: InputMaybe<LimitFrequency>;
};

export type BankAccountInput = {
  bsb: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
};

export type ChargeAmount = {
  __typename?: 'ChargeAmount';
  amount: PaymentBreakdown;
  frequency: MembershipPaymentFrequency;
};

export type Class = {
  __typename?: 'Class';
  bookedMemberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  calendarColor: Scalars['String'];
  cancellationReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['String']>;
  checkedInMemberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  classScheduleRuleId?: Maybe<Scalars['String']>;
  clubId: Scalars['String'];
  companyId: Scalars['String'];
  currentCapacity: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length: Scalars['Int'];
  maximumCapacity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  staffId: Scalars['String'];
  staffName?: Maybe<Scalars['String']>;
  startsAt: Scalars['String'];
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  useCheckIn: Scalars['Boolean'];
  useWaitlist: Scalars['Boolean'];
  waitlistConfirmationWindow?: Maybe<Scalars['Int']>;
  waitlistMemberIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ClassBooking = {
  __typename?: 'ClassBooking';
  bookedOn: Scalars['String'];
  cancellationReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['String']>;
  classId: Scalars['String'];
  className: Scalars['String'];
  id: Scalars['ID'];
  memberId: Scalars['String'];
  startsAt: Scalars['String'];
};

export type ClassBookingConnection = {
  __typename?: 'ClassBookingConnection';
  items?: Maybe<Array<Maybe<ClassBooking>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClassBookingResult = {
  __typename?: 'ClassBookingResult';
  classBooking?: Maybe<ClassBooking>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ClassCheckIn = {
  __typename?: 'ClassCheckIn';
  checkedInAt: Scalars['String'];
  classId: Scalars['String'];
  id: Scalars['ID'];
  memberId: Scalars['String'];
};

export type ClassCheckInConnection = {
  __typename?: 'ClassCheckInConnection';
  items?: Maybe<Array<Maybe<ClassCheckIn>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClassCheckInResult = {
  __typename?: 'ClassCheckInResult';
  classCheckIn?: Maybe<ClassCheckIn>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ClassConnection = {
  __typename?: 'ClassConnection';
  items?: Maybe<Array<Maybe<Class>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClassResponse = {
  __typename?: 'ClassResponse';
  class?: Maybe<Class>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ClassScheduleResponse = {
  __typename?: 'ClassScheduleResponse';
  classSchedule?: Maybe<ClassScheduleRule>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ClassScheduleRule = {
  __typename?: 'ClassScheduleRule';
  calendarColor: Scalars['String'];
  classTypeId: Scalars['String'];
  defaultStaffMemberId?: Maybe<Scalars['String']>;
  endsAt: Scalars['String'];
  id: Scalars['ID'];
  length: Scalars['Int'];
  name: Scalars['String'];
  startsFrom: Scalars['String'];
  times: Array<Maybe<Scalars['String']>>;
  weekDays: Array<Maybe<WeekDay>>;
};

export type ClassType = {
  __typename?: 'ClassType';
  clubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyId: Scalars['String'];
  created?: Maybe<Scalars['String']>;
  defaultCalendarColor?: Maybe<Scalars['String']>;
  defaultLength?: Maybe<Scalars['Int']>;
  defaultMaximumCapacity?: Maybe<Scalars['Int']>;
  defaultUseCheckIn: Scalars['Boolean'];
  defaultUseWaitlist: Scalars['Boolean'];
  defaultWaitlistConfirmationWindow?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnailImageUrl?: Maybe<Scalars['String']>;
};

export type ClassTypeConnection = {
  __typename?: 'ClassTypeConnection';
  items?: Maybe<Array<Maybe<ClassType>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClassTypeResponse = {
  __typename?: 'ClassTypeResponse';
  classType?: Maybe<ClassType>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ClassWaitlist = {
  __typename?: 'ClassWaitlist';
  classId: Scalars['String'];
  className: Scalars['String'];
  id: Scalars['ID'];
  memberId: Scalars['String'];
  startsAt: Scalars['String'];
  waitedOn: Scalars['String'];
};

export type ClassWaitlistConnection = {
  __typename?: 'ClassWaitlistConnection';
  items?: Maybe<Array<Maybe<ClassWaitlist>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClassWaitlistResult = {
  __typename?: 'ClassWaitlistResult';
  classWaitlist?: Maybe<ClassWaitlist>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Club = {
  __typename?: 'Club';
  address?: Maybe<Scalars['AWSJSON']>;
  baseCurrency: Currency;
  clubWebPortal?: Maybe<ClubWebPortal>;
  company: Company;
  countryCode: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  timezone: Scalars['String'];
};

export type ClubConnection = {
  __typename?: 'ClubConnection';
  items?: Maybe<Array<Maybe<Club>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ClubResponse = {
  __typename?: 'ClubResponse';
  club?: Maybe<Club>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ClubWebPortal = {
  __typename?: 'ClubWebPortal';
  clubId: Scalars['String'];
  colorScheme?: Maybe<Scalars['AWSJSON']>;
  enabled: Scalars['Boolean'];
  logInText?: Maybe<Scalars['String']>;
  showPublicClasses: Scalars['Boolean'];
  welcomeText?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  brandName?: Maybe<Scalars['String']>;
  clubs?: Maybe<ClubConnection>;
  coverImageUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headOfficeAddress: Scalars['AWSJSON'];
  id: Scalars['ID'];
  logoImageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  webPortal?: Maybe<WebPortal>;
};


export type CompanyClubsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type CompanyPayment = {
  __typename?: 'CompanyPayment';
  amount: Scalars['Int'];
  attemptNumber: Scalars['Int'];
  companyId: Scalars['String'];
  failReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
  processedAt: Scalars['String'];
  serviceId: Scalars['String'];
  servicePeriod?: Maybe<Scalars['String']>;
  serviceType: JourneyPaymentServiceType;
  status: PaymentStatus;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type CompanyPaymentMethod = {
  __typename?: 'CompanyPaymentMethod';
  accountName?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  expiryMonth?: Maybe<Scalars['String']>;
  expiryYear?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  maskedNumber: Scalars['String'];
  type: PaymentMethodType;
};

export type CompanyPaymentMethodResult = {
  __typename?: 'CompanyPaymentMethodResult';
  companyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  company?: Maybe<Company>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreditCardInput = {
  cvv: Scalars['String'];
  expiryMonth: Scalars['String'];
  expiryYear: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  base: Scalars['Int'];
  code: Scalars['String'];
  exponent: Scalars['Int'];
};

export type DirectDebitConnection = {
  __typename?: 'DirectDebitConnection';
  baseCurrency: Currency;
  clubId: Scalars['String'];
  companyId: Scalars['String'];
  directDebitProviderId: Scalars['String'];
  dishonorFee: Scalars['Int'];
  memberPaysDishonorFee: Scalars['Boolean'];
  memberPaysMerchantFee: Scalars['Boolean'];
  memberPaysTransactionFee: Scalars['Boolean'];
  merchantFeePercentage: Scalars['Float'];
  name: Scalars['String'];
  transactionFee: Scalars['Int'];
  username: Scalars['String'];
};

export type DirectDebitProvider = {
  __typename?: 'DirectDebitProvider';
  baseCurrency: Currency;
  description: Scalars['String'];
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  termsAndConditionsUrl: Scalars['String'];
  url: Scalars['String'];
  username: Scalars['String'];
};

export type EmailDomain = {
  __typename?: 'EmailDomain';
  active: Scalars['Boolean'];
  dKIMHost?: Maybe<Scalars['String']>;
  dKIMPendingHost?: Maybe<Scalars['String']>;
  dKIMPendingTextValue?: Maybe<Scalars['String']>;
  dKIMTextValue?: Maybe<Scalars['String']>;
  dKIMVerified: Scalars['Boolean'];
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  returnPathDomain: Scalars['String'];
  returnPathDomainCNAMEValue: Scalars['String'];
  returnPathDomainVerified: Scalars['Boolean'];
};

export type EmailDomainResponse = {
  __typename?: 'EmailDomainResponse';
  emailDomain?: Maybe<EmailDomain>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type EmailMessageTemplate = {
  __typename?: 'EmailMessageTemplate';
  design: Scalars['AWSJSON'];
  subject?: Maybe<Scalars['String']>;
};

export type EmailStats = {
  __typename?: 'EmailStats';
  bounceRate: Scalars['Float'];
  bounced: Scalars['Int'];
  opens: Scalars['Int'];
  sent: Scalars['Int'];
  spamComplaints: Scalars['Int'];
  spamComplaintsRate: Scalars['Float'];
  uniqueOpens: Scalars['Int'];
};

export type Error = {
  __typename?: 'Error';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum Gender {
  Female = 'FEMALE',
  Intersex = 'INTERSEX',
  Male = 'MALE',
  Trans = 'TRANS',
  Unknown = 'UNKNOWN'
}

export enum JourneyPaymentServiceType {
  Membership = 'MEMBERSHIP',
  Sms = 'SMS'
}

export enum LimitFrequency {
  Daily = 'DAILY',
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type Member = {
  __typename?: 'Member';
  activeMembershipId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['AWSJSON']>;
  clubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  email: Scalars['String'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  familyName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  givenName: Scalars['String'];
  homeClubId: Scalars['String'];
  homeClubName: Scalars['String'];
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
  privateNote?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  status: MemberStatus;
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  items?: Maybe<Array<Maybe<Member>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type MemberEmail = {
  __typename?: 'MemberEmail';
  clickedAt?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  deliveredAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberId: Scalars['String'];
  messageTemplateId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['String']>;
  receivedAt?: Maybe<Scalars['String']>;
  sentAt: Scalars['String'];
  templateEventId?: Maybe<Scalars['String']>;
  to: Scalars['String'];
};

export type MemberFees = {
  __typename?: 'MemberFees';
  adminFee?: Maybe<Scalars['Int']>;
  cancellationFee?: Maybe<Scalars['Int']>;
  fobFee?: Maybe<Scalars['Int']>;
  joinFee?: Maybe<Scalars['Int']>;
  suspensionFee?: Maybe<Scalars['Int']>;
};

export type MemberMembership = {
  __typename?: 'MemberMembership';
  adminFee?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  companyId: Scalars['String'];
  directDebitProviderId?: Maybe<Scalars['String']>;
  dishonorFee?: Maybe<Scalars['Float']>;
  fobFee?: Maybe<Scalars['Float']>;
  hasCustomFees: Scalars['Boolean'];
  id: Scalars['String'];
  joinFee?: Maybe<Scalars['Float']>;
  lengthAmount: Scalars['Int'];
  lengthFrequency: MembershipLength;
  memberId: Scalars['String'];
  memberName: Scalars['String'];
  membershipId: Scalars['String'];
  membershipName: Scalars['String'];
  merchantFeePercentage?: Maybe<Scalars['Float']>;
  paymentAmount: Scalars['Float'];
  paymentFrequency: MembershipPaymentFrequency;
  signedAt?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  suspensionFee?: Maybe<Scalars['Float']>;
  transactionFee?: Maybe<Scalars['Float']>;
  visitLimit?: Maybe<Scalars['Int']>;
  visitLimitFrequency?: Maybe<LimitFrequency>;
};

export type MemberMembershipResponse = {
  __typename?: 'MemberMembershipResponse';
  error?: Maybe<Error>;
  memberMembership?: Maybe<MemberMembership>;
};

export type MemberPayment = {
  __typename?: 'MemberPayment';
  amount: Scalars['Int'];
  attemptNumber: Scalars['Int'];
  companyId: Scalars['String'];
  failReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  memberId: Scalars['String'];
  memberPaymentMethodId: Scalars['String'];
  merchantFee?: Maybe<Scalars['Int']>;
  processedAt: Scalars['String'];
  serviceId: Scalars['String'];
  servicePeriod?: Maybe<Scalars['String']>;
  serviceType: PaymentServiceType;
  status: PaymentStatus;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  transactionFee?: Maybe<Scalars['Int']>;
};

export type MemberPaymentMethod = {
  __typename?: 'MemberPaymentMethod';
  accountName?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  expiryMonth?: Maybe<Scalars['String']>;
  expiryYear?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  maskedNumber: Scalars['String'];
  memberId: Scalars['String'];
  providerId: Scalars['String'];
  type: PaymentMethodType;
};

export type MemberPaymentMethodResult = {
  __typename?: 'MemberPaymentMethodResult';
  errors?: Maybe<Array<Maybe<Error>>>;
  memberPaymentMethod?: Maybe<MemberPaymentMethod>;
};

export type MemberSearchInput = {
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeClubId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  status?: InputMaybe<MemberStatus>;
};

export type MemberSms = {
  __typename?: 'MemberSms';
  body: Scalars['String'];
  clickedAt?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  cost: Scalars['Int'];
  deliveredAt?: Maybe<Scalars['String']>;
  direction: MessageDirection;
  id: Scalars['String'];
  memberId: Scalars['String'];
  messageTemplateId?: Maybe<MessageTemplateId>;
  numberOfSegments: Scalars['Int'];
  openedAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  status?: Maybe<SmsStatus>;
  templateEventId?: Maybe<Scalars['String']>;
  to: Scalars['String'];
};

export type MemberSmsConnection = {
  __typename?: 'MemberSmsConnection';
  memberSms?: Maybe<Array<Maybe<MemberSms>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type MemberSmsResponse = {
  __typename?: 'MemberSmsResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  memberSms?: Maybe<MemberSms>;
};

export enum MemberStatus {
  CasualMember = 'CASUAL_MEMBER',
  Lead = 'LEAD',
  LongTermMember = 'LONG_TERM_MEMBER',
  PastMember = 'PAST_MEMBER',
  SigningUp = 'SIGNING_UP',
  TrialMember = 'TRIAL_MEMBER'
}

export type Membership = {
  __typename?: 'Membership';
  adminFee?: Maybe<Scalars['Int']>;
  availablePaymentPeriods?: Maybe<Array<Maybe<MembershipPaymentFrequency>>>;
  baseCurrency: Currency;
  cancellationFee?: Maybe<Scalars['Int']>;
  clubIds: Array<Maybe<Scalars['String']>>;
  coverImageUrl?: Maybe<Scalars['String']>;
  createdBy: Staff;
  description: Scalars['String'];
  fobFee?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  joinFee?: Maybe<Scalars['Int']>;
  lengthAmount?: Maybe<Scalars['Int']>;
  lengthFrequency?: Maybe<MembershipLength>;
  name: Scalars['String'];
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentFrequency?: Maybe<MembershipPaymentFrequency>;
  publishedAt?: Maybe<Scalars['String']>;
  status: MembershipStatus;
  suspensionFee?: Maybe<Scalars['Int']>;
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  visitLimit?: Maybe<Scalars['Int']>;
  visitLimitFrequency?: Maybe<LimitFrequency>;
};

export type MembershipClubMembership = {
  __typename?: 'MembershipClubMembership';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  createdBy: Staff;
  membershipDescription: Scalars['String'];
  membershipId: Scalars['String'];
  membershipName: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  status: MembershipStatus;
  thumbnailImageUrl?: Maybe<Scalars['String']>;
};

export type MembershipClubMembershipConnection = {
  __typename?: 'MembershipClubMembershipConnection';
  items?: Maybe<Array<Maybe<MembershipClubMembership>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type MembershipConnection = {
  __typename?: 'MembershipConnection';
  items?: Maybe<Array<Maybe<Membership>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum MembershipLength {
  Day = 'DAY',
  Fortnight = 'FORTNIGHT',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum MembershipPaymentFrequency {
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  OnceOff = 'ONCE_OFF',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum MembershipStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type MergeTag = {
  __typename?: 'MergeTag';
  key: Scalars['String'];
  name: Scalars['String'];
  sample?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export enum MessageDirection {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  category: MessageTemplateCategory;
  description: Scalars['String'];
  email?: Maybe<EmailMessageTemplate>;
  id: MessageTemplateId;
  mergeTags?: Maybe<Array<Maybe<MergeTag>>>;
  sms?: Maybe<Scalars['String']>;
};

export enum MessageTemplateCategory {
  Classes = 'CLASSES',
  FreeForm = 'FREE_FORM',
  Onboarding = 'ONBOARDING'
}

export enum MessageTemplateId {
  ClassBooked = 'CLASS_BOOKED',
  ClassCancelled = 'CLASS_CANCELLED',
  ClassChanged = 'CLASS_CHANGED',
  FreeForm = 'FREE_FORM',
  InWaitlist = 'IN_WAITLIST',
  SignUp = 'SIGN_UP',
  WaitlistSpotOpen = 'WAITLIST_SPOT_OPEN'
}

export type MessageTemplateResult = {
  __typename?: 'MessageTemplateResult';
  errors?: Maybe<Array<Maybe<Error>>>;
  messageTemplate?: Maybe<MessageTemplate>;
};

export enum MessageTemplateType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type Mutation = {
  __typename?: 'Mutation';
  addClass?: Maybe<ClassResponse>;
  addClassScheduleRule?: Maybe<ClassScheduleResponse>;
  addClassType?: Maybe<ClassTypeResponse>;
  addClub?: Maybe<ClubResponse>;
  addCompany?: Maybe<CompanyResponse>;
  addCompanyPaymentMethod?: Maybe<CompanyPaymentMethodResult>;
  addEmailDomain?: Maybe<EmailDomainResponse>;
  addMember?: Maybe<Member>;
  addMemberPaymentMethod?: Maybe<MemberPaymentMethodResult>;
  addMembership?: Maybe<AddMembershipResponse>;
  addStaffInvite?: Maybe<StaffInvite>;
  agreeToCurrentMembership: MemberMembership;
  assignMembershipToMember?: Maybe<MemberMembershipResponse>;
  bookMemberIntoClass?: Maybe<ClassBookingResult>;
  bookMemberIntoWaitlist?: Maybe<ClassWaitlistResult>;
  cancelClass?: Maybe<ClassResponse>;
  cancelClassBooking?: Maybe<ClassBookingResult>;
  cancelClassCheckIn?: Maybe<ClassCheckInResult>;
  cancelClassWaitlist?: Maybe<ClassCheckInResult>;
  checkInMemberForClass?: Maybe<ClassCheckInResult>;
  deleteClass?: Maybe<ClassResponse>;
  deleteClassScheduleRule?: Maybe<ClassScheduleResponse>;
  deleteClassType?: Maybe<ClassTypeResponse>;
  deleteMembership?: Maybe<Membership>;
  disableStaffInvite?: Maybe<StaffInvite>;
  enableEmailDomain?: Maybe<EmailDomainResponse>;
  redeemStaffInvite?: Maybe<StaffInvite>;
  removeCompanyPaymentMethod?: Maybe<CompanyPaymentMethodResult>;
  removeEmailDomain?: Maybe<EmailDomainResponse>;
  removeMemberPaymentMethod?: Maybe<MemberPaymentMethodResult>;
  sendSms: MemberSmsResponse;
  setAutoTopUp: SmsAccountResponse;
  setCompanyPaymentMethodAsDefault?: Maybe<CompanyPaymentMethodResult>;
  setDirectDebitConnection?: Maybe<DirectDebitConnection>;
  setMemberPaymentMethodAsDefault?: Maybe<MemberPaymentMethodResult>;
  setMembershipStatus?: Maybe<Membership>;
  setSmsPaymentMethod: SmsAccountResponse;
  topUpSmsAccount: SmsAccountResponse;
  updateClass?: Maybe<ClassResponse>;
  updateClassScheduleRule?: Maybe<ClassScheduleResponse>;
  updateClassType?: Maybe<ClassTypeResponse>;
  updateClub?: Maybe<ClubResponse>;
  updateClubWebPortal?: Maybe<ClubWebPortal>;
  updateCompany?: Maybe<CompanyResponse>;
  updateDirectDebitConnection?: Maybe<DirectDebitConnection>;
  updateMeStaff?: Maybe<Staff>;
  updateMember?: Maybe<UpdateMemberResponse>;
  updateMemberMembership?: Maybe<MemberMembershipResponse>;
  updateMembership?: Maybe<Membership>;
  updateMessageTemplate?: Maybe<MessageTemplateResult>;
  updateStaff?: Maybe<Staff>;
  updateWebPortal?: Maybe<WebPortal>;
  verifyEmailDomain?: Maybe<EmailDomainResponse>;
};


export type MutationAddClassArgs = {
  classTypeId: Scalars['String'];
  input: AddClassInput;
};


export type MutationAddClassScheduleRuleArgs = {
  classTypeId: Scalars['String'];
  clubId: Scalars['String'];
  input: AddClassScheduleRuleInput;
};


export type MutationAddClassTypeArgs = {
  input: AddClassTypeInput;
};


export type MutationAddClubArgs = {
  input: AddClubInput;
};


export type MutationAddCompanyArgs = {
  input: AddCompanyInput;
};


export type MutationAddCompanyPaymentMethodArgs = {
  input: AddCompanyPaymentMethodInput;
};


export type MutationAddEmailDomainArgs = {
  name: Scalars['String'];
};


export type MutationAddMemberArgs = {
  input: AddMemberInput;
};


export type MutationAddMemberPaymentMethodArgs = {
  input: AddMemberPaymentMethodInput;
  memberId: Scalars['ID'];
};


export type MutationAddMembershipArgs = {
  input: AddMembershipInput;
};


export type MutationAddStaffInviteArgs = {
  input: AddStaffInviteInput;
};


export type MutationAgreeToCurrentMembershipArgs = {
  input: AgreeToMembershipInput;
  memberId: Scalars['ID'];
};


export type MutationAssignMembershipToMemberArgs = {
  input: AssignMembershipInput;
  memberId: Scalars['ID'];
};


export type MutationBookMemberIntoClassArgs = {
  classId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationBookMemberIntoWaitlistArgs = {
  classId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationCancelClassArgs = {
  cancellationReason?: InputMaybe<Scalars['String']>;
  clubId: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationCancelClassBookingArgs = {
  cancellationReason?: InputMaybe<Scalars['String']>;
  classId: Scalars['String'];
  id: Scalars['ID'];
  memberId: Scalars['String'];
};


export type MutationCancelClassCheckInArgs = {
  classId: Scalars['String'];
  id: Scalars['ID'];
  memberId: Scalars['String'];
};


export type MutationCancelClassWaitlistArgs = {
  classId: Scalars['String'];
  id: Scalars['ID'];
  memberId: Scalars['String'];
};


export type MutationCheckInMemberForClassArgs = {
  classId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationDeleteClassArgs = {
  clubId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationDeleteClassScheduleRuleArgs = {
  clubId: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationDeleteClassTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMembershipArgs = {
  id: Scalars['ID'];
};


export type MutationDisableStaffInviteArgs = {
  id: Scalars['ID'];
};


export type MutationEnableEmailDomainArgs = {
  id: Scalars['Int'];
};


export type MutationRedeemStaffInviteArgs = {
  id: Scalars['ID'];
  input: RedeemStaffInviteInput;
};


export type MutationRemoveCompanyPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveEmailDomainArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveMemberPaymentMethodArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationSendSmsArgs = {
  input: SendSmsInput;
  memberId: Scalars['ID'];
};


export type MutationSetAutoTopUpArgs = {
  clubId: Scalars['ID'];
  input: SmsAutoTopUpInput;
};


export type MutationSetCompanyPaymentMethodAsDefaultArgs = {
  id: Scalars['ID'];
};


export type MutationSetDirectDebitConnectionArgs = {
  input: SetDirectDebitConnectionInput;
};


export type MutationSetMemberPaymentMethodAsDefaultArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationSetMembershipStatusArgs = {
  id: Scalars['ID'];
  status: MembershipStatus;
};


export type MutationSetSmsPaymentMethodArgs = {
  clubId: Scalars['ID'];
  companyPaymentMethodId: Scalars['ID'];
};


export type MutationTopUpSmsAccountArgs = {
  clubId: Scalars['ID'];
  input: SmsTopUpInput;
};


export type MutationUpdateClassArgs = {
  id: Scalars['ID'];
  input: UpdateClassInput;
};


export type MutationUpdateClassScheduleRuleArgs = {
  clubId: Scalars['String'];
  id: Scalars['ID'];
  input: UpdateClassScheduleRuleInput;
};


export type MutationUpdateClassTypeArgs = {
  id: Scalars['ID'];
  input: UpdateClassTypeInput;
};


export type MutationUpdateClubArgs = {
  id: Scalars['ID'];
  input: UpdateClubInput;
};


export type MutationUpdateClubWebPortalArgs = {
  clubId: Scalars['ID'];
  input: UpdateClubWebPortalInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateDirectDebitConnectionArgs = {
  clubId: Scalars['ID'];
  input: UpdateDirectDebitConnectionInput;
};


export type MutationUpdateMeStaffArgs = {
  input: UpdateStaffInput;
};


export type MutationUpdateMemberArgs = {
  id: Scalars['ID'];
  input: UpdateMemberInput;
};


export type MutationUpdateMemberMembershipArgs = {
  id: Scalars['ID'];
  input: UpdateMemberMembershipInput;
  memberId: Scalars['ID'];
};


export type MutationUpdateMembershipArgs = {
  id: Scalars['ID'];
  input: UpdateMembershipInput;
};


export type MutationUpdateMessageTemplateArgs = {
  emailInput?: InputMaybe<UpdateEmailMessageTemplateInput>;
  id: MessageTemplateId;
  smsInput?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateStaffArgs = {
  id: Scalars['ID'];
  input: UpdateStaffInput;
};


export type MutationUpdateWebPortalArgs = {
  input?: InputMaybe<UpdateWebPortalInput>;
};


export type MutationVerifyEmailDomainArgs = {
  id: Scalars['Int'];
};

export type PaymentBreakdown = {
  __typename?: 'PaymentBreakdown';
  merchantFee: Scalars['Int'];
  subTotal: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  transactionFee: Scalars['Int'];
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  baseCurrency: Currency;
  directDebitProviderId: Scalars['String'];
  firstAmount: PaymentBreakdown;
  memberFees: MemberFees;
  memberId: Scalars['ID'];
  membershipId: Scalars['ID'];
  nextChargeDate: Scalars['String'];
  regularAmount: ChargeAmount;
  transactionFees: TransactionFees;
};

export enum PaymentMethodType {
  BankAccount = 'BANK_ACCOUNT',
  CreditCard = 'CREDIT_CARD'
}

export enum PaymentServiceType {
  Membership = 'MEMBERSHIP',
  Package = 'PACKAGE',
  PersonalTraining = 'PERSONAL_TRAINING',
  Product = 'PRODUCT',
  Trial = 'TRIAL'
}

export enum PaymentStatus {
  Dishonored = 'DISHONORED',
  Failed = 'FAILED',
  Paid = 'PAID',
  Processing = 'PROCESSING'
}

export type PublicFileUpload = {
  __typename?: 'PublicFileUpload';
  getUrl?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export enum PublicUploadFileType {
  ClassTypeThumbnail = 'CLASS_TYPE_THUMBNAIL',
  CompanyCoverImage = 'COMPANY_COVER_IMAGE',
  CompanyLogo = 'COMPANY_LOGO',
  EmailImage = 'EMAIL_IMAGE',
  MembershipCoverImage = 'MEMBERSHIP_COVER_IMAGE',
  MembershipThumbnail = 'MEMBERSHIP_THUMBNAIL',
  MemberProfilePicture = 'MEMBER_PROFILE_PICTURE',
  PosImage = 'POS_IMAGE',
  PosThumbnail = 'POS_THUMBNAIL',
  StaffProfilePicture = 'STAFF_PROFILE_PICTURE',
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

export type Query = {
  __typename?: 'Query';
  getActivePaymentDetailsForMember?: Maybe<PaymentDetails>;
  getClass?: Maybe<Class>;
  getClassBookingsForClass?: Maybe<ClassBookingConnection>;
  getClassBookingsForMember?: Maybe<ClassBookingConnection>;
  getClassCheckIns?: Maybe<ClassCheckInConnection>;
  getClassScheduleRules?: Maybe<Array<Maybe<ClassScheduleRule>>>;
  getClassType?: Maybe<ClassType>;
  getClassTypes?: Maybe<ClassTypeConnection>;
  getClassWaitlistForClass?: Maybe<ClassWaitlistConnection>;
  getClassWaitlistForMember?: Maybe<ClassWaitlistConnection>;
  getClassesForClub?: Maybe<ClassConnection>;
  getClassesForStaff?: Maybe<ClassConnection>;
  getClassesForType?: Maybe<ClassConnection>;
  getClub?: Maybe<Club>;
  getClubsForMembership?: Maybe<MembershipClubMembershipConnection>;
  getCompany?: Maybe<Company>;
  getCompanyPaymentMethod?: Maybe<CompanyPaymentMethod>;
  getCompanyPaymentMethods?: Maybe<Array<Maybe<CompanyPaymentMethod>>>;
  getDirectDebitConnection?: Maybe<DirectDebitConnection>;
  getDirectDebitProvider?: Maybe<DirectDebitProvider>;
  getDirectDebitProviders?: Maybe<Array<Maybe<DirectDebitProvider>>>;
  getEmailDomains?: Maybe<Array<Maybe<EmailDomain>>>;
  getEmailStats: EmailStats;
  getMeStaff?: Maybe<Staff>;
  getMember?: Maybe<Member>;
  getMemberActiveMembership?: Maybe<MemberMembership>;
  getMemberMembership?: Maybe<MemberMembership>;
  getMemberPaymentMethod?: Maybe<MemberPaymentMethod>;
  getMemberPaymentMethods?: Maybe<Array<Maybe<MemberPaymentMethod>>>;
  getMemberSms?: Maybe<MemberSmsConnection>;
  getMembers?: Maybe<MemberConnection>;
  getMembership?: Maybe<Membership>;
  getMembershipsForClub?: Maybe<MembershipClubMembershipConnection>;
  getMembershipsForCompany?: Maybe<MembershipConnection>;
  getMessageTemplate?: Maybe<MessageTemplate>;
  getMessageTemplates?: Maybe<Array<Maybe<MessageTemplate>>>;
  getMyClubs?: Maybe<StaffClubMembershipConnection>;
  getMyTeam?: Maybe<StaffConnection>;
  getPublicFileUploadUrl?: Maybe<PublicFileUpload>;
  getSmsAccount: SmsAccount;
  getSmsNumber: SmsNumber;
  getStaffInvites?: Maybe<StaffInviteConnection>;
  getSupportedCurrencies: Array<Maybe<Currency>>;
  memberSearch?: Maybe<SearchResult>;
  search?: Maybe<SearchResult>;
  searchForAddress?: Maybe<AddressSearchResult>;
  testDirectDebitCredentials?: Maybe<Scalars['Boolean']>;
};


export type QueryGetActivePaymentDetailsForMemberArgs = {
  memberId: Scalars['ID'];
  usesCreditCard: Scalars['Boolean'];
};


export type QueryGetClassArgs = {
  id: Scalars['ID'];
};


export type QueryGetClassBookingsForClassArgs = {
  classId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassBookingsForMemberArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassCheckInsArgs = {
  classId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken: Scalars['String'];
};


export type QueryGetClassScheduleRulesArgs = {
  classTypeId?: InputMaybe<Scalars['String']>;
  clubId: Scalars['String'];
};


export type QueryGetClassTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetClassTypesArgs = {
  clubId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassWaitlistForClassArgs = {
  classId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassWaitlistForMemberArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetClassesForClubArgs = {
  classTypeId?: InputMaybe<Scalars['String']>;
  clubId: Scalars['String'];
  endsAt?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  startsAt: Scalars['String'];
};


export type QueryGetClassesForStaffArgs = {
  endsAt?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  staffId: Scalars['String'];
  startsAt: Scalars['String'];
};


export type QueryGetClassesForTypeArgs = {
  classTypeId: Scalars['String'];
  endsAt?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  startsAt: Scalars['String'];
};


export type QueryGetClubArgs = {
  id: Scalars['ID'];
};


export type QueryGetClubsForMembershipArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  membershipId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryGetCompanyPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type QueryGetDirectDebitConnectionArgs = {
  clubId: Scalars['String'];
};


export type QueryGetDirectDebitProviderArgs = {
  id: Scalars['ID'];
};


export type QueryGetMemberArgs = {
  id: Scalars['ID'];
};


export type QueryGetMemberActiveMembershipArgs = {
  memberId: Scalars['ID'];
};


export type QueryGetMemberMembershipArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type QueryGetMemberPaymentMethodArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type QueryGetMemberPaymentMethodsArgs = {
  memberId: Scalars['ID'];
};


export type QueryGetMemberSmsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MemberStatus>;
};


export type QueryGetMembershipArgs = {
  id: Scalars['ID'];
};


export type QueryGetMembershipsForClubArgs = {
  clubId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MembershipStatus>;
};


export type QueryGetMembershipsForCompanyArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MembershipStatus>;
};


export type QueryGetMessageTemplateArgs = {
  templateId: MessageTemplateId;
};


export type QueryGetMessageTemplatesArgs = {
  category?: InputMaybe<MessageTemplateCategory>;
};


export type QueryGetPublicFileUploadUrlArgs = {
  entityId?: InputMaybe<Scalars['ID']>;
  fileType: Scalars['String'];
  uploadFileType: PublicUploadFileType;
};


export type QueryGetSmsAccountArgs = {
  clubId: Scalars['ID'];
};


export type QueryGetSmsNumberArgs = {
  clubId: Scalars['ID'];
};


export type QueryGetStaffInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryMemberSearchArgs = {
  input?: InputMaybe<MemberSearchInput>;
};


export type QuerySearchArgs = {
  input?: InputMaybe<SearchInput>;
};


export type QuerySearchForAddressArgs = {
  search: Scalars['String'];
};


export type QueryTestDirectDebitCredentialsArgs = {
  password: Scalars['String'];
  providerId: Scalars['ID'];
  username: Scalars['String'];
};

export type RedeemStaffInviteInput = {
  companyId: Scalars['String'];
  redeemToken: Scalars['String'];
};

export type ScheduledCompanyPayment = {
  __typename?: 'ScheduledCompanyPayment';
  amount: Scalars['Int'];
  attemptNumber: Scalars['Int'];
  companyId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  memberMembershipId?: Maybe<Scalars['String']>;
  merchantFee?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['String'];
  serviceId: Scalars['String'];
  servicePeriod?: Maybe<Scalars['String']>;
  serviceType: PaymentServiceType;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  transactionFee?: Maybe<Scalars['Int']>;
};

export type ScheduledMemberPayment = {
  __typename?: 'ScheduledMemberPayment';
  amount: Scalars['Int'];
  attemptNumber: Scalars['Int'];
  companyId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  memberMembershipId?: Maybe<Scalars['String']>;
  merchantFee?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['String'];
  serviceId: Scalars['String'];
  servicePeriod?: Maybe<Scalars['String']>;
  serviceType: PaymentServiceType;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  transactionFee?: Maybe<Scalars['Int']>;
};

export enum SearchDocumentType {
  ClassType = 'CLASS_TYPE',
  Club = 'CLUB',
  Company = 'COMPANY',
  Member = 'MEMBER',
  Membership = 'MEMBERSHIP',
  Staff = 'STAFF'
}

export type SearchInput = {
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  type?: InputMaybe<SearchDocumentType>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  items: Array<Maybe<SearchResultItem>>;
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  perPage: Scalars['Int'];
  resultCount: Scalars['Int'];
};

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  address?: Maybe<Scalars['AWSJSON']>;
  detail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  homeClubId?: Maybe<Scalars['String']>;
  homeClubName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: SearchDocumentType;
};

export type SendSmsInput = {
  body: Scalars['String'];
};

export type SetDirectDebitConnectionInput = {
  clubId: Scalars['String'];
  directDebitProviderId: Scalars['String'];
  dishonorFee: Scalars['Int'];
  memberPaysDishonorFee: Scalars['Boolean'];
  memberPaysMerchantFee: Scalars['Boolean'];
  memberPaysTransactionFee: Scalars['Boolean'];
  merchantFeePercentage: Scalars['Float'];
  password: Scalars['String'];
  transactionFee: Scalars['Int'];
  username: Scalars['String'];
};

export type SmsAccount = {
  __typename?: 'SmsAccount';
  autoTopUpAmount: Scalars['Int'];
  autoTopUpEnabled: Scalars['Boolean'];
  autoTopUpWhenBelow: Scalars['Int'];
  balance: Scalars['Int'];
  clubId: Scalars['String'];
  companyId: Scalars['String'];
  inboundCost: Scalars['Int'];
  outboundCost: Scalars['Int'];
  paymentMethodId?: Maybe<Scalars['String']>;
  smsEnabled: Scalars['Boolean'];
  smsNumberId?: Maybe<Scalars['String']>;
};

export type SmsAccountResponse = {
  __typename?: 'SmsAccountResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  smsAccount?: Maybe<SmsAccount>;
};

export type SmsAutoTopUpInput = {
  autoTopUpAmount: Scalars['Int'];
  autoTopUpEnabled: Scalars['Boolean'];
  autoTopUpWhenBelow: Scalars['Int'];
};

export type SmsNumber = {
  __typename?: 'SmsNumber';
  countryCode: Scalars['String'];
  id: Scalars['ID'];
  numberType: SmsNumberType;
  phoneNumber: Scalars['String'];
  provider: SmsProvider;
  providerId: Scalars['String'];
};

export enum SmsNumberType {
  DedicatedLongcode = 'DEDICATED_LONGCODE',
  DedicatedShortcode = 'DEDICATED_SHORTCODE',
  SharedLongcode = 'SHARED_LONGCODE',
  SharedShortcode = 'SHARED_SHORTCODE'
}

export enum SmsProvider {
  Twilio = 'TWILIO'
}

export enum SmsStatus {
  Accepted = 'accepted',
  Delivered = 'delivered',
  Queued = 'queued',
  Sending = 'sending',
  Sent = 'sent'
}

export type SmsTopUpInput = {
  amount: Scalars['Int'];
};

export type Staff = {
  __typename?: 'Staff';
  company?: Maybe<Company>;
  displayName: Scalars['String'];
  email: Scalars['String'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  familyName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  role?: Maybe<StaffRole>;
};

export type StaffClubMembership = {
  __typename?: 'StaffClubMembership';
  clubId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type StaffClubMembershipConnection = {
  __typename?: 'StaffClubMembershipConnection';
  items?: Maybe<Array<Maybe<StaffClubMembership>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type StaffConnection = {
  __typename?: 'StaffConnection';
  items?: Maybe<Array<Maybe<Staff>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type StaffInvite = {
  __typename?: 'StaffInvite';
  acceptedAt?: Maybe<Scalars['String']>;
  clubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyId: Scalars['String'];
  createdByName: Scalars['String'];
  disabledAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  expiresAt: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  role: Scalars['String'];
};

export type StaffInviteConnection = {
  __typename?: 'StaffInviteConnection';
  items?: Maybe<Array<Maybe<StaffInvite>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum StaffRole {
  Admin = 'ADMIN',
  FranchiseOwner = 'FRANCHISE_OWNER',
  Owner = 'OWNER',
  Staff = 'STAFF'
}

export type TransactionFees = {
  __typename?: 'TransactionFees';
  dishonorFee?: Maybe<Scalars['Int']>;
  merchantFeePercentage?: Maybe<Scalars['Float']>;
  transactionFee?: Maybe<Scalars['Int']>;
};

export type UpdateClassInput = {
  calendarColor?: InputMaybe<Scalars['String']>;
  clubId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  length?: InputMaybe<Scalars['Int']>;
  maximumCapacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['String']>;
  thumbnailImageUrl?: InputMaybe<Scalars['String']>;
  useCheckIn?: InputMaybe<Scalars['Boolean']>;
  useWaitlist?: InputMaybe<Scalars['Boolean']>;
  waitlistConfirmationWindow?: InputMaybe<Scalars['Int']>;
};

export type UpdateClassScheduleRuleInput = {
  calendarColor?: InputMaybe<Scalars['String']>;
  defaultStaffMemberId?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  length?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  startsFrom: Scalars['String'];
  times?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weekDays?: InputMaybe<Array<InputMaybe<WeekDay>>>;
};

export type UpdateClassTypeInput = {
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  defaultCalendarColor?: InputMaybe<Scalars['String']>;
  defaultLength?: InputMaybe<Scalars['Int']>;
  defaultMaximumCapacity?: InputMaybe<Scalars['Int']>;
  defaultUseCheckIn?: InputMaybe<Scalars['Boolean']>;
  defaultUseWaitlist?: InputMaybe<Scalars['Boolean']>;
  defaultWaitlistConfirmationWindow?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  thumbnailImageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateClubInput = {
  address?: InputMaybe<Scalars['AWSJSON']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateClubWebPortalInput = {
  colorScheme?: InputMaybe<Scalars['AWSJSON']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  logInText?: InputMaybe<Scalars['String']>;
  showPublicClasses?: InputMaybe<Scalars['Boolean']>;
  welcomeText?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  brandName?: InputMaybe<Scalars['String']>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headOfficeAddress?: InputMaybe<Scalars['AWSJSON']>;
  logoImageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  termsAndConditionsUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateDirectDebitConnectionInput = {
  dishonorFee?: InputMaybe<Scalars['Int']>;
  memberPaysDishonorFee?: InputMaybe<Scalars['Boolean']>;
  memberPaysMerchantFee?: InputMaybe<Scalars['Boolean']>;
  memberPaysTransactionFee?: InputMaybe<Scalars['Boolean']>;
  merchantFeePercentage?: InputMaybe<Scalars['Float']>;
  password?: InputMaybe<Scalars['String']>;
  transactionFee?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateEmailMessageTemplateInput = {
  design: Scalars['AWSJSON'];
  subject?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type UpdateMemberInput = {
  address?: InputMaybe<Scalars['AWSJSON']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  givenName?: InputMaybe<Scalars['String']>;
  homeClubId?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  privateNote?: InputMaybe<Scalars['String']>;
  profilePictureUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateMemberMembershipInput = {
  adminFee?: InputMaybe<Scalars['Float']>;
  cancellationFee?: InputMaybe<Scalars['Float']>;
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dishonorFee?: InputMaybe<Scalars['Float']>;
  fobFee?: InputMaybe<Scalars['Float']>;
  hasCustomFees?: InputMaybe<Scalars['Boolean']>;
  joinFee?: InputMaybe<Scalars['Float']>;
  lengthAmount?: InputMaybe<Scalars['Int']>;
  lengthFrequency?: InputMaybe<MembershipLength>;
  membershipId: Scalars['String'];
  merchantFeePercentage?: InputMaybe<Scalars['Float']>;
  paymentAmount?: InputMaybe<Scalars['Float']>;
  paymentFrequency?: InputMaybe<MembershipPaymentFrequency>;
  startDate?: InputMaybe<Scalars['String']>;
  suspensionFee?: InputMaybe<Scalars['Float']>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  visitLimit?: InputMaybe<Scalars['Int']>;
  visitLimitFrequency?: InputMaybe<LimitFrequency>;
};

export type UpdateMemberResponse = {
  __typename?: 'UpdateMemberResponse';
  error?: Maybe<Error>;
  member?: Maybe<Member>;
};

export type UpdateMembershipInput = {
  adminFee?: InputMaybe<Scalars['Float']>;
  availablePaymentPeriods?: InputMaybe<Array<InputMaybe<MembershipPaymentFrequency>>>;
  cancellationFee?: InputMaybe<Scalars['Float']>;
  clubIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fobFee?: InputMaybe<Scalars['Float']>;
  joinFee?: InputMaybe<Scalars['Float']>;
  lengthAmount?: InputMaybe<Scalars['Int']>;
  lengthFrequency?: InputMaybe<MembershipLength>;
  name?: InputMaybe<Scalars['String']>;
  paymentAmount?: InputMaybe<Scalars['Float']>;
  paymentFrequency?: InputMaybe<MembershipPaymentFrequency>;
  suspensionFee?: InputMaybe<Scalars['Float']>;
  thumbnailImageUrl?: InputMaybe<Scalars['String']>;
  visitLimit?: InputMaybe<Scalars['Int']>;
  visitLimitFrequency?: InputMaybe<LimitFrequency>;
};

export type UpdateStaffInput = {
  familyName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  givenName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePictureUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateWebPortalInput = {
  allowClubChanges?: InputMaybe<Scalars['Boolean']>;
  colorScheme?: InputMaybe<Scalars['AWSJSON']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  logInText?: InputMaybe<Scalars['String']>;
  showPublicClasses?: InputMaybe<Scalars['Boolean']>;
  welcomeText?: InputMaybe<Scalars['String']>;
};

export type WebPortal = {
  __typename?: 'WebPortal';
  allowClubChanges: Scalars['Boolean'];
  colorScheme?: Maybe<Scalars['AWSJSON']>;
  companyId: Scalars['String'];
  enabled: Scalars['Boolean'];
  logInText?: Maybe<Scalars['String']>;
  showPublicClasses: Scalars['Boolean'];
  welcomeText?: Maybe<Scalars['String']>;
};

export type WebPortalColorScheme = {
  __typename?: 'WebPortalColorScheme';
  backgroundColor: Scalars['String'];
  buttonBackgroundColor: Scalars['String'];
  buttonTextColor: Scalars['String'];
  textColor: Scalars['String'];
};

export enum WeekDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type SearchForAddressQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type SearchForAddressQuery = { __typename?: 'Query', searchForAddress?: { __typename?: 'AddressSearchResult', items?: Array<{ __typename?: 'Address', fullText: string, address?: string | null | undefined, streetOne: string, streetTwo?: string | null | undefined, city?: string | null | undefined, zipPostcode?: string | null | undefined, state?: string | null | undefined, country?: string | null | undefined, center?: Array<number | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetClassesForClubQueryVariables = Exact<{
  clubId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  startsAt: Scalars['String'];
  classTypeId?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
}>;


export type GetClassesForClubQuery = { __typename?: 'Query', getClassesForClub?: { __typename?: 'ClassConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'Class', id: string, name?: string | null | undefined, description?: string | null | undefined, thumbnailImageUrl?: string | null | undefined, companyId: string, clubId: string, staffId: string, staffName?: string | null | undefined, startsAt: string, length: number, maximumCapacity?: number | null | undefined, currentCapacity: number, useWaitlist: boolean, useCheckIn: boolean, bookedMemberIds?: Array<string | null | undefined> | null | undefined, waitlistMemberIds?: Array<string | null | undefined> | null | undefined, checkedInMemberIds?: Array<string | null | undefined> | null | undefined, cancelledAt?: string | null | undefined, cancellationReason?: string | null | undefined, waitlistConfirmationWindow?: number | null | undefined, calendarColor: string, classScheduleRuleId?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type AddClassMutationVariables = Exact<{
  classTypeId: Scalars['String'];
  input: AddClassInput;
}>;


export type AddClassMutation = { __typename?: 'Mutation', addClass?: { __typename?: 'ClassResponse', class?: { __typename?: 'Class', id: string, name?: string | null | undefined, description?: string | null | undefined, thumbnailImageUrl?: string | null | undefined, companyId: string, clubId: string, staffId: string, staffName?: string | null | undefined, startsAt: string, length: number, maximumCapacity?: number | null | undefined, currentCapacity: number, useWaitlist: boolean, useCheckIn: boolean, bookedMemberIds?: Array<string | null | undefined> | null | undefined, waitlistMemberIds?: Array<string | null | undefined> | null | undefined, checkedInMemberIds?: Array<string | null | undefined> | null | undefined, cancelledAt?: string | null | undefined, cancellationReason?: string | null | undefined, waitlistConfirmationWindow?: number | null | undefined, calendarColor: string, classScheduleRuleId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type UpdateClassMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateClassInput;
}>;


export type UpdateClassMutation = { __typename?: 'Mutation', updateClass?: { __typename?: 'ClassResponse', class?: { __typename?: 'Class', id: string, name?: string | null | undefined, description?: string | null | undefined, thumbnailImageUrl?: string | null | undefined, companyId: string, clubId: string, staffId: string, staffName?: string | null | undefined, startsAt: string, length: number, maximumCapacity?: number | null | undefined, currentCapacity: number, useWaitlist: boolean, useCheckIn: boolean, bookedMemberIds?: Array<string | null | undefined> | null | undefined, waitlistMemberIds?: Array<string | null | undefined> | null | undefined, checkedInMemberIds?: Array<string | null | undefined> | null | undefined, cancelledAt?: string | null | undefined, cancellationReason?: string | null | undefined, waitlistConfirmationWindow?: number | null | undefined, calendarColor: string, classScheduleRuleId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type DeleteClassMutationVariables = Exact<{
  id: Scalars['ID'];
  clubId: Scalars['ID'];
}>;


export type DeleteClassMutation = { __typename?: 'Mutation', deleteClass?: { __typename?: 'ClassResponse', class?: { __typename?: 'Class', id: string, name?: string | null | undefined, description?: string | null | undefined, thumbnailImageUrl?: string | null | undefined, companyId: string, clubId: string, staffId: string, staffName?: string | null | undefined, startsAt: string, length: number, maximumCapacity?: number | null | undefined, currentCapacity: number, useWaitlist: boolean, useCheckIn: boolean, bookedMemberIds?: Array<string | null | undefined> | null | undefined, waitlistMemberIds?: Array<string | null | undefined> | null | undefined, checkedInMemberIds?: Array<string | null | undefined> | null | undefined, cancelledAt?: string | null | undefined, cancellationReason?: string | null | undefined, waitlistConfirmationWindow?: number | null | undefined, calendarColor: string, classScheduleRuleId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type CancelClassMutationVariables = Exact<{
  id: Scalars['ID'];
  clubId: Scalars['String'];
  cancellationReason?: InputMaybe<Scalars['String']>;
}>;


export type CancelClassMutation = { __typename?: 'Mutation', cancelClass?: { __typename?: 'ClassResponse', class?: { __typename?: 'Class', id: string, name?: string | null | undefined, description?: string | null | undefined, thumbnailImageUrl?: string | null | undefined, companyId: string, clubId: string, staffId: string, staffName?: string | null | undefined, startsAt: string, length: number, maximumCapacity?: number | null | undefined, currentCapacity: number, useWaitlist: boolean, useCheckIn: boolean, bookedMemberIds?: Array<string | null | undefined> | null | undefined, waitlistMemberIds?: Array<string | null | undefined> | null | undefined, checkedInMemberIds?: Array<string | null | undefined> | null | undefined, cancelledAt?: string | null | undefined, cancellationReason?: string | null | undefined, waitlistConfirmationWindow?: number | null | undefined, calendarColor: string, classScheduleRuleId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetClassScheduleRulesQueryVariables = Exact<{
  classTypeId?: InputMaybe<Scalars['String']>;
  clubId: Scalars['String'];
}>;


export type GetClassScheduleRulesQuery = { __typename?: 'Query', getClassScheduleRules?: Array<{ __typename?: 'ClassScheduleRule', classTypeId: string, defaultStaffMemberId?: string | null | undefined, id: string, length: number, name: string, times: Array<string | null | undefined>, weekDays: Array<WeekDay | null | undefined>, calendarColor: string, endsAt: string } | null | undefined> | null | undefined };

export type AddClassScheduleRuleMutationVariables = Exact<{
  clubId: Scalars['String'];
  classTypeId: Scalars['String'];
  input: AddClassScheduleRuleInput;
}>;


export type AddClassScheduleRuleMutation = { __typename?: 'Mutation', addClassScheduleRule?: { __typename?: 'ClassScheduleResponse', classSchedule?: { __typename?: 'ClassScheduleRule', classTypeId: string, defaultStaffMemberId?: string | null | undefined, id: string, length: number, name: string, times: Array<string | null | undefined>, weekDays: Array<WeekDay | null | undefined>, calendarColor: string, endsAt: string } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type UpdateClassScheduleRuleMutationVariables = Exact<{
  clubId: Scalars['String'];
  id: Scalars['ID'];
  input: UpdateClassScheduleRuleInput;
}>;


export type UpdateClassScheduleRuleMutation = { __typename?: 'Mutation', updateClassScheduleRule?: { __typename?: 'ClassScheduleResponse', classSchedule?: { __typename?: 'ClassScheduleRule', classTypeId: string, defaultStaffMemberId?: string | null | undefined, id: string, length: number, name: string, times: Array<string | null | undefined>, weekDays: Array<WeekDay | null | undefined>, calendarColor: string, endsAt: string } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type DeleteClassScheduleRuleMutationVariables = Exact<{
  clubId: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteClassScheduleRuleMutation = { __typename?: 'Mutation', deleteClassScheduleRule?: { __typename?: 'ClassScheduleResponse', classSchedule?: { __typename?: 'ClassScheduleRule', classTypeId: string, defaultStaffMemberId?: string | null | undefined, id: string, length: number, name: string, times: Array<string | null | undefined>, weekDays: Array<WeekDay | null | undefined>, calendarColor: string, endsAt: string } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetClassTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  clubId?: InputMaybe<Scalars['String']>;
}>;


export type GetClassTypesQuery = { __typename?: 'Query', getClassTypes?: { __typename?: 'ClassTypeConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'ClassType', id: string, name: string, description: string, thumbnailImageUrl?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, defaultMaximumCapacity?: number | null | undefined, defaultUseWaitlist: boolean, defaultUseCheckIn: boolean, defaultLength?: number | null | undefined, defaultWaitlistConfirmationWindow?: number | null | undefined, defaultCalendarColor?: string | null | undefined, created?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetClassTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClassTypeQuery = { __typename?: 'Query', getClassType?: { __typename?: 'ClassType', id: string, name: string, description: string, thumbnailImageUrl?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, defaultMaximumCapacity?: number | null | undefined, defaultUseWaitlist: boolean, defaultUseCheckIn: boolean, defaultLength?: number | null | undefined, defaultWaitlistConfirmationWindow?: number | null | undefined, defaultCalendarColor?: string | null | undefined, created?: string | null | undefined } | null | undefined };

export type AddClassTypeMutationVariables = Exact<{
  input: AddClassTypeInput;
}>;


export type AddClassTypeMutation = { __typename?: 'Mutation', addClassType?: { __typename?: 'ClassTypeResponse', classType?: { __typename?: 'ClassType', id: string, name: string, description: string, thumbnailImageUrl?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, defaultMaximumCapacity?: number | null | undefined, defaultUseWaitlist: boolean, defaultUseCheckIn: boolean, defaultLength?: number | null | undefined, defaultWaitlistConfirmationWindow?: number | null | undefined, defaultCalendarColor?: string | null | undefined, created?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type UpdateClassTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateClassTypeInput;
}>;


export type UpdateClassTypeMutation = { __typename?: 'Mutation', updateClassType?: { __typename?: 'ClassTypeResponse', classType?: { __typename?: 'ClassType', id: string, name: string, description: string, thumbnailImageUrl?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, defaultMaximumCapacity?: number | null | undefined, defaultUseWaitlist: boolean, defaultUseCheckIn: boolean, defaultLength?: number | null | undefined, defaultWaitlistConfirmationWindow?: number | null | undefined, defaultCalendarColor?: string | null | undefined, created?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type DeleteClassTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteClassTypeMutation = { __typename?: 'Mutation', deleteClassType?: { __typename?: 'ClassTypeResponse', classType?: { __typename?: 'ClassType', id: string, name: string, description: string, thumbnailImageUrl?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, defaultMaximumCapacity?: number | null | undefined, defaultUseWaitlist: boolean, defaultUseCheckIn: boolean, defaultLength?: number | null | undefined, defaultWaitlistConfirmationWindow?: number | null | undefined, defaultCalendarColor?: string | null | undefined, created?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetMyClubsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyClubsQuery = { __typename?: 'Query', getMyClubs?: { __typename?: 'StaffClubMembershipConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'StaffClubMembership', id: string, name: string, clubId: string } | null | undefined> | null | undefined } | null | undefined };

export type GetClubQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClubQuery = { __typename?: 'Query', getClub?: { __typename?: 'Club', id: string, name: string, description: string, countryCode: string, address?: string | null | undefined, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type GetClubWebPortalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClubWebPortalQuery = { __typename?: 'Query', getClub?: { __typename?: 'Club', id: string, name: string, description: string, address?: string | null | undefined, clubWebPortal?: { __typename?: 'ClubWebPortal', clubId: string, enabled: boolean, showPublicClasses: boolean, welcomeText?: string | null | undefined, logInText?: string | null | undefined, colorScheme?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateClubMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateClubInput;
}>;


export type UpdateClubMutation = { __typename?: 'Mutation', updateClub?: { __typename?: 'ClubResponse', club?: { __typename?: 'Club', id: string, name: string, description: string, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type UpdateClubWebPortalMutationVariables = Exact<{
  clubId: Scalars['ID'];
  input: UpdateClubWebPortalInput;
}>;


export type UpdateClubWebPortalMutation = { __typename?: 'Mutation', updateClubWebPortal?: { __typename?: 'ClubWebPortal', clubId: string, enabled: boolean, showPublicClasses: boolean, welcomeText?: string | null | undefined, logInText?: string | null | undefined, colorScheme?: string | null | undefined } | null | undefined };

export type AddClubMutationVariables = Exact<{
  input: AddClubInput;
}>;


export type AddClubMutation = { __typename?: 'Mutation', addClub?: { __typename?: 'ClubResponse', club?: { __typename?: 'Club', id: string, name: string, description: string, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: { __typename?: 'CompanyResponse', company?: { __typename?: 'Company', id: string, brandName?: string | null | undefined, name: string, description?: string | null | undefined, headOfficeAddress: string } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type AddCompanyMutationVariables = Exact<{
  input: AddCompanyInput;
}>;


export type AddCompanyMutation = { __typename?: 'Mutation', addCompany?: { __typename?: 'CompanyResponse', company?: { __typename?: 'Company', id: string, brandName?: string | null | undefined, name: string, description?: string | null | undefined, headOfficeAddress: string } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetCompanyPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyPaymentMethodsQuery = { __typename?: 'Query', getCompanyPaymentMethods?: Array<{ __typename?: 'CompanyPaymentMethod', id: string, isDefault: boolean, companyId: string, type: PaymentMethodType, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined> | null | undefined };

export type GetCompanyPaymentMethodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCompanyPaymentMethodQuery = { __typename?: 'Query', getCompanyPaymentMethod?: { __typename?: 'CompanyPaymentMethod', id: string, isDefault: boolean, companyId: string, type: PaymentMethodType, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined };

export type AddCompanyPaymentMethodMutationVariables = Exact<{
  input: AddCompanyPaymentMethodInput;
}>;


export type AddCompanyPaymentMethodMutation = { __typename?: 'Mutation', addCompanyPaymentMethod?: { __typename?: 'CompanyPaymentMethodResult', companyPaymentMethod?: { __typename?: 'CompanyPaymentMethod', id: string, isDefault: boolean, companyId: string, type: PaymentMethodType, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type RemoveCompanyPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCompanyPaymentMethodMutation = { __typename?: 'Mutation', removeCompanyPaymentMethod?: { __typename?: 'CompanyPaymentMethodResult', companyPaymentMethod?: { __typename?: 'CompanyPaymentMethod', id: string, isDefault: boolean, companyId: string, type: PaymentMethodType, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type SetCompanyPaymentMethodAsDefaultMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetCompanyPaymentMethodAsDefaultMutation = { __typename?: 'Mutation', setCompanyPaymentMethodAsDefault?: { __typename?: 'CompanyPaymentMethodResult', companyPaymentMethod?: { __typename?: 'CompanyPaymentMethod', id: string, isDefault: boolean, companyId: string, type: PaymentMethodType, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetDirectDebitProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDirectDebitProvidersQuery = { __typename?: 'Query', getDirectDebitProviders?: Array<{ __typename?: 'DirectDebitProvider', id: string, name: string, description: string, url: string, logoUrl: string, termsAndConditionsUrl: string, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined> | null | undefined };

export type GetDirectDebitProviderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDirectDebitProviderQuery = { __typename?: 'Query', getDirectDebitProvider?: { __typename?: 'DirectDebitProvider', id: string, name: string, description: string, url: string, logoUrl: string, termsAndConditionsUrl: string, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type TestDirectDebitCredentialsQueryVariables = Exact<{
  providerId: Scalars['ID'];
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type TestDirectDebitCredentialsQuery = { __typename?: 'Query', testDirectDebitCredentials?: boolean | null | undefined };

export type GetDirectDebitConnectionQueryVariables = Exact<{
  clubId: Scalars['String'];
}>;


export type GetDirectDebitConnectionQuery = { __typename?: 'Query', getDirectDebitConnection?: { __typename?: 'DirectDebitConnection', companyId: string, clubId: string, directDebitProviderId: string, name: string, username: string, transactionFee: number, merchantFeePercentage: number, dishonorFee: number, memberPaysDishonorFee: boolean, memberPaysTransactionFee: boolean, memberPaysMerchantFee: boolean, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type SetDirectDebitConnectionMutationVariables = Exact<{
  input: SetDirectDebitConnectionInput;
}>;


export type SetDirectDebitConnectionMutation = { __typename?: 'Mutation', setDirectDebitConnection?: { __typename?: 'DirectDebitConnection', companyId: string, username: string, directDebitProviderId: string, name: string, transactionFee: number, merchantFeePercentage: number, dishonorFee: number, memberPaysDishonorFee: boolean, memberPaysTransactionFee: boolean, memberPaysMerchantFee: boolean, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type UpdateDirectDebitConnectionMutationVariables = Exact<{
  clubId: Scalars['ID'];
  input: UpdateDirectDebitConnectionInput;
}>;


export type UpdateDirectDebitConnectionMutation = { __typename?: 'Mutation', updateDirectDebitConnection?: { __typename?: 'DirectDebitConnection', companyId: string, username: string, directDebitProviderId: string, name: string, transactionFee: number, merchantFeePercentage: number, dishonorFee: number, memberPaysDishonorFee: boolean, memberPaysTransactionFee: boolean, memberPaysMerchantFee: boolean, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type GetEmailStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailStatsQuery = { __typename?: 'Query', getEmailStats: { __typename?: 'EmailStats', sent: number, bounced: number, bounceRate: number, spamComplaints: number, spamComplaintsRate: number, opens: number, uniqueOpens: number } };

export type GetEmailDomainsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailDomainsQuery = { __typename?: 'Query', getEmailDomains?: Array<{ __typename?: 'EmailDomain', id: number, isDefault: boolean, name: string, active: boolean, returnPathDomain: string, returnPathDomainCNAMEValue: string, returnPathDomainVerified: boolean, dKIMHost?: string | null | undefined, dKIMTextValue?: string | null | undefined, dKIMVerified: boolean, dKIMPendingHost?: string | null | undefined, dKIMPendingTextValue?: string | null | undefined } | null | undefined> | null | undefined };

export type AddEmailDomainMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddEmailDomainMutation = { __typename?: 'Mutation', addEmailDomain?: { __typename?: 'EmailDomainResponse', emailDomain?: { __typename?: 'EmailDomain', id: number, isDefault: boolean, name: string, active: boolean, returnPathDomain: string, returnPathDomainCNAMEValue: string, returnPathDomainVerified: boolean, dKIMHost?: string | null | undefined, dKIMTextValue?: string | null | undefined, dKIMVerified: boolean, dKIMPendingHost?: string | null | undefined, dKIMPendingTextValue?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type RemoveEmailDomainMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveEmailDomainMutation = { __typename?: 'Mutation', removeEmailDomain?: { __typename?: 'EmailDomainResponse', emailDomain?: { __typename?: 'EmailDomain', id: number } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type VerifyEmailDomainMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type VerifyEmailDomainMutation = { __typename?: 'Mutation', verifyEmailDomain?: { __typename?: 'EmailDomainResponse', emailDomain?: { __typename?: 'EmailDomain', id: number, isDefault: boolean, name: string, active: boolean, returnPathDomain: string, returnPathDomainCNAMEValue: string, returnPathDomainVerified: boolean, dKIMHost?: string | null | undefined, dKIMTextValue?: string | null | undefined, dKIMVerified: boolean, dKIMPendingHost?: string | null | undefined, dKIMPendingTextValue?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type EnableEmailDomainMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EnableEmailDomainMutation = { __typename?: 'Mutation', enableEmailDomain?: { __typename?: 'EmailDomainResponse', emailDomain?: { __typename?: 'EmailDomain', id: number, isDefault: boolean, name: string, active: boolean, returnPathDomain: string, returnPathDomainCNAMEValue: string, returnPathDomainVerified: boolean, dKIMHost?: string | null | undefined, dKIMTextValue?: string | null | undefined, dKIMVerified: boolean, dKIMPendingHost?: string | null | undefined, dKIMPendingTextValue?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetPublicFileUploadUrlQueryVariables = Exact<{
  uploadFileType: PublicUploadFileType;
  fileType: Scalars['String'];
  entityId?: InputMaybe<Scalars['ID']>;
}>;


export type GetPublicFileUploadUrlQuery = { __typename?: 'Query', getPublicFileUploadUrl?: { __typename?: 'PublicFileUpload', uploadUrl?: string | null | undefined, getUrl?: string | null | undefined } | null | undefined };

export type GetMeStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeStaffQuery = { __typename?: 'Query', getMeStaff?: { __typename?: 'Staff', id: string, givenName: string, familyName?: string | null | undefined, displayName: string, email: string, mobileNumber?: string | null | undefined, profilePictureUrl?: string | null | undefined, gender?: Gender | null | undefined, role?: StaffRole | null | undefined, company?: { __typename?: 'Company', id: string, name: string, brandName?: string | null | undefined, headOfficeAddress: string, description?: string | null | undefined, logoImageUrl?: string | null | undefined, coverImageUrl?: string | null | undefined, termsAndConditionsUrl?: string | null | undefined } | null | undefined } | null | undefined };

export type GetMemberPaymentMethodsQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type GetMemberPaymentMethodsQuery = { __typename?: 'Query', getMemberPaymentMethods?: Array<{ __typename?: 'MemberPaymentMethod', id: string, isDefault: boolean, memberId: string, companyId: string, type: PaymentMethodType, providerId: string, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined> | null | undefined };

export type GetMemberPaymentMethodQueryVariables = Exact<{
  memberId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetMemberPaymentMethodQuery = { __typename?: 'Query', getMemberPaymentMethod?: { __typename?: 'MemberPaymentMethod', id: string, isDefault: boolean, memberId: string, companyId: string, type: PaymentMethodType, providerId: string, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined };

export type AddMemberPaymentMethodMutationVariables = Exact<{
  memberId: Scalars['ID'];
  input: AddMemberPaymentMethodInput;
}>;


export type AddMemberPaymentMethodMutation = { __typename?: 'Mutation', addMemberPaymentMethod?: { __typename?: 'MemberPaymentMethodResult', memberPaymentMethod?: { __typename?: 'MemberPaymentMethod', id: string, isDefault: boolean, memberId: string, companyId: string, type: PaymentMethodType, providerId: string, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type RemoveMemberPaymentMethodMutationVariables = Exact<{
  memberId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveMemberPaymentMethodMutation = { __typename?: 'Mutation', removeMemberPaymentMethod?: { __typename?: 'MemberPaymentMethodResult', memberPaymentMethod?: { __typename?: 'MemberPaymentMethod', id: string, isDefault: boolean, memberId: string, companyId: string, type: PaymentMethodType, providerId: string, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type SetMemberPaymentMethodAsDefaultMutationVariables = Exact<{
  memberId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type SetMemberPaymentMethodAsDefaultMutation = { __typename?: 'Mutation', setMemberPaymentMethodAsDefault?: { __typename?: 'MemberPaymentMethodResult', memberPaymentMethod?: { __typename?: 'MemberPaymentMethod', id: string, isDefault: boolean, memberId: string, companyId: string, type: PaymentMethodType, providerId: string, maskedNumber: string, expiryMonth?: string | null | undefined, expiryYear?: string | null | undefined, accountName?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetMembersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MemberStatus>;
}>;


export type GetMembersQuery = { __typename?: 'Query', getMembers?: { __typename?: 'MemberConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'Member', id: string, email: string, givenName: string, familyName?: string | null | undefined, displayName: string, mobileNumber?: string | null | undefined, status: MemberStatus, gender?: Gender | null | undefined, emailVerified?: boolean | null | undefined, profilePictureUrl?: string | null | undefined, homeClubId: string, homeClubName: string, activeMembershipId?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetMemberActiveMembershipQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type GetMemberActiveMembershipQuery = { __typename?: 'Query', getMemberActiveMembership?: { __typename?: 'MemberMembership', id: string, memberName: string, membershipName: string, memberId: string, membershipId: string, companyId: string, startDate: string, paymentFrequency: MembershipPaymentFrequency, directDebitProviderId?: string | null | undefined, paymentAmount: number, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, transactionFee?: number | null | undefined, merchantFeePercentage?: number | null | undefined, dishonorFee?: number | null | undefined, visitLimit?: number | null | undefined, hasCustomFees: boolean, visitLimitFrequency?: LimitFrequency | null | undefined, lengthFrequency: MembershipLength, lengthAmount: number } | null | undefined };

export type GetActivePaymentDetailsForMemberQueryVariables = Exact<{
  memberId: Scalars['ID'];
  usesCreditCard: Scalars['Boolean'];
}>;


export type GetActivePaymentDetailsForMemberQuery = { __typename?: 'Query', getActivePaymentDetailsForMember?: { __typename?: 'PaymentDetails', memberId: string, membershipId: string, directDebitProviderId: string, nextChargeDate: string, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number }, firstAmount: { __typename?: 'PaymentBreakdown', total: number, subTotal: number, tax: number, transactionFee: number, merchantFee: number }, regularAmount: { __typename?: 'ChargeAmount', frequency: MembershipPaymentFrequency, amount: { __typename?: 'PaymentBreakdown', total: number, subTotal: number, tax: number, transactionFee: number, merchantFee: number } }, memberFees: { __typename?: 'MemberFees', joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined }, transactionFees: { __typename?: 'TransactionFees', transactionFee?: number | null | undefined, merchantFeePercentage?: number | null | undefined, dishonorFee?: number | null | undefined } } | null | undefined };

export type GetMemberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMemberQuery = { __typename?: 'Query', getMember?: { __typename?: 'Member', id: string, email: string, givenName: string, familyName?: string | null | undefined, displayName: string, mobileNumber?: string | null | undefined, status: MemberStatus, gender?: Gender | null | undefined, emailVerified?: boolean | null | undefined, address?: string | null | undefined, dateOfBirth?: string | null | undefined, profilePictureUrl?: string | null | undefined, privateNote?: string | null | undefined, activeMembershipId?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, homeClubId: string, homeClubName: string } | null | undefined };

export type AddMemberMutationVariables = Exact<{
  input: AddMemberInput;
}>;


export type AddMemberMutation = { __typename?: 'Mutation', addMember?: { __typename?: 'Member', id: string, email: string, givenName: string, familyName?: string | null | undefined, displayName: string, mobileNumber?: string | null | undefined, status: MemberStatus, gender?: Gender | null | undefined, emailVerified?: boolean | null | undefined, address?: string | null | undefined, dateOfBirth?: string | null | undefined, profilePictureUrl?: string | null | undefined, homeClubId: string, homeClubName: string } | null | undefined };

export type UpdateMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMemberInput;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', updateMember?: { __typename?: 'UpdateMemberResponse', member?: { __typename?: 'Member', id: string, email: string, givenName: string, familyName?: string | null | undefined, displayName: string, mobileNumber?: string | null | undefined, status: MemberStatus, gender?: Gender | null | undefined, emailVerified?: boolean | null | undefined, address?: string | null | undefined, dateOfBirth?: string | null | undefined, profilePictureUrl?: string | null | undefined, privateNote?: string | null | undefined, activeMembershipId?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined, homeClubId: string, homeClubName: string } | null | undefined, error?: { __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined } | null | undefined };

export type AssignMembershipToMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
  input: AssignMembershipInput;
}>;


export type AssignMembershipToMemberMutation = { __typename?: 'Mutation', assignMembershipToMember?: { __typename?: 'MemberMembershipResponse', memberMembership?: { __typename?: 'MemberMembership', id: string, memberName: string, membershipName: string, memberId: string, membershipId: string, companyId: string, startDate: string, paymentFrequency: MembershipPaymentFrequency, paymentAmount: number, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, lengthFrequency: MembershipLength, lengthAmount: number, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, transactionFee?: number | null | undefined, merchantFeePercentage?: number | null | undefined, dishonorFee?: number | null | undefined, hasCustomFees: boolean } | null | undefined, error?: { __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateMemberMembershipMutationVariables = Exact<{
  memberId: Scalars['ID'];
  id: Scalars['ID'];
  input: UpdateMemberMembershipInput;
}>;


export type UpdateMemberMembershipMutation = { __typename?: 'Mutation', updateMemberMembership?: { __typename?: 'MemberMembershipResponse', memberMembership?: { __typename?: 'MemberMembership', id: string, memberName: string, membershipName: string, memberId: string, membershipId: string, companyId: string, startDate: string, paymentFrequency: MembershipPaymentFrequency, paymentAmount: number, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, lengthFrequency: MembershipLength, lengthAmount: number, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, transactionFee?: number | null | undefined, merchantFeePercentage?: number | null | undefined, dishonorFee?: number | null | undefined, hasCustomFees: boolean } | null | undefined, error?: { __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined } | null | undefined };

export type AgreeToCurrentMembershipMutationVariables = Exact<{
  memberId: Scalars['ID'];
  input: AgreeToMembershipInput;
}>;


export type AgreeToCurrentMembershipMutation = { __typename?: 'Mutation', agreeToCurrentMembership: { __typename?: 'MemberMembership', id: string, memberName: string, membershipName: string, memberId: string, membershipId: string, companyId: string, startDate: string, paymentFrequency: MembershipPaymentFrequency, paymentAmount: number, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, lengthFrequency: MembershipLength, lengthAmount: number, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, transactionFee?: number | null | undefined, merchantFeePercentage?: number | null | undefined, dishonorFee?: number | null | undefined, hasCustomFees: boolean } };

export type GetMembershipsForCompanyQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetMembershipsForCompanyQuery = { __typename?: 'Query', getMembershipsForCompany?: { __typename?: 'MembershipConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'Membership', id: string, name: string, description: string, status: MembershipStatus, publishedAt?: string | null | undefined, thumbnailImageUrl?: string | null | undefined, paymentFrequency?: MembershipPaymentFrequency | null | undefined, paymentAmount?: number | null | undefined, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined> | null | undefined } | null | undefined };

export type GetMembershipsForClubQueryVariables = Exact<{
  clubId: Scalars['ID'];
  status?: InputMaybe<MembershipStatus>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetMembershipsForClubQuery = { __typename?: 'Query', getMembershipsForClub?: { __typename?: 'MembershipClubMembershipConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'MembershipClubMembership', membershipId: string, clubId: string, membershipName: string, membershipDescription: string, clubName: string, status: MembershipStatus, publishedAt?: string | null | undefined, thumbnailImageUrl?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetMembershipQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMembershipQuery = { __typename?: 'Query', getMembership?: { __typename?: 'Membership', availablePaymentPeriods?: Array<MembershipPaymentFrequency | null | undefined> | null | undefined, coverImageUrl?: string | null | undefined, description: string, id: string, lengthAmount?: number | null | undefined, lengthFrequency?: MembershipLength | null | undefined, name: string, paymentAmount?: number | null | undefined, paymentFrequency?: MembershipPaymentFrequency | null | undefined, publishedAt?: string | null | undefined, status: MembershipStatus, thumbnailImageUrl?: string | null | undefined, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type AddMembershipMutationVariables = Exact<{
  input: AddMembershipInput;
}>;


export type AddMembershipMutation = { __typename?: 'Mutation', addMembership?: { __typename?: 'AddMembershipResponse', membership?: { __typename?: 'Membership', availablePaymentPeriods?: Array<MembershipPaymentFrequency | null | undefined> | null | undefined, coverImageUrl?: string | null | undefined, description: string, id: string, lengthAmount?: number | null | undefined, lengthFrequency?: MembershipLength | null | undefined, name: string, paymentAmount?: number | null | undefined, paymentFrequency?: MembershipPaymentFrequency | null | undefined, publishedAt?: string | null | undefined, status: MembershipStatus, thumbnailImageUrl?: string | null | undefined, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined, error?: { __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateMembershipMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMembershipInput;
}>;


export type UpdateMembershipMutation = { __typename?: 'Mutation', updateMembership?: { __typename?: 'Membership', availablePaymentPeriods?: Array<MembershipPaymentFrequency | null | undefined> | null | undefined, coverImageUrl?: string | null | undefined, description: string, id: string, lengthAmount?: number | null | undefined, lengthFrequency?: MembershipLength | null | undefined, name: string, paymentAmount?: number | null | undefined, paymentFrequency?: MembershipPaymentFrequency | null | undefined, publishedAt?: string | null | undefined, status: MembershipStatus, thumbnailImageUrl?: string | null | undefined, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type SetMembershipStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: MembershipStatus;
}>;


export type SetMembershipStatusMutation = { __typename?: 'Mutation', setMembershipStatus?: { __typename?: 'Membership', availablePaymentPeriods?: Array<MembershipPaymentFrequency | null | undefined> | null | undefined, coverImageUrl?: string | null | undefined, description: string, id: string, lengthAmount?: number | null | undefined, lengthFrequency?: MembershipLength | null | undefined, name: string, paymentAmount?: number | null | undefined, paymentFrequency?: MembershipPaymentFrequency | null | undefined, publishedAt?: string | null | undefined, status: MembershipStatus, thumbnailImageUrl?: string | null | undefined, visitLimit?: number | null | undefined, visitLimitFrequency?: LimitFrequency | null | undefined, joinFee?: number | null | undefined, adminFee?: number | null | undefined, fobFee?: number | null | undefined, cancellationFee?: number | null | undefined, suspensionFee?: number | null | undefined, baseCurrency: { __typename?: 'Currency', code: string, base: number, exponent: number } } | null | undefined };

export type GetMessageTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessageTemplatesQuery = { __typename?: 'Query', getMessageTemplates?: Array<{ __typename?: 'MessageTemplate', id: MessageTemplateId, category: MessageTemplateCategory, description: string } | null | undefined> | null | undefined };

export type GetMessageTemplateQueryVariables = Exact<{
  templateId: MessageTemplateId;
}>;


export type GetMessageTemplateQuery = { __typename?: 'Query', getMessageTemplate?: { __typename?: 'MessageTemplate', id: MessageTemplateId, category: MessageTemplateCategory, description: string, sms?: string | null | undefined, mergeTags?: Array<{ __typename?: 'MergeTag', key: string, name: string, value: string, sample?: string | null | undefined } | null | undefined> | null | undefined, email?: { __typename?: 'EmailMessageTemplate', design: string, subject?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdateMessageTemplateMutationVariables = Exact<{
  id: MessageTemplateId;
  emailInput?: InputMaybe<UpdateEmailMessageTemplateInput>;
  smsInput?: InputMaybe<Scalars['String']>;
}>;


export type UpdateMessageTemplateMutation = { __typename?: 'Mutation', updateMessageTemplate?: { __typename?: 'MessageTemplateResult', messageTemplate?: { __typename?: 'MessageTemplate', id: MessageTemplateId, category: MessageTemplateCategory, sms?: string | null | undefined, email?: { __typename?: 'EmailMessageTemplate', subject?: string | null | undefined } | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type SearchQueryVariables = Exact<{
  input: SearchInput;
}>;


export type SearchQuery = { __typename?: 'Query', search?: { __typename?: 'SearchResult', page: number, pageCount: number, perPage: number, resultCount: number, items: Array<{ __typename?: 'SearchResultItem', id: string, title: string, detail?: string | null | undefined, thumbnailUrl?: string | null | undefined, type: SearchDocumentType, status?: string | null | undefined, email?: string | null | undefined, homeClubId?: string | null | undefined, homeClubName?: string | null | undefined } | null | undefined> } | null | undefined };

export type MemberSearchQueryVariables = Exact<{
  input: MemberSearchInput;
}>;


export type MemberSearchQuery = { __typename?: 'Query', memberSearch?: { __typename?: 'SearchResult', page: number, pageCount: number, perPage: number, resultCount: number, items: Array<{ __typename?: 'SearchResultItem', id: string, title: string, detail?: string | null | undefined, thumbnailUrl?: string | null | undefined, type: SearchDocumentType, status?: string | null | undefined, email?: string | null | undefined, homeClubId?: string | null | undefined, homeClubName?: string | null | undefined } | null | undefined> } | null | undefined };

export type GetSmsNumberQueryVariables = Exact<{
  clubId: Scalars['ID'];
}>;


export type GetSmsNumberQuery = { __typename?: 'Query', getSmsNumber: { __typename?: 'SmsNumber', id: string, phoneNumber: string, providerId: string, numberType: SmsNumberType, provider: SmsProvider, countryCode: string } };

export type GetSmsAccountQueryVariables = Exact<{
  clubId: Scalars['ID'];
}>;


export type GetSmsAccountQuery = { __typename?: 'Query', getSmsAccount: { __typename?: 'SmsAccount', balance: number, clubId: string, outboundCost: number, inboundCost: number, smsEnabled: boolean, autoTopUpEnabled: boolean, autoTopUpAmount: number, autoTopUpWhenBelow: number, smsNumberId?: string | null | undefined, paymentMethodId?: string | null | undefined } };

export type GetMemberSmsQueryVariables = Exact<{
  memberId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberSmsQuery = { __typename?: 'Query', getMemberSms?: { __typename?: 'MemberSmsConnection', nextToken?: string | null | undefined, memberSms?: Array<{ __typename?: 'MemberSms', id: string, body: string, direction: MessageDirection, to: string, sentAt?: string | null | undefined, deliveredAt?: string | null | undefined, openedAt?: string | null | undefined, clickedAt?: string | null | undefined, messageTemplateId?: MessageTemplateId | null | undefined, templateEventId?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type TopUpSmsAccountMutationVariables = Exact<{
  input: SmsTopUpInput;
  clubId: Scalars['ID'];
}>;


export type TopUpSmsAccountMutation = { __typename?: 'Mutation', topUpSmsAccount: { __typename?: 'SmsAccountResponse', smsAccount?: { __typename?: 'SmsAccount', balance: number, clubId: string, outboundCost: number, inboundCost: number, smsEnabled: boolean, autoTopUpEnabled: boolean, autoTopUpAmount: number, smsNumberId?: string | null | undefined, autoTopUpWhenBelow: number, paymentMethodId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } };

export type SetAutoTopUpMutationVariables = Exact<{
  input: SmsAutoTopUpInput;
  clubId: Scalars['ID'];
}>;


export type SetAutoTopUpMutation = { __typename?: 'Mutation', setAutoTopUp: { __typename?: 'SmsAccountResponse', smsAccount?: { __typename?: 'SmsAccount', balance: number, clubId: string, outboundCost: number, inboundCost: number, smsEnabled: boolean, autoTopUpEnabled: boolean, autoTopUpAmount: number, smsNumberId?: string | null | undefined, autoTopUpWhenBelow: number, paymentMethodId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } };

export type SetSmsPaymentMethodMutationVariables = Exact<{
  companyPaymentMethodId: Scalars['ID'];
  clubId: Scalars['ID'];
}>;


export type SetSmsPaymentMethodMutation = { __typename?: 'Mutation', setSmsPaymentMethod: { __typename?: 'SmsAccountResponse', smsAccount?: { __typename?: 'SmsAccount', balance: number, clubId: string, outboundCost: number, inboundCost: number, smsEnabled: boolean, autoTopUpEnabled: boolean, autoTopUpAmount: number, smsNumberId?: string | null | undefined, autoTopUpWhenBelow: number, paymentMethodId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } };

export type SendSmsMutationVariables = Exact<{
  memberId: Scalars['ID'];
  input: SendSmsInput;
}>;


export type SendSmsMutation = { __typename?: 'Mutation', sendSms: { __typename?: 'MemberSmsResponse', memberSms?: { __typename?: 'MemberSms', id: string, body: string, direction: MessageDirection, to: string, sentAt?: string | null | undefined, deliveredAt?: string | null | undefined, openedAt?: string | null | undefined, clickedAt?: string | null | undefined, messageTemplateId?: MessageTemplateId | null | undefined, templateEventId?: string | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'Error', type?: string | null | undefined, message?: string | null | undefined } | null | undefined> | null | undefined } };

export type GetMyTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyTeamQuery = { __typename?: 'Query', getMyTeam?: { __typename?: 'StaffConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'Staff', id: string, displayName: string, profilePictureUrl?: string | null | undefined, role?: StaffRole | null | undefined, email: string, givenName: string, familyName?: string | null | undefined, gender?: Gender | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type UpdateMeStaffMutationVariables = Exact<{
  input: UpdateStaffInput;
}>;


export type UpdateMeStaffMutation = { __typename?: 'Mutation', updateMeStaff?: { __typename?: 'Staff', givenName: string, familyName?: string | null | undefined, email: string, mobileNumber?: string | null | undefined, profilePictureUrl?: string | null | undefined, gender?: Gender | null | undefined } | null | undefined };

export type GetStaffInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStaffInvitesQuery = { __typename?: 'Query', getStaffInvites?: { __typename?: 'StaffInviteConnection', nextToken?: string | null | undefined, items?: Array<{ __typename?: 'StaffInvite', id: string, email: string, givenName: string, role: string, createdByName: string, expiresAt: string, disabledAt?: string | null | undefined, acceptedAt?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type DisableStaffInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisableStaffInviteMutation = { __typename?: 'Mutation', disableStaffInvite?: { __typename?: 'StaffInvite', id: string, email: string, givenName: string, createdByName: string, expiresAt: string, disabledAt?: string | null | undefined, acceptedAt?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined } | null | undefined };

export type AddStaffInviteMutationVariables = Exact<{
  input: AddStaffInviteInput;
}>;


export type AddStaffInviteMutation = { __typename?: 'Mutation', addStaffInvite?: { __typename?: 'StaffInvite', id: string, email: string, givenName: string, createdByName: string, expiresAt: string, disabledAt?: string | null | undefined, acceptedAt?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined } | null | undefined };

export type RedeemStaffInviteMutationVariables = Exact<{
  id: Scalars['ID'];
  input: RedeemStaffInviteInput;
}>;


export type RedeemStaffInviteMutation = { __typename?: 'Mutation', redeemStaffInvite?: { __typename?: 'StaffInvite', id: string, email: string, givenName: string, createdByName: string, expiresAt: string, disabledAt?: string | null | undefined, acceptedAt?: string | null | undefined, clubIds?: Array<string | null | undefined> | null | undefined } | null | undefined };


export const SearchForAddressDocument = gql`
    query searchForAddress($search: String!) {
  searchForAddress(search: $search) {
    items {
      fullText
      address
      streetOne
      streetTwo
      city
      zipPostcode
      state
      country
      center
    }
  }
}
    `;

/**
 * __useSearchForAddressQuery__
 *
 * To run a query within a React component, call `useSearchForAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForAddressQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchForAddressQuery(baseOptions: Apollo.QueryHookOptions<SearchForAddressQuery, SearchForAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchForAddressQuery, SearchForAddressQueryVariables>(SearchForAddressDocument, options);
      }
export function useSearchForAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchForAddressQuery, SearchForAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchForAddressQuery, SearchForAddressQueryVariables>(SearchForAddressDocument, options);
        }
export type SearchForAddressQueryHookResult = ReturnType<typeof useSearchForAddressQuery>;
export type SearchForAddressLazyQueryHookResult = ReturnType<typeof useSearchForAddressLazyQuery>;
export type SearchForAddressQueryResult = Apollo.QueryResult<SearchForAddressQuery, SearchForAddressQueryVariables>;
export const GetClassesForClubDocument = gql`
    query getClassesForClub($clubId: String!, $limit: Int, $startsAt: String!, $classTypeId: String, $endsAt: String) {
  getClassesForClub(
    clubId: $clubId
    limit: $limit
    startsAt: $startsAt
    classTypeId: $classTypeId
    endsAt: $endsAt
  ) {
    items {
      id
      name
      description
      thumbnailImageUrl
      companyId
      clubId
      staffId
      staffName
      startsAt
      length
      maximumCapacity
      currentCapacity
      useWaitlist
      useCheckIn
      bookedMemberIds
      waitlistMemberIds
      checkedInMemberIds
      cancelledAt
      cancellationReason
      waitlistConfirmationWindow
      calendarColor
      classScheduleRuleId
    }
    nextToken
  }
}
    `;

/**
 * __useGetClassesForClubQuery__
 *
 * To run a query within a React component, call `useGetClassesForClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesForClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesForClubQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      limit: // value for 'limit'
 *      startsAt: // value for 'startsAt'
 *      classTypeId: // value for 'classTypeId'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useGetClassesForClubQuery(baseOptions: Apollo.QueryHookOptions<GetClassesForClubQuery, GetClassesForClubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesForClubQuery, GetClassesForClubQueryVariables>(GetClassesForClubDocument, options);
      }
export function useGetClassesForClubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesForClubQuery, GetClassesForClubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesForClubQuery, GetClassesForClubQueryVariables>(GetClassesForClubDocument, options);
        }
export type GetClassesForClubQueryHookResult = ReturnType<typeof useGetClassesForClubQuery>;
export type GetClassesForClubLazyQueryHookResult = ReturnType<typeof useGetClassesForClubLazyQuery>;
export type GetClassesForClubQueryResult = Apollo.QueryResult<GetClassesForClubQuery, GetClassesForClubQueryVariables>;
export const AddClassDocument = gql`
    mutation addClass($classTypeId: String!, $input: AddClassInput!) {
  addClass(classTypeId: $classTypeId, input: $input) {
    class {
      id
      name
      description
      thumbnailImageUrl
      companyId
      clubId
      staffId
      staffName
      startsAt
      length
      maximumCapacity
      currentCapacity
      useWaitlist
      useCheckIn
      bookedMemberIds
      waitlistMemberIds
      checkedInMemberIds
      cancelledAt
      cancellationReason
      waitlistConfirmationWindow
      calendarColor
      classScheduleRuleId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddClassMutationFn = Apollo.MutationFunction<AddClassMutation, AddClassMutationVariables>;

/**
 * __useAddClassMutation__
 *
 * To run a mutation, you first call `useAddClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClassMutation, { data, loading, error }] = useAddClassMutation({
 *   variables: {
 *      classTypeId: // value for 'classTypeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClassMutation(baseOptions?: Apollo.MutationHookOptions<AddClassMutation, AddClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClassMutation, AddClassMutationVariables>(AddClassDocument, options);
      }
export type AddClassMutationHookResult = ReturnType<typeof useAddClassMutation>;
export type AddClassMutationResult = Apollo.MutationResult<AddClassMutation>;
export type AddClassMutationOptions = Apollo.BaseMutationOptions<AddClassMutation, AddClassMutationVariables>;
export const UpdateClassDocument = gql`
    mutation updateClass($id: ID!, $input: UpdateClassInput!) {
  updateClass(id: $id, input: $input) {
    class {
      id
      name
      description
      thumbnailImageUrl
      companyId
      clubId
      staffId
      staffName
      startsAt
      length
      maximumCapacity
      currentCapacity
      useWaitlist
      useCheckIn
      bookedMemberIds
      waitlistMemberIds
      checkedInMemberIds
      cancelledAt
      cancellationReason
      waitlistConfirmationWindow
      calendarColor
      classScheduleRuleId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type UpdateClassMutationFn = Apollo.MutationFunction<UpdateClassMutation, UpdateClassMutationVariables>;

/**
 * __useUpdateClassMutation__
 *
 * To run a mutation, you first call `useUpdateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassMutation, { data, loading, error }] = useUpdateClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassMutation, UpdateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassMutation, UpdateClassMutationVariables>(UpdateClassDocument, options);
      }
export type UpdateClassMutationHookResult = ReturnType<typeof useUpdateClassMutation>;
export type UpdateClassMutationResult = Apollo.MutationResult<UpdateClassMutation>;
export type UpdateClassMutationOptions = Apollo.BaseMutationOptions<UpdateClassMutation, UpdateClassMutationVariables>;
export const DeleteClassDocument = gql`
    mutation deleteClass($id: ID!, $clubId: ID!) {
  deleteClass(id: $id, clubId: $clubId) {
    class {
      id
      name
      description
      thumbnailImageUrl
      companyId
      clubId
      staffId
      staffName
      startsAt
      length
      maximumCapacity
      currentCapacity
      useWaitlist
      useCheckIn
      bookedMemberIds
      waitlistMemberIds
      checkedInMemberIds
      cancelledAt
      cancellationReason
      waitlistConfirmationWindow
      calendarColor
      classScheduleRuleId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type DeleteClassMutationFn = Apollo.MutationFunction<DeleteClassMutation, DeleteClassMutationVariables>;

/**
 * __useDeleteClassMutation__
 *
 * To run a mutation, you first call `useDeleteClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassMutation, { data, loading, error }] = useDeleteClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useDeleteClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassMutation, DeleteClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassMutation, DeleteClassMutationVariables>(DeleteClassDocument, options);
      }
export type DeleteClassMutationHookResult = ReturnType<typeof useDeleteClassMutation>;
export type DeleteClassMutationResult = Apollo.MutationResult<DeleteClassMutation>;
export type DeleteClassMutationOptions = Apollo.BaseMutationOptions<DeleteClassMutation, DeleteClassMutationVariables>;
export const CancelClassDocument = gql`
    mutation cancelClass($id: ID!, $clubId: String!, $cancellationReason: String) {
  cancelClass(id: $id, clubId: $clubId, cancellationReason: $cancellationReason) {
    class {
      id
      name
      description
      thumbnailImageUrl
      companyId
      clubId
      staffId
      staffName
      startsAt
      length
      maximumCapacity
      currentCapacity
      useWaitlist
      useCheckIn
      bookedMemberIds
      waitlistMemberIds
      checkedInMemberIds
      cancelledAt
      cancellationReason
      waitlistConfirmationWindow
      calendarColor
      classScheduleRuleId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type CancelClassMutationFn = Apollo.MutationFunction<CancelClassMutation, CancelClassMutationVariables>;

/**
 * __useCancelClassMutation__
 *
 * To run a mutation, you first call `useCancelClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClassMutation, { data, loading, error }] = useCancelClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clubId: // value for 'clubId'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useCancelClassMutation(baseOptions?: Apollo.MutationHookOptions<CancelClassMutation, CancelClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelClassMutation, CancelClassMutationVariables>(CancelClassDocument, options);
      }
export type CancelClassMutationHookResult = ReturnType<typeof useCancelClassMutation>;
export type CancelClassMutationResult = Apollo.MutationResult<CancelClassMutation>;
export type CancelClassMutationOptions = Apollo.BaseMutationOptions<CancelClassMutation, CancelClassMutationVariables>;
export const GetClassScheduleRulesDocument = gql`
    query getClassScheduleRules($classTypeId: String, $clubId: String!) {
  getClassScheduleRules(classTypeId: $classTypeId, clubId: $clubId) {
    classTypeId
    defaultStaffMemberId
    id
    length
    name
    times
    weekDays
    calendarColor
    endsAt
  }
}
    `;

/**
 * __useGetClassScheduleRulesQuery__
 *
 * To run a query within a React component, call `useGetClassScheduleRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassScheduleRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassScheduleRulesQuery({
 *   variables: {
 *      classTypeId: // value for 'classTypeId'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetClassScheduleRulesQuery(baseOptions: Apollo.QueryHookOptions<GetClassScheduleRulesQuery, GetClassScheduleRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassScheduleRulesQuery, GetClassScheduleRulesQueryVariables>(GetClassScheduleRulesDocument, options);
      }
export function useGetClassScheduleRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassScheduleRulesQuery, GetClassScheduleRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassScheduleRulesQuery, GetClassScheduleRulesQueryVariables>(GetClassScheduleRulesDocument, options);
        }
export type GetClassScheduleRulesQueryHookResult = ReturnType<typeof useGetClassScheduleRulesQuery>;
export type GetClassScheduleRulesLazyQueryHookResult = ReturnType<typeof useGetClassScheduleRulesLazyQuery>;
export type GetClassScheduleRulesQueryResult = Apollo.QueryResult<GetClassScheduleRulesQuery, GetClassScheduleRulesQueryVariables>;
export const AddClassScheduleRuleDocument = gql`
    mutation addClassScheduleRule($clubId: String!, $classTypeId: String!, $input: AddClassScheduleRuleInput!) {
  addClassScheduleRule(clubId: $clubId, classTypeId: $classTypeId, input: $input) {
    classSchedule {
      classTypeId
      defaultStaffMemberId
      id
      length
      name
      times
      weekDays
      calendarColor
      endsAt
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddClassScheduleRuleMutationFn = Apollo.MutationFunction<AddClassScheduleRuleMutation, AddClassScheduleRuleMutationVariables>;

/**
 * __useAddClassScheduleRuleMutation__
 *
 * To run a mutation, you first call `useAddClassScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClassScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClassScheduleRuleMutation, { data, loading, error }] = useAddClassScheduleRuleMutation({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      classTypeId: // value for 'classTypeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClassScheduleRuleMutation(baseOptions?: Apollo.MutationHookOptions<AddClassScheduleRuleMutation, AddClassScheduleRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClassScheduleRuleMutation, AddClassScheduleRuleMutationVariables>(AddClassScheduleRuleDocument, options);
      }
export type AddClassScheduleRuleMutationHookResult = ReturnType<typeof useAddClassScheduleRuleMutation>;
export type AddClassScheduleRuleMutationResult = Apollo.MutationResult<AddClassScheduleRuleMutation>;
export type AddClassScheduleRuleMutationOptions = Apollo.BaseMutationOptions<AddClassScheduleRuleMutation, AddClassScheduleRuleMutationVariables>;
export const UpdateClassScheduleRuleDocument = gql`
    mutation updateClassScheduleRule($clubId: String!, $id: ID!, $input: UpdateClassScheduleRuleInput!) {
  updateClassScheduleRule(clubId: $clubId, id: $id, input: $input) {
    classSchedule {
      classTypeId
      defaultStaffMemberId
      id
      length
      name
      times
      weekDays
      calendarColor
      endsAt
    }
    errors {
      type
      message
    }
  }
}
    `;
export type UpdateClassScheduleRuleMutationFn = Apollo.MutationFunction<UpdateClassScheduleRuleMutation, UpdateClassScheduleRuleMutationVariables>;

/**
 * __useUpdateClassScheduleRuleMutation__
 *
 * To run a mutation, you first call `useUpdateClassScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassScheduleRuleMutation, { data, loading, error }] = useUpdateClassScheduleRuleMutation({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClassScheduleRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassScheduleRuleMutation, UpdateClassScheduleRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassScheduleRuleMutation, UpdateClassScheduleRuleMutationVariables>(UpdateClassScheduleRuleDocument, options);
      }
export type UpdateClassScheduleRuleMutationHookResult = ReturnType<typeof useUpdateClassScheduleRuleMutation>;
export type UpdateClassScheduleRuleMutationResult = Apollo.MutationResult<UpdateClassScheduleRuleMutation>;
export type UpdateClassScheduleRuleMutationOptions = Apollo.BaseMutationOptions<UpdateClassScheduleRuleMutation, UpdateClassScheduleRuleMutationVariables>;
export const DeleteClassScheduleRuleDocument = gql`
    mutation deleteClassScheduleRule($clubId: String!, $id: ID!) {
  deleteClassScheduleRule(clubId: $clubId, id: $id) {
    classSchedule {
      classTypeId
      defaultStaffMemberId
      id
      length
      name
      times
      weekDays
      calendarColor
      endsAt
    }
    errors {
      type
      message
    }
  }
}
    `;
export type DeleteClassScheduleRuleMutationFn = Apollo.MutationFunction<DeleteClassScheduleRuleMutation, DeleteClassScheduleRuleMutationVariables>;

/**
 * __useDeleteClassScheduleRuleMutation__
 *
 * To run a mutation, you first call `useDeleteClassScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassScheduleRuleMutation, { data, loading, error }] = useDeleteClassScheduleRuleMutation({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassScheduleRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassScheduleRuleMutation, DeleteClassScheduleRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassScheduleRuleMutation, DeleteClassScheduleRuleMutationVariables>(DeleteClassScheduleRuleDocument, options);
      }
export type DeleteClassScheduleRuleMutationHookResult = ReturnType<typeof useDeleteClassScheduleRuleMutation>;
export type DeleteClassScheduleRuleMutationResult = Apollo.MutationResult<DeleteClassScheduleRuleMutation>;
export type DeleteClassScheduleRuleMutationOptions = Apollo.BaseMutationOptions<DeleteClassScheduleRuleMutation, DeleteClassScheduleRuleMutationVariables>;
export const GetClassTypesDocument = gql`
    query getClassTypes($limit: Int, $nextToken: String, $clubId: String) {
  getClassTypes(limit: $limit, nextToken: $nextToken, clubId: $clubId) {
    items {
      id
      name
      description
      thumbnailImageUrl
      clubIds
      defaultMaximumCapacity
      defaultUseWaitlist
      defaultUseCheckIn
      defaultLength
      defaultWaitlistConfirmationWindow
      defaultCalendarColor
      created
    }
    nextToken
  }
}
    `;

/**
 * __useGetClassTypesQuery__
 *
 * To run a query within a React component, call `useGetClassTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetClassTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetClassTypesQuery, GetClassTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassTypesQuery, GetClassTypesQueryVariables>(GetClassTypesDocument, options);
      }
export function useGetClassTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassTypesQuery, GetClassTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassTypesQuery, GetClassTypesQueryVariables>(GetClassTypesDocument, options);
        }
export type GetClassTypesQueryHookResult = ReturnType<typeof useGetClassTypesQuery>;
export type GetClassTypesLazyQueryHookResult = ReturnType<typeof useGetClassTypesLazyQuery>;
export type GetClassTypesQueryResult = Apollo.QueryResult<GetClassTypesQuery, GetClassTypesQueryVariables>;
export const GetClassTypeDocument = gql`
    query getClassType($id: ID!) {
  getClassType(id: $id) {
    id
    name
    description
    thumbnailImageUrl
    clubIds
    defaultMaximumCapacity
    defaultUseWaitlist
    defaultUseCheckIn
    defaultLength
    defaultWaitlistConfirmationWindow
    defaultCalendarColor
    created
  }
}
    `;

/**
 * __useGetClassTypeQuery__
 *
 * To run a query within a React component, call `useGetClassTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassTypeQuery(baseOptions: Apollo.QueryHookOptions<GetClassTypeQuery, GetClassTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassTypeQuery, GetClassTypeQueryVariables>(GetClassTypeDocument, options);
      }
export function useGetClassTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassTypeQuery, GetClassTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassTypeQuery, GetClassTypeQueryVariables>(GetClassTypeDocument, options);
        }
export type GetClassTypeQueryHookResult = ReturnType<typeof useGetClassTypeQuery>;
export type GetClassTypeLazyQueryHookResult = ReturnType<typeof useGetClassTypeLazyQuery>;
export type GetClassTypeQueryResult = Apollo.QueryResult<GetClassTypeQuery, GetClassTypeQueryVariables>;
export const AddClassTypeDocument = gql`
    mutation addClassType($input: AddClassTypeInput!) {
  addClassType(input: $input) {
    classType {
      id
      name
      description
      thumbnailImageUrl
      clubIds
      defaultMaximumCapacity
      defaultUseWaitlist
      defaultUseCheckIn
      defaultLength
      defaultWaitlistConfirmationWindow
      defaultCalendarColor
      created
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddClassTypeMutationFn = Apollo.MutationFunction<AddClassTypeMutation, AddClassTypeMutationVariables>;

/**
 * __useAddClassTypeMutation__
 *
 * To run a mutation, you first call `useAddClassTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClassTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClassTypeMutation, { data, loading, error }] = useAddClassTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClassTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddClassTypeMutation, AddClassTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClassTypeMutation, AddClassTypeMutationVariables>(AddClassTypeDocument, options);
      }
export type AddClassTypeMutationHookResult = ReturnType<typeof useAddClassTypeMutation>;
export type AddClassTypeMutationResult = Apollo.MutationResult<AddClassTypeMutation>;
export type AddClassTypeMutationOptions = Apollo.BaseMutationOptions<AddClassTypeMutation, AddClassTypeMutationVariables>;
export const UpdateClassTypeDocument = gql`
    mutation updateClassType($id: ID!, $input: UpdateClassTypeInput!) {
  updateClassType(id: $id, input: $input) {
    classType {
      id
      name
      description
      thumbnailImageUrl
      clubIds
      defaultMaximumCapacity
      defaultUseWaitlist
      defaultUseCheckIn
      defaultLength
      defaultWaitlistConfirmationWindow
      defaultCalendarColor
      created
    }
    errors {
      type
      message
    }
  }
}
    `;
export type UpdateClassTypeMutationFn = Apollo.MutationFunction<UpdateClassTypeMutation, UpdateClassTypeMutationVariables>;

/**
 * __useUpdateClassTypeMutation__
 *
 * To run a mutation, you first call `useUpdateClassTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassTypeMutation, { data, loading, error }] = useUpdateClassTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClassTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassTypeMutation, UpdateClassTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassTypeMutation, UpdateClassTypeMutationVariables>(UpdateClassTypeDocument, options);
      }
export type UpdateClassTypeMutationHookResult = ReturnType<typeof useUpdateClassTypeMutation>;
export type UpdateClassTypeMutationResult = Apollo.MutationResult<UpdateClassTypeMutation>;
export type UpdateClassTypeMutationOptions = Apollo.BaseMutationOptions<UpdateClassTypeMutation, UpdateClassTypeMutationVariables>;
export const DeleteClassTypeDocument = gql`
    mutation deleteClassType($id: ID!) {
  deleteClassType(id: $id) {
    classType {
      id
      name
      description
      thumbnailImageUrl
      clubIds
      defaultMaximumCapacity
      defaultUseWaitlist
      defaultUseCheckIn
      defaultLength
      defaultWaitlistConfirmationWindow
      defaultCalendarColor
      created
    }
    errors {
      type
      message
    }
  }
}
    `;
export type DeleteClassTypeMutationFn = Apollo.MutationFunction<DeleteClassTypeMutation, DeleteClassTypeMutationVariables>;

/**
 * __useDeleteClassTypeMutation__
 *
 * To run a mutation, you first call `useDeleteClassTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassTypeMutation, { data, loading, error }] = useDeleteClassTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassTypeMutation, DeleteClassTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassTypeMutation, DeleteClassTypeMutationVariables>(DeleteClassTypeDocument, options);
      }
export type DeleteClassTypeMutationHookResult = ReturnType<typeof useDeleteClassTypeMutation>;
export type DeleteClassTypeMutationResult = Apollo.MutationResult<DeleteClassTypeMutation>;
export type DeleteClassTypeMutationOptions = Apollo.BaseMutationOptions<DeleteClassTypeMutation, DeleteClassTypeMutationVariables>;
export const GetMyClubsDocument = gql`
    query getMyClubs {
  getMyClubs {
    items {
      id
      name
      clubId
    }
    nextToken
  }
}
    `;

/**
 * __useGetMyClubsQuery__
 *
 * To run a query within a React component, call `useGetMyClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyClubsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyClubsQuery, GetMyClubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyClubsQuery, GetMyClubsQueryVariables>(GetMyClubsDocument, options);
      }
export function useGetMyClubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyClubsQuery, GetMyClubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyClubsQuery, GetMyClubsQueryVariables>(GetMyClubsDocument, options);
        }
export type GetMyClubsQueryHookResult = ReturnType<typeof useGetMyClubsQuery>;
export type GetMyClubsLazyQueryHookResult = ReturnType<typeof useGetMyClubsLazyQuery>;
export type GetMyClubsQueryResult = Apollo.QueryResult<GetMyClubsQuery, GetMyClubsQueryVariables>;
export const GetClubDocument = gql`
    query getClub($id: ID!) {
  getClub(id: $id) {
    id
    name
    description
    countryCode
    address
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;

/**
 * __useGetClubQuery__
 *
 * To run a query within a React component, call `useGetClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClubQuery(baseOptions: Apollo.QueryHookOptions<GetClubQuery, GetClubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClubQuery, GetClubQueryVariables>(GetClubDocument, options);
      }
export function useGetClubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClubQuery, GetClubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClubQuery, GetClubQueryVariables>(GetClubDocument, options);
        }
export type GetClubQueryHookResult = ReturnType<typeof useGetClubQuery>;
export type GetClubLazyQueryHookResult = ReturnType<typeof useGetClubLazyQuery>;
export type GetClubQueryResult = Apollo.QueryResult<GetClubQuery, GetClubQueryVariables>;
export const GetClubWebPortalDocument = gql`
    query getClubWebPortal($id: ID!) {
  getClub(id: $id) {
    id
    name
    description
    address
    clubWebPortal {
      clubId
      enabled
      showPublicClasses
      welcomeText
      logInText
      colorScheme
    }
  }
}
    `;

/**
 * __useGetClubWebPortalQuery__
 *
 * To run a query within a React component, call `useGetClubWebPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubWebPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubWebPortalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClubWebPortalQuery(baseOptions: Apollo.QueryHookOptions<GetClubWebPortalQuery, GetClubWebPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClubWebPortalQuery, GetClubWebPortalQueryVariables>(GetClubWebPortalDocument, options);
      }
export function useGetClubWebPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClubWebPortalQuery, GetClubWebPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClubWebPortalQuery, GetClubWebPortalQueryVariables>(GetClubWebPortalDocument, options);
        }
export type GetClubWebPortalQueryHookResult = ReturnType<typeof useGetClubWebPortalQuery>;
export type GetClubWebPortalLazyQueryHookResult = ReturnType<typeof useGetClubWebPortalLazyQuery>;
export type GetClubWebPortalQueryResult = Apollo.QueryResult<GetClubWebPortalQuery, GetClubWebPortalQueryVariables>;
export const UpdateClubDocument = gql`
    mutation updateClub($id: ID!, $input: UpdateClubInput!) {
  updateClub(id: $id, input: $input) {
    club {
      id
      name
      description
      baseCurrency {
        code
        base
        exponent
      }
    }
    errors {
      type
      message
    }
  }
}
    `;
export type UpdateClubMutationFn = Apollo.MutationFunction<UpdateClubMutation, UpdateClubMutationVariables>;

/**
 * __useUpdateClubMutation__
 *
 * To run a mutation, you first call `useUpdateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubMutation, { data, loading, error }] = useUpdateClubMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClubMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClubMutation, UpdateClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClubMutation, UpdateClubMutationVariables>(UpdateClubDocument, options);
      }
export type UpdateClubMutationHookResult = ReturnType<typeof useUpdateClubMutation>;
export type UpdateClubMutationResult = Apollo.MutationResult<UpdateClubMutation>;
export type UpdateClubMutationOptions = Apollo.BaseMutationOptions<UpdateClubMutation, UpdateClubMutationVariables>;
export const UpdateClubWebPortalDocument = gql`
    mutation updateClubWebPortal($clubId: ID!, $input: UpdateClubWebPortalInput!) {
  updateClubWebPortal(clubId: $clubId, input: $input) {
    clubId
    enabled
    showPublicClasses
    welcomeText
    logInText
    colorScheme
  }
}
    `;
export type UpdateClubWebPortalMutationFn = Apollo.MutationFunction<UpdateClubWebPortalMutation, UpdateClubWebPortalMutationVariables>;

/**
 * __useUpdateClubWebPortalMutation__
 *
 * To run a mutation, you first call `useUpdateClubWebPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubWebPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubWebPortalMutation, { data, loading, error }] = useUpdateClubWebPortalMutation({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClubWebPortalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClubWebPortalMutation, UpdateClubWebPortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClubWebPortalMutation, UpdateClubWebPortalMutationVariables>(UpdateClubWebPortalDocument, options);
      }
export type UpdateClubWebPortalMutationHookResult = ReturnType<typeof useUpdateClubWebPortalMutation>;
export type UpdateClubWebPortalMutationResult = Apollo.MutationResult<UpdateClubWebPortalMutation>;
export type UpdateClubWebPortalMutationOptions = Apollo.BaseMutationOptions<UpdateClubWebPortalMutation, UpdateClubWebPortalMutationVariables>;
export const AddClubDocument = gql`
    mutation addClub($input: AddClubInput!) {
  addClub(input: $input) {
    club {
      id
      name
      description
      baseCurrency {
        code
        base
        exponent
      }
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddClubMutationFn = Apollo.MutationFunction<AddClubMutation, AddClubMutationVariables>;

/**
 * __useAddClubMutation__
 *
 * To run a mutation, you first call `useAddClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClubMutation, { data, loading, error }] = useAddClubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClubMutation(baseOptions?: Apollo.MutationHookOptions<AddClubMutation, AddClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClubMutation, AddClubMutationVariables>(AddClubDocument, options);
      }
export type AddClubMutationHookResult = ReturnType<typeof useAddClubMutation>;
export type AddClubMutationResult = Apollo.MutationResult<AddClubMutation>;
export type AddClubMutationOptions = Apollo.BaseMutationOptions<AddClubMutation, AddClubMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    company {
      id
      brandName
      name
      description
      headOfficeAddress
    }
    errors {
      type
      message
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const AddCompanyDocument = gql`
    mutation addCompany($input: AddCompanyInput!) {
  addCompany(input: $input) {
    company {
      id
      brandName
      name
      description
      headOfficeAddress
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddCompanyMutationFn = Apollo.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyMutation, AddCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(AddCompanyDocument, options);
      }
export type AddCompanyMutationHookResult = ReturnType<typeof useAddCompanyMutation>;
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;
export const GetCompanyPaymentMethodsDocument = gql`
    query getCompanyPaymentMethods {
  getCompanyPaymentMethods {
    id
    isDefault
    companyId
    type
    maskedNumber
    expiryMonth
    expiryYear
    accountName
  }
}
    `;

/**
 * __useGetCompanyPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>(GetCompanyPaymentMethodsDocument, options);
      }
export function useGetCompanyPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>(GetCompanyPaymentMethodsDocument, options);
        }
export type GetCompanyPaymentMethodsQueryHookResult = ReturnType<typeof useGetCompanyPaymentMethodsQuery>;
export type GetCompanyPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetCompanyPaymentMethodsLazyQuery>;
export type GetCompanyPaymentMethodsQueryResult = Apollo.QueryResult<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>;
export const GetCompanyPaymentMethodDocument = gql`
    query getCompanyPaymentMethod($id: ID!) {
  getCompanyPaymentMethod(id: $id) {
    id
    isDefault
    companyId
    type
    maskedNumber
    expiryMonth
    expiryYear
    accountName
  }
}
    `;

/**
 * __useGetCompanyPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetCompanyPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>(GetCompanyPaymentMethodDocument, options);
      }
export function useGetCompanyPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>(GetCompanyPaymentMethodDocument, options);
        }
export type GetCompanyPaymentMethodQueryHookResult = ReturnType<typeof useGetCompanyPaymentMethodQuery>;
export type GetCompanyPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetCompanyPaymentMethodLazyQuery>;
export type GetCompanyPaymentMethodQueryResult = Apollo.QueryResult<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>;
export const AddCompanyPaymentMethodDocument = gql`
    mutation addCompanyPaymentMethod($input: AddCompanyPaymentMethodInput!) {
  addCompanyPaymentMethod(input: $input) {
    companyPaymentMethod {
      id
      isDefault
      companyId
      type
      maskedNumber
      expiryMonth
      expiryYear
      accountName
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddCompanyPaymentMethodMutationFn = Apollo.MutationFunction<AddCompanyPaymentMethodMutation, AddCompanyPaymentMethodMutationVariables>;

/**
 * __useAddCompanyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddCompanyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyPaymentMethodMutation, { data, loading, error }] = useAddCompanyPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyPaymentMethodMutation, AddCompanyPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyPaymentMethodMutation, AddCompanyPaymentMethodMutationVariables>(AddCompanyPaymentMethodDocument, options);
      }
export type AddCompanyPaymentMethodMutationHookResult = ReturnType<typeof useAddCompanyPaymentMethodMutation>;
export type AddCompanyPaymentMethodMutationResult = Apollo.MutationResult<AddCompanyPaymentMethodMutation>;
export type AddCompanyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddCompanyPaymentMethodMutation, AddCompanyPaymentMethodMutationVariables>;
export const RemoveCompanyPaymentMethodDocument = gql`
    mutation removeCompanyPaymentMethod($id: ID!) {
  removeCompanyPaymentMethod(id: $id) {
    companyPaymentMethod {
      id
      isDefault
      companyId
      type
      maskedNumber
      expiryMonth
      expiryYear
      accountName
    }
    errors {
      type
      message
    }
  }
}
    `;
export type RemoveCompanyPaymentMethodMutationFn = Apollo.MutationFunction<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>;

/**
 * __useRemoveCompanyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyPaymentMethodMutation, { data, loading, error }] = useRemoveCompanyPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>(RemoveCompanyPaymentMethodDocument, options);
      }
export type RemoveCompanyPaymentMethodMutationHookResult = ReturnType<typeof useRemoveCompanyPaymentMethodMutation>;
export type RemoveCompanyPaymentMethodMutationResult = Apollo.MutationResult<RemoveCompanyPaymentMethodMutation>;
export type RemoveCompanyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyPaymentMethodMutation, RemoveCompanyPaymentMethodMutationVariables>;
export const SetCompanyPaymentMethodAsDefaultDocument = gql`
    mutation setCompanyPaymentMethodAsDefault($id: ID!) {
  setCompanyPaymentMethodAsDefault(id: $id) {
    companyPaymentMethod {
      id
      isDefault
      companyId
      type
      maskedNumber
      expiryMonth
      expiryYear
      accountName
    }
    errors {
      type
      message
    }
  }
}
    `;
export type SetCompanyPaymentMethodAsDefaultMutationFn = Apollo.MutationFunction<SetCompanyPaymentMethodAsDefaultMutation, SetCompanyPaymentMethodAsDefaultMutationVariables>;

/**
 * __useSetCompanyPaymentMethodAsDefaultMutation__
 *
 * To run a mutation, you first call `useSetCompanyPaymentMethodAsDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyPaymentMethodAsDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanyPaymentMethodAsDefaultMutation, { data, loading, error }] = useSetCompanyPaymentMethodAsDefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetCompanyPaymentMethodAsDefaultMutation(baseOptions?: Apollo.MutationHookOptions<SetCompanyPaymentMethodAsDefaultMutation, SetCompanyPaymentMethodAsDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCompanyPaymentMethodAsDefaultMutation, SetCompanyPaymentMethodAsDefaultMutationVariables>(SetCompanyPaymentMethodAsDefaultDocument, options);
      }
export type SetCompanyPaymentMethodAsDefaultMutationHookResult = ReturnType<typeof useSetCompanyPaymentMethodAsDefaultMutation>;
export type SetCompanyPaymentMethodAsDefaultMutationResult = Apollo.MutationResult<SetCompanyPaymentMethodAsDefaultMutation>;
export type SetCompanyPaymentMethodAsDefaultMutationOptions = Apollo.BaseMutationOptions<SetCompanyPaymentMethodAsDefaultMutation, SetCompanyPaymentMethodAsDefaultMutationVariables>;
export const GetDirectDebitProvidersDocument = gql`
    query getDirectDebitProviders {
  getDirectDebitProviders {
    id
    name
    description
    url
    logoUrl
    termsAndConditionsUrl
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;

/**
 * __useGetDirectDebitProvidersQuery__
 *
 * To run a query within a React component, call `useGetDirectDebitProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectDebitProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectDebitProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDirectDebitProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetDirectDebitProvidersQuery, GetDirectDebitProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectDebitProvidersQuery, GetDirectDebitProvidersQueryVariables>(GetDirectDebitProvidersDocument, options);
      }
export function useGetDirectDebitProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectDebitProvidersQuery, GetDirectDebitProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectDebitProvidersQuery, GetDirectDebitProvidersQueryVariables>(GetDirectDebitProvidersDocument, options);
        }
export type GetDirectDebitProvidersQueryHookResult = ReturnType<typeof useGetDirectDebitProvidersQuery>;
export type GetDirectDebitProvidersLazyQueryHookResult = ReturnType<typeof useGetDirectDebitProvidersLazyQuery>;
export type GetDirectDebitProvidersQueryResult = Apollo.QueryResult<GetDirectDebitProvidersQuery, GetDirectDebitProvidersQueryVariables>;
export const GetDirectDebitProviderDocument = gql`
    query getDirectDebitProvider($id: ID!) {
  getDirectDebitProvider(id: $id) {
    id
    name
    description
    url
    logoUrl
    termsAndConditionsUrl
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;

/**
 * __useGetDirectDebitProviderQuery__
 *
 * To run a query within a React component, call `useGetDirectDebitProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectDebitProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectDebitProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDirectDebitProviderQuery(baseOptions: Apollo.QueryHookOptions<GetDirectDebitProviderQuery, GetDirectDebitProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectDebitProviderQuery, GetDirectDebitProviderQueryVariables>(GetDirectDebitProviderDocument, options);
      }
export function useGetDirectDebitProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectDebitProviderQuery, GetDirectDebitProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectDebitProviderQuery, GetDirectDebitProviderQueryVariables>(GetDirectDebitProviderDocument, options);
        }
export type GetDirectDebitProviderQueryHookResult = ReturnType<typeof useGetDirectDebitProviderQuery>;
export type GetDirectDebitProviderLazyQueryHookResult = ReturnType<typeof useGetDirectDebitProviderLazyQuery>;
export type GetDirectDebitProviderQueryResult = Apollo.QueryResult<GetDirectDebitProviderQuery, GetDirectDebitProviderQueryVariables>;
export const TestDirectDebitCredentialsDocument = gql`
    query testDirectDebitCredentials($providerId: ID!, $username: String!, $password: String!) {
  testDirectDebitCredentials(
    providerId: $providerId
    username: $username
    password: $password
  )
}
    `;

/**
 * __useTestDirectDebitCredentialsQuery__
 *
 * To run a query within a React component, call `useTestDirectDebitCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestDirectDebitCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestDirectDebitCredentialsQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTestDirectDebitCredentialsQuery(baseOptions: Apollo.QueryHookOptions<TestDirectDebitCredentialsQuery, TestDirectDebitCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestDirectDebitCredentialsQuery, TestDirectDebitCredentialsQueryVariables>(TestDirectDebitCredentialsDocument, options);
      }
export function useTestDirectDebitCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestDirectDebitCredentialsQuery, TestDirectDebitCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestDirectDebitCredentialsQuery, TestDirectDebitCredentialsQueryVariables>(TestDirectDebitCredentialsDocument, options);
        }
export type TestDirectDebitCredentialsQueryHookResult = ReturnType<typeof useTestDirectDebitCredentialsQuery>;
export type TestDirectDebitCredentialsLazyQueryHookResult = ReturnType<typeof useTestDirectDebitCredentialsLazyQuery>;
export type TestDirectDebitCredentialsQueryResult = Apollo.QueryResult<TestDirectDebitCredentialsQuery, TestDirectDebitCredentialsQueryVariables>;
export const GetDirectDebitConnectionDocument = gql`
    query getDirectDebitConnection($clubId: String!) {
  getDirectDebitConnection(clubId: $clubId) {
    companyId
    clubId
    directDebitProviderId
    name
    username
    transactionFee
    merchantFeePercentage
    dishonorFee
    memberPaysDishonorFee
    memberPaysTransactionFee
    memberPaysMerchantFee
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;

/**
 * __useGetDirectDebitConnectionQuery__
 *
 * To run a query within a React component, call `useGetDirectDebitConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectDebitConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectDebitConnectionQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetDirectDebitConnectionQuery(baseOptions: Apollo.QueryHookOptions<GetDirectDebitConnectionQuery, GetDirectDebitConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectDebitConnectionQuery, GetDirectDebitConnectionQueryVariables>(GetDirectDebitConnectionDocument, options);
      }
export function useGetDirectDebitConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectDebitConnectionQuery, GetDirectDebitConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectDebitConnectionQuery, GetDirectDebitConnectionQueryVariables>(GetDirectDebitConnectionDocument, options);
        }
export type GetDirectDebitConnectionQueryHookResult = ReturnType<typeof useGetDirectDebitConnectionQuery>;
export type GetDirectDebitConnectionLazyQueryHookResult = ReturnType<typeof useGetDirectDebitConnectionLazyQuery>;
export type GetDirectDebitConnectionQueryResult = Apollo.QueryResult<GetDirectDebitConnectionQuery, GetDirectDebitConnectionQueryVariables>;
export const SetDirectDebitConnectionDocument = gql`
    mutation setDirectDebitConnection($input: SetDirectDebitConnectionInput!) {
  setDirectDebitConnection(input: $input) {
    companyId
    username
    directDebitProviderId
    name
    username
    transactionFee
    merchantFeePercentage
    dishonorFee
    memberPaysDishonorFee
    memberPaysTransactionFee
    memberPaysMerchantFee
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;
export type SetDirectDebitConnectionMutationFn = Apollo.MutationFunction<SetDirectDebitConnectionMutation, SetDirectDebitConnectionMutationVariables>;

/**
 * __useSetDirectDebitConnectionMutation__
 *
 * To run a mutation, you first call `useSetDirectDebitConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDirectDebitConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDirectDebitConnectionMutation, { data, loading, error }] = useSetDirectDebitConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDirectDebitConnectionMutation(baseOptions?: Apollo.MutationHookOptions<SetDirectDebitConnectionMutation, SetDirectDebitConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDirectDebitConnectionMutation, SetDirectDebitConnectionMutationVariables>(SetDirectDebitConnectionDocument, options);
      }
export type SetDirectDebitConnectionMutationHookResult = ReturnType<typeof useSetDirectDebitConnectionMutation>;
export type SetDirectDebitConnectionMutationResult = Apollo.MutationResult<SetDirectDebitConnectionMutation>;
export type SetDirectDebitConnectionMutationOptions = Apollo.BaseMutationOptions<SetDirectDebitConnectionMutation, SetDirectDebitConnectionMutationVariables>;
export const UpdateDirectDebitConnectionDocument = gql`
    mutation updateDirectDebitConnection($clubId: ID!, $input: UpdateDirectDebitConnectionInput!) {
  updateDirectDebitConnection(clubId: $clubId, input: $input) {
    companyId
    username
    directDebitProviderId
    name
    username
    transactionFee
    merchantFeePercentage
    dishonorFee
    memberPaysDishonorFee
    memberPaysTransactionFee
    memberPaysMerchantFee
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;
export type UpdateDirectDebitConnectionMutationFn = Apollo.MutationFunction<UpdateDirectDebitConnectionMutation, UpdateDirectDebitConnectionMutationVariables>;

/**
 * __useUpdateDirectDebitConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateDirectDebitConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectDebitConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectDebitConnectionMutation, { data, loading, error }] = useUpdateDirectDebitConnectionMutation({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDirectDebitConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDirectDebitConnectionMutation, UpdateDirectDebitConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDirectDebitConnectionMutation, UpdateDirectDebitConnectionMutationVariables>(UpdateDirectDebitConnectionDocument, options);
      }
export type UpdateDirectDebitConnectionMutationHookResult = ReturnType<typeof useUpdateDirectDebitConnectionMutation>;
export type UpdateDirectDebitConnectionMutationResult = Apollo.MutationResult<UpdateDirectDebitConnectionMutation>;
export type UpdateDirectDebitConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateDirectDebitConnectionMutation, UpdateDirectDebitConnectionMutationVariables>;
export const GetEmailStatsDocument = gql`
    query getEmailStats {
  getEmailStats {
    sent
    bounced
    bounceRate
    spamComplaints
    spamComplaintsRate
    opens
    uniqueOpens
  }
}
    `;

/**
 * __useGetEmailStatsQuery__
 *
 * To run a query within a React component, call `useGetEmailStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailStatsQuery, GetEmailStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailStatsQuery, GetEmailStatsQueryVariables>(GetEmailStatsDocument, options);
      }
export function useGetEmailStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailStatsQuery, GetEmailStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailStatsQuery, GetEmailStatsQueryVariables>(GetEmailStatsDocument, options);
        }
export type GetEmailStatsQueryHookResult = ReturnType<typeof useGetEmailStatsQuery>;
export type GetEmailStatsLazyQueryHookResult = ReturnType<typeof useGetEmailStatsLazyQuery>;
export type GetEmailStatsQueryResult = Apollo.QueryResult<GetEmailStatsQuery, GetEmailStatsQueryVariables>;
export const GetEmailDomainsDocument = gql`
    query getEmailDomains {
  getEmailDomains {
    id
    isDefault
    name
    active
    returnPathDomain
    returnPathDomainCNAMEValue
    returnPathDomainVerified
    dKIMHost
    dKIMTextValue
    dKIMVerified
    dKIMPendingHost
    dKIMPendingTextValue
  }
}
    `;

/**
 * __useGetEmailDomainsQuery__
 *
 * To run a query within a React component, call `useGetEmailDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailDomainsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailDomainsQuery, GetEmailDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailDomainsQuery, GetEmailDomainsQueryVariables>(GetEmailDomainsDocument, options);
      }
export function useGetEmailDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailDomainsQuery, GetEmailDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailDomainsQuery, GetEmailDomainsQueryVariables>(GetEmailDomainsDocument, options);
        }
export type GetEmailDomainsQueryHookResult = ReturnType<typeof useGetEmailDomainsQuery>;
export type GetEmailDomainsLazyQueryHookResult = ReturnType<typeof useGetEmailDomainsLazyQuery>;
export type GetEmailDomainsQueryResult = Apollo.QueryResult<GetEmailDomainsQuery, GetEmailDomainsQueryVariables>;
export const AddEmailDomainDocument = gql`
    mutation addEmailDomain($name: String!) {
  addEmailDomain(name: $name) {
    emailDomain {
      id
      isDefault
      name
      active
      returnPathDomain
      returnPathDomainCNAMEValue
      returnPathDomainVerified
      dKIMHost
      dKIMTextValue
      dKIMVerified
      dKIMPendingHost
      dKIMPendingTextValue
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddEmailDomainMutationFn = Apollo.MutationFunction<AddEmailDomainMutation, AddEmailDomainMutationVariables>;

/**
 * __useAddEmailDomainMutation__
 *
 * To run a mutation, you first call `useAddEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailDomainMutation, { data, loading, error }] = useAddEmailDomainMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailDomainMutation, AddEmailDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmailDomainMutation, AddEmailDomainMutationVariables>(AddEmailDomainDocument, options);
      }
export type AddEmailDomainMutationHookResult = ReturnType<typeof useAddEmailDomainMutation>;
export type AddEmailDomainMutationResult = Apollo.MutationResult<AddEmailDomainMutation>;
export type AddEmailDomainMutationOptions = Apollo.BaseMutationOptions<AddEmailDomainMutation, AddEmailDomainMutationVariables>;
export const RemoveEmailDomainDocument = gql`
    mutation removeEmailDomain($id: Int!) {
  removeEmailDomain(id: $id) {
    emailDomain {
      id
    }
    errors {
      type
      message
    }
  }
}
    `;
export type RemoveEmailDomainMutationFn = Apollo.MutationFunction<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>;

/**
 * __useRemoveEmailDomainMutation__
 *
 * To run a mutation, you first call `useRemoveEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmailDomainMutation, { data, loading, error }] = useRemoveEmailDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>(RemoveEmailDomainDocument, options);
      }
export type RemoveEmailDomainMutationHookResult = ReturnType<typeof useRemoveEmailDomainMutation>;
export type RemoveEmailDomainMutationResult = Apollo.MutationResult<RemoveEmailDomainMutation>;
export type RemoveEmailDomainMutationOptions = Apollo.BaseMutationOptions<RemoveEmailDomainMutation, RemoveEmailDomainMutationVariables>;
export const VerifyEmailDomainDocument = gql`
    mutation verifyEmailDomain($id: Int!) {
  verifyEmailDomain(id: $id) {
    emailDomain {
      id
      isDefault
      name
      active
      returnPathDomain
      returnPathDomainCNAMEValue
      returnPathDomainVerified
      dKIMHost
      dKIMTextValue
      dKIMVerified
      dKIMPendingHost
      dKIMPendingTextValue
    }
    errors {
      type
      message
    }
  }
}
    `;
export type VerifyEmailDomainMutationFn = Apollo.MutationFunction<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>;

/**
 * __useVerifyEmailDomainMutation__
 *
 * To run a mutation, you first call `useVerifyEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailDomainMutation, { data, loading, error }] = useVerifyEmailDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>(VerifyEmailDomainDocument, options);
      }
export type VerifyEmailDomainMutationHookResult = ReturnType<typeof useVerifyEmailDomainMutation>;
export type VerifyEmailDomainMutationResult = Apollo.MutationResult<VerifyEmailDomainMutation>;
export type VerifyEmailDomainMutationOptions = Apollo.BaseMutationOptions<VerifyEmailDomainMutation, VerifyEmailDomainMutationVariables>;
export const EnableEmailDomainDocument = gql`
    mutation enableEmailDomain($id: Int!) {
  enableEmailDomain(id: $id) {
    emailDomain {
      id
      isDefault
      name
      active
      returnPathDomain
      returnPathDomainCNAMEValue
      returnPathDomainVerified
      dKIMHost
      dKIMTextValue
      dKIMVerified
      dKIMPendingHost
      dKIMPendingTextValue
    }
    errors {
      type
      message
    }
  }
}
    `;
export type EnableEmailDomainMutationFn = Apollo.MutationFunction<EnableEmailDomainMutation, EnableEmailDomainMutationVariables>;

/**
 * __useEnableEmailDomainMutation__
 *
 * To run a mutation, you first call `useEnableEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableEmailDomainMutation, { data, loading, error }] = useEnableEmailDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<EnableEmailDomainMutation, EnableEmailDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableEmailDomainMutation, EnableEmailDomainMutationVariables>(EnableEmailDomainDocument, options);
      }
export type EnableEmailDomainMutationHookResult = ReturnType<typeof useEnableEmailDomainMutation>;
export type EnableEmailDomainMutationResult = Apollo.MutationResult<EnableEmailDomainMutation>;
export type EnableEmailDomainMutationOptions = Apollo.BaseMutationOptions<EnableEmailDomainMutation, EnableEmailDomainMutationVariables>;
export const GetPublicFileUploadUrlDocument = gql`
    query getPublicFileUploadUrl($uploadFileType: PublicUploadFileType!, $fileType: String!, $entityId: ID) {
  getPublicFileUploadUrl(
    uploadFileType: $uploadFileType
    fileType: $fileType
    entityId: $entityId
  ) {
    uploadUrl
    getUrl
  }
}
    `;

/**
 * __useGetPublicFileUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetPublicFileUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicFileUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicFileUploadUrlQuery({
 *   variables: {
 *      uploadFileType: // value for 'uploadFileType'
 *      fileType: // value for 'fileType'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetPublicFileUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPublicFileUploadUrlQuery, GetPublicFileUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicFileUploadUrlQuery, GetPublicFileUploadUrlQueryVariables>(GetPublicFileUploadUrlDocument, options);
      }
export function useGetPublicFileUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicFileUploadUrlQuery, GetPublicFileUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicFileUploadUrlQuery, GetPublicFileUploadUrlQueryVariables>(GetPublicFileUploadUrlDocument, options);
        }
export type GetPublicFileUploadUrlQueryHookResult = ReturnType<typeof useGetPublicFileUploadUrlQuery>;
export type GetPublicFileUploadUrlLazyQueryHookResult = ReturnType<typeof useGetPublicFileUploadUrlLazyQuery>;
export type GetPublicFileUploadUrlQueryResult = Apollo.QueryResult<GetPublicFileUploadUrlQuery, GetPublicFileUploadUrlQueryVariables>;
export const GetMeStaffDocument = gql`
    query getMeStaff {
  getMeStaff {
    id
    givenName
    familyName
    displayName
    email
    mobileNumber
    profilePictureUrl
    gender
    role
    company {
      id
      name
      brandName
      headOfficeAddress
      description
      logoImageUrl
      coverImageUrl
      termsAndConditionsUrl
    }
  }
}
    `;

/**
 * __useGetMeStaffQuery__
 *
 * To run a query within a React component, call `useGetMeStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeStaffQuery(baseOptions?: Apollo.QueryHookOptions<GetMeStaffQuery, GetMeStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeStaffQuery, GetMeStaffQueryVariables>(GetMeStaffDocument, options);
      }
export function useGetMeStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeStaffQuery, GetMeStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeStaffQuery, GetMeStaffQueryVariables>(GetMeStaffDocument, options);
        }
export type GetMeStaffQueryHookResult = ReturnType<typeof useGetMeStaffQuery>;
export type GetMeStaffLazyQueryHookResult = ReturnType<typeof useGetMeStaffLazyQuery>;
export type GetMeStaffQueryResult = Apollo.QueryResult<GetMeStaffQuery, GetMeStaffQueryVariables>;
export const GetMemberPaymentMethodsDocument = gql`
    query getMemberPaymentMethods($memberId: ID!) {
  getMemberPaymentMethods(memberId: $memberId) {
    id
    isDefault
    memberId
    companyId
    type
    providerId
    maskedNumber
    expiryMonth
    expiryYear
    accountName
  }
}
    `;

/**
 * __useGetMemberPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetMemberPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPaymentMethodsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetMemberPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<GetMemberPaymentMethodsQuery, GetMemberPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberPaymentMethodsQuery, GetMemberPaymentMethodsQueryVariables>(GetMemberPaymentMethodsDocument, options);
      }
export function useGetMemberPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberPaymentMethodsQuery, GetMemberPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberPaymentMethodsQuery, GetMemberPaymentMethodsQueryVariables>(GetMemberPaymentMethodsDocument, options);
        }
export type GetMemberPaymentMethodsQueryHookResult = ReturnType<typeof useGetMemberPaymentMethodsQuery>;
export type GetMemberPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetMemberPaymentMethodsLazyQuery>;
export type GetMemberPaymentMethodsQueryResult = Apollo.QueryResult<GetMemberPaymentMethodsQuery, GetMemberPaymentMethodsQueryVariables>;
export const GetMemberPaymentMethodDocument = gql`
    query getMemberPaymentMethod($memberId: ID!, $id: ID!) {
  getMemberPaymentMethod(memberId: $memberId, id: $id) {
    id
    isDefault
    memberId
    companyId
    type
    providerId
    maskedNumber
    expiryMonth
    expiryYear
    accountName
  }
}
    `;

/**
 * __useGetMemberPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetMemberPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPaymentMethodQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<GetMemberPaymentMethodQuery, GetMemberPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberPaymentMethodQuery, GetMemberPaymentMethodQueryVariables>(GetMemberPaymentMethodDocument, options);
      }
export function useGetMemberPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberPaymentMethodQuery, GetMemberPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberPaymentMethodQuery, GetMemberPaymentMethodQueryVariables>(GetMemberPaymentMethodDocument, options);
        }
export type GetMemberPaymentMethodQueryHookResult = ReturnType<typeof useGetMemberPaymentMethodQuery>;
export type GetMemberPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetMemberPaymentMethodLazyQuery>;
export type GetMemberPaymentMethodQueryResult = Apollo.QueryResult<GetMemberPaymentMethodQuery, GetMemberPaymentMethodQueryVariables>;
export const AddMemberPaymentMethodDocument = gql`
    mutation addMemberPaymentMethod($memberId: ID!, $input: AddMemberPaymentMethodInput!) {
  addMemberPaymentMethod(memberId: $memberId, input: $input) {
    memberPaymentMethod {
      id
      isDefault
      memberId
      companyId
      type
      providerId
      maskedNumber
      expiryMonth
      expiryYear
      accountName
    }
    errors {
      type
      message
    }
  }
}
    `;
export type AddMemberPaymentMethodMutationFn = Apollo.MutationFunction<AddMemberPaymentMethodMutation, AddMemberPaymentMethodMutationVariables>;

/**
 * __useAddMemberPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddMemberPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberPaymentMethodMutation, { data, loading, error }] = useAddMemberPaymentMethodMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberPaymentMethodMutation, AddMemberPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberPaymentMethodMutation, AddMemberPaymentMethodMutationVariables>(AddMemberPaymentMethodDocument, options);
      }
export type AddMemberPaymentMethodMutationHookResult = ReturnType<typeof useAddMemberPaymentMethodMutation>;
export type AddMemberPaymentMethodMutationResult = Apollo.MutationResult<AddMemberPaymentMethodMutation>;
export type AddMemberPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddMemberPaymentMethodMutation, AddMemberPaymentMethodMutationVariables>;
export const RemoveMemberPaymentMethodDocument = gql`
    mutation removeMemberPaymentMethod($memberId: ID!, $id: ID!) {
  removeMemberPaymentMethod(memberId: $memberId, id: $id) {
    memberPaymentMethod {
      id
      isDefault
      memberId
      companyId
      type
      providerId
      maskedNumber
      expiryMonth
      expiryYear
      accountName
    }
    errors {
      type
      message
    }
  }
}
    `;
export type RemoveMemberPaymentMethodMutationFn = Apollo.MutationFunction<RemoveMemberPaymentMethodMutation, RemoveMemberPaymentMethodMutationVariables>;

/**
 * __useRemoveMemberPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveMemberPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberPaymentMethodMutation, { data, loading, error }] = useRemoveMemberPaymentMethodMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMemberPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberPaymentMethodMutation, RemoveMemberPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberPaymentMethodMutation, RemoveMemberPaymentMethodMutationVariables>(RemoveMemberPaymentMethodDocument, options);
      }
export type RemoveMemberPaymentMethodMutationHookResult = ReturnType<typeof useRemoveMemberPaymentMethodMutation>;
export type RemoveMemberPaymentMethodMutationResult = Apollo.MutationResult<RemoveMemberPaymentMethodMutation>;
export type RemoveMemberPaymentMethodMutationOptions = Apollo.BaseMutationOptions<RemoveMemberPaymentMethodMutation, RemoveMemberPaymentMethodMutationVariables>;
export const SetMemberPaymentMethodAsDefaultDocument = gql`
    mutation setMemberPaymentMethodAsDefault($memberId: ID!, $id: ID!) {
  setMemberPaymentMethodAsDefault(memberId: $memberId, id: $id) {
    memberPaymentMethod {
      id
      isDefault
      memberId
      companyId
      type
      providerId
      maskedNumber
      expiryMonth
      expiryYear
      accountName
    }
    errors {
      type
      message
    }
  }
}
    `;
export type SetMemberPaymentMethodAsDefaultMutationFn = Apollo.MutationFunction<SetMemberPaymentMethodAsDefaultMutation, SetMemberPaymentMethodAsDefaultMutationVariables>;

/**
 * __useSetMemberPaymentMethodAsDefaultMutation__
 *
 * To run a mutation, you first call `useSetMemberPaymentMethodAsDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMemberPaymentMethodAsDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMemberPaymentMethodAsDefaultMutation, { data, loading, error }] = useSetMemberPaymentMethodAsDefaultMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetMemberPaymentMethodAsDefaultMutation(baseOptions?: Apollo.MutationHookOptions<SetMemberPaymentMethodAsDefaultMutation, SetMemberPaymentMethodAsDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMemberPaymentMethodAsDefaultMutation, SetMemberPaymentMethodAsDefaultMutationVariables>(SetMemberPaymentMethodAsDefaultDocument, options);
      }
export type SetMemberPaymentMethodAsDefaultMutationHookResult = ReturnType<typeof useSetMemberPaymentMethodAsDefaultMutation>;
export type SetMemberPaymentMethodAsDefaultMutationResult = Apollo.MutationResult<SetMemberPaymentMethodAsDefaultMutation>;
export type SetMemberPaymentMethodAsDefaultMutationOptions = Apollo.BaseMutationOptions<SetMemberPaymentMethodAsDefaultMutation, SetMemberPaymentMethodAsDefaultMutationVariables>;
export const GetMembersDocument = gql`
    query getMembers($limit: Int, $nextToken: String, $status: MemberStatus) {
  getMembers(limit: $limit, nextToken: $nextToken, status: $status) {
    items {
      id
      email
      givenName
      familyName
      displayName
      mobileNumber
      status
      gender
      emailVerified
      profilePictureUrl
      homeClubId
      homeClubName
      activeMembershipId
    }
    nextToken
  }
}
    `;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetMemberActiveMembershipDocument = gql`
    query getMemberActiveMembership($memberId: ID!) {
  getMemberActiveMembership(memberId: $memberId) {
    id
    memberName
    membershipName
    memberId
    membershipId
    companyId
    startDate
    paymentFrequency
    directDebitProviderId
    paymentAmount
    joinFee
    adminFee
    fobFee
    cancellationFee
    suspensionFee
    transactionFee
    merchantFeePercentage
    dishonorFee
    visitLimit
    hasCustomFees
    visitLimitFrequency
    lengthFrequency
    lengthAmount
  }
}
    `;

/**
 * __useGetMemberActiveMembershipQuery__
 *
 * To run a query within a React component, call `useGetMemberActiveMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberActiveMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberActiveMembershipQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetMemberActiveMembershipQuery(baseOptions: Apollo.QueryHookOptions<GetMemberActiveMembershipQuery, GetMemberActiveMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberActiveMembershipQuery, GetMemberActiveMembershipQueryVariables>(GetMemberActiveMembershipDocument, options);
      }
export function useGetMemberActiveMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberActiveMembershipQuery, GetMemberActiveMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberActiveMembershipQuery, GetMemberActiveMembershipQueryVariables>(GetMemberActiveMembershipDocument, options);
        }
export type GetMemberActiveMembershipQueryHookResult = ReturnType<typeof useGetMemberActiveMembershipQuery>;
export type GetMemberActiveMembershipLazyQueryHookResult = ReturnType<typeof useGetMemberActiveMembershipLazyQuery>;
export type GetMemberActiveMembershipQueryResult = Apollo.QueryResult<GetMemberActiveMembershipQuery, GetMemberActiveMembershipQueryVariables>;
export const GetActivePaymentDetailsForMemberDocument = gql`
    query getActivePaymentDetailsForMember($memberId: ID!, $usesCreditCard: Boolean!) {
  getActivePaymentDetailsForMember(
    memberId: $memberId
    usesCreditCard: $usesCreditCard
  ) {
    memberId
    membershipId
    baseCurrency {
      code
      base
      exponent
    }
    directDebitProviderId
    nextChargeDate
    firstAmount {
      total
      subTotal
      tax
      transactionFee
      merchantFee
    }
    regularAmount {
      amount {
        total
        subTotal
        tax
        transactionFee
        merchantFee
      }
      frequency
    }
    memberFees {
      joinFee
      adminFee
      fobFee
      cancellationFee
      suspensionFee
    }
    transactionFees {
      transactionFee
      merchantFeePercentage
      dishonorFee
    }
  }
}
    `;

/**
 * __useGetActivePaymentDetailsForMemberQuery__
 *
 * To run a query within a React component, call `useGetActivePaymentDetailsForMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePaymentDetailsForMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePaymentDetailsForMemberQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      usesCreditCard: // value for 'usesCreditCard'
 *   },
 * });
 */
export function useGetActivePaymentDetailsForMemberQuery(baseOptions: Apollo.QueryHookOptions<GetActivePaymentDetailsForMemberQuery, GetActivePaymentDetailsForMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivePaymentDetailsForMemberQuery, GetActivePaymentDetailsForMemberQueryVariables>(GetActivePaymentDetailsForMemberDocument, options);
      }
export function useGetActivePaymentDetailsForMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivePaymentDetailsForMemberQuery, GetActivePaymentDetailsForMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivePaymentDetailsForMemberQuery, GetActivePaymentDetailsForMemberQueryVariables>(GetActivePaymentDetailsForMemberDocument, options);
        }
export type GetActivePaymentDetailsForMemberQueryHookResult = ReturnType<typeof useGetActivePaymentDetailsForMemberQuery>;
export type GetActivePaymentDetailsForMemberLazyQueryHookResult = ReturnType<typeof useGetActivePaymentDetailsForMemberLazyQuery>;
export type GetActivePaymentDetailsForMemberQueryResult = Apollo.QueryResult<GetActivePaymentDetailsForMemberQuery, GetActivePaymentDetailsForMemberQueryVariables>;
export const GetMemberDocument = gql`
    query getMember($id: ID!) {
  getMember(id: $id) {
    id
    email
    givenName
    familyName
    displayName
    mobileNumber
    status
    gender
    emailVerified
    address
    dateOfBirth
    profilePictureUrl
    privateNote
    activeMembershipId
    clubIds
    homeClubId
    homeClubName
  }
}
    `;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberQuery(baseOptions: Apollo.QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
      }
export function useGetMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
        }
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<typeof useGetMemberLazyQuery>;
export type GetMemberQueryResult = Apollo.QueryResult<GetMemberQuery, GetMemberQueryVariables>;
export const AddMemberDocument = gql`
    mutation addMember($input: AddMemberInput!) {
  addMember(input: $input) {
    id
    email
    givenName
    familyName
    displayName
    mobileNumber
    status
    gender
    emailVerified
    address
    dateOfBirth
    profilePictureUrl
    homeClubId
    homeClubName
  }
}
    `;
export type AddMemberMutationFn = Apollo.MutationFunction<AddMemberMutation, AddMemberMutationVariables>;

/**
 * __useAddMemberMutation__
 *
 * To run a mutation, you first call `useAddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberMutation, { data, loading, error }] = useAddMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberMutation, AddMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberMutation, AddMemberMutationVariables>(AddMemberDocument, options);
      }
export type AddMemberMutationHookResult = ReturnType<typeof useAddMemberMutation>;
export type AddMemberMutationResult = Apollo.MutationResult<AddMemberMutation>;
export type AddMemberMutationOptions = Apollo.BaseMutationOptions<AddMemberMutation, AddMemberMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation updateMember($id: ID!, $input: UpdateMemberInput!) {
  updateMember(id: $id, input: $input) {
    member {
      id
      email
      givenName
      familyName
      displayName
      mobileNumber
      status
      gender
      emailVerified
      address
      dateOfBirth
      profilePictureUrl
      privateNote
      activeMembershipId
      clubIds
      homeClubId
      homeClubName
    }
    error {
      type
      message
    }
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const AssignMembershipToMemberDocument = gql`
    mutation assignMembershipToMember($memberId: ID!, $input: AssignMembershipInput!) {
  assignMembershipToMember(memberId: $memberId, input: $input) {
    memberMembership {
      id
      memberName
      membershipName
      memberId
      membershipId
      companyId
      startDate
      paymentFrequency
      paymentAmount
      visitLimit
      visitLimitFrequency
      lengthFrequency
      lengthAmount
      joinFee
      adminFee
      fobFee
      cancellationFee
      suspensionFee
      transactionFee
      merchantFeePercentage
      dishonorFee
      hasCustomFees
    }
    error {
      type
      message
    }
  }
}
    `;
export type AssignMembershipToMemberMutationFn = Apollo.MutationFunction<AssignMembershipToMemberMutation, AssignMembershipToMemberMutationVariables>;

/**
 * __useAssignMembershipToMemberMutation__
 *
 * To run a mutation, you first call `useAssignMembershipToMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignMembershipToMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignMembershipToMemberMutation, { data, loading, error }] = useAssignMembershipToMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignMembershipToMemberMutation(baseOptions?: Apollo.MutationHookOptions<AssignMembershipToMemberMutation, AssignMembershipToMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignMembershipToMemberMutation, AssignMembershipToMemberMutationVariables>(AssignMembershipToMemberDocument, options);
      }
export type AssignMembershipToMemberMutationHookResult = ReturnType<typeof useAssignMembershipToMemberMutation>;
export type AssignMembershipToMemberMutationResult = Apollo.MutationResult<AssignMembershipToMemberMutation>;
export type AssignMembershipToMemberMutationOptions = Apollo.BaseMutationOptions<AssignMembershipToMemberMutation, AssignMembershipToMemberMutationVariables>;
export const UpdateMemberMembershipDocument = gql`
    mutation updateMemberMembership($memberId: ID!, $id: ID!, $input: UpdateMemberMembershipInput!) {
  updateMemberMembership(memberId: $memberId, id: $id, input: $input) {
    memberMembership {
      id
      memberName
      membershipName
      memberId
      membershipId
      companyId
      startDate
      paymentFrequency
      paymentAmount
      visitLimit
      visitLimitFrequency
      lengthFrequency
      lengthAmount
      joinFee
      adminFee
      fobFee
      cancellationFee
      suspensionFee
      transactionFee
      merchantFeePercentage
      dishonorFee
      hasCustomFees
    }
    error {
      type
      message
    }
  }
}
    `;
export type UpdateMemberMembershipMutationFn = Apollo.MutationFunction<UpdateMemberMembershipMutation, UpdateMemberMembershipMutationVariables>;

/**
 * __useUpdateMemberMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMembershipMutation, { data, loading, error }] = useUpdateMemberMembershipMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMembershipMutation, UpdateMemberMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMembershipMutation, UpdateMemberMembershipMutationVariables>(UpdateMemberMembershipDocument, options);
      }
export type UpdateMemberMembershipMutationHookResult = ReturnType<typeof useUpdateMemberMembershipMutation>;
export type UpdateMemberMembershipMutationResult = Apollo.MutationResult<UpdateMemberMembershipMutation>;
export type UpdateMemberMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMembershipMutation, UpdateMemberMembershipMutationVariables>;
export const AgreeToCurrentMembershipDocument = gql`
    mutation agreeToCurrentMembership($memberId: ID!, $input: AgreeToMembershipInput!) {
  agreeToCurrentMembership(memberId: $memberId, input: $input) {
    id
    memberName
    membershipName
    memberId
    membershipId
    companyId
    startDate
    paymentFrequency
    paymentAmount
    visitLimit
    visitLimitFrequency
    lengthFrequency
    lengthAmount
    joinFee
    adminFee
    fobFee
    cancellationFee
    suspensionFee
    transactionFee
    merchantFeePercentage
    dishonorFee
    hasCustomFees
  }
}
    `;
export type AgreeToCurrentMembershipMutationFn = Apollo.MutationFunction<AgreeToCurrentMembershipMutation, AgreeToCurrentMembershipMutationVariables>;

/**
 * __useAgreeToCurrentMembershipMutation__
 *
 * To run a mutation, you first call `useAgreeToCurrentMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeToCurrentMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeToCurrentMembershipMutation, { data, loading, error }] = useAgreeToCurrentMembershipMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgreeToCurrentMembershipMutation(baseOptions?: Apollo.MutationHookOptions<AgreeToCurrentMembershipMutation, AgreeToCurrentMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgreeToCurrentMembershipMutation, AgreeToCurrentMembershipMutationVariables>(AgreeToCurrentMembershipDocument, options);
      }
export type AgreeToCurrentMembershipMutationHookResult = ReturnType<typeof useAgreeToCurrentMembershipMutation>;
export type AgreeToCurrentMembershipMutationResult = Apollo.MutationResult<AgreeToCurrentMembershipMutation>;
export type AgreeToCurrentMembershipMutationOptions = Apollo.BaseMutationOptions<AgreeToCurrentMembershipMutation, AgreeToCurrentMembershipMutationVariables>;
export const GetMembershipsForCompanyDocument = gql`
    query getMembershipsForCompany($limit: Int, $nextToken: String) {
  getMembershipsForCompany(limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      status
      publishedAt
      thumbnailImageUrl
      paymentFrequency
      paymentAmount
      baseCurrency {
        code
        base
        exponent
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetMembershipsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetMembershipsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipsForCompanyQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetMembershipsForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetMembershipsForCompanyQuery, GetMembershipsForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembershipsForCompanyQuery, GetMembershipsForCompanyQueryVariables>(GetMembershipsForCompanyDocument, options);
      }
export function useGetMembershipsForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipsForCompanyQuery, GetMembershipsForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembershipsForCompanyQuery, GetMembershipsForCompanyQueryVariables>(GetMembershipsForCompanyDocument, options);
        }
export type GetMembershipsForCompanyQueryHookResult = ReturnType<typeof useGetMembershipsForCompanyQuery>;
export type GetMembershipsForCompanyLazyQueryHookResult = ReturnType<typeof useGetMembershipsForCompanyLazyQuery>;
export type GetMembershipsForCompanyQueryResult = Apollo.QueryResult<GetMembershipsForCompanyQuery, GetMembershipsForCompanyQueryVariables>;
export const GetMembershipsForClubDocument = gql`
    query getMembershipsForClub($clubId: ID!, $status: MembershipStatus, $limit: Int, $nextToken: String) {
  getMembershipsForClub(
    clubId: $clubId
    status: $status
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      membershipId
      clubId
      membershipName
      membershipDescription
      clubName
      status
      publishedAt
      thumbnailImageUrl
    }
    nextToken
  }
}
    `;

/**
 * __useGetMembershipsForClubQuery__
 *
 * To run a query within a React component, call `useGetMembershipsForClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipsForClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipsForClubQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetMembershipsForClubQuery(baseOptions: Apollo.QueryHookOptions<GetMembershipsForClubQuery, GetMembershipsForClubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembershipsForClubQuery, GetMembershipsForClubQueryVariables>(GetMembershipsForClubDocument, options);
      }
export function useGetMembershipsForClubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipsForClubQuery, GetMembershipsForClubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembershipsForClubQuery, GetMembershipsForClubQueryVariables>(GetMembershipsForClubDocument, options);
        }
export type GetMembershipsForClubQueryHookResult = ReturnType<typeof useGetMembershipsForClubQuery>;
export type GetMembershipsForClubLazyQueryHookResult = ReturnType<typeof useGetMembershipsForClubLazyQuery>;
export type GetMembershipsForClubQueryResult = Apollo.QueryResult<GetMembershipsForClubQuery, GetMembershipsForClubQueryVariables>;
export const GetMembershipDocument = gql`
    query getMembership($id: ID!) {
  getMembership(id: $id) {
    availablePaymentPeriods
    coverImageUrl
    description
    id
    lengthAmount
    lengthFrequency
    name
    paymentAmount
    paymentFrequency
    publishedAt
    status
    thumbnailImageUrl
    visitLimit
    visitLimitFrequency
    joinFee
    adminFee
    fobFee
    cancellationFee
    suspensionFee
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;

/**
 * __useGetMembershipQuery__
 *
 * To run a query within a React component, call `useGetMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMembershipQuery(baseOptions: Apollo.QueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, options);
      }
export function useGetMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipQuery, GetMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembershipQuery, GetMembershipQueryVariables>(GetMembershipDocument, options);
        }
export type GetMembershipQueryHookResult = ReturnType<typeof useGetMembershipQuery>;
export type GetMembershipLazyQueryHookResult = ReturnType<typeof useGetMembershipLazyQuery>;
export type GetMembershipQueryResult = Apollo.QueryResult<GetMembershipQuery, GetMembershipQueryVariables>;
export const AddMembershipDocument = gql`
    mutation addMembership($input: AddMembershipInput!) {
  addMembership(input: $input) {
    membership {
      availablePaymentPeriods
      coverImageUrl
      description
      id
      lengthAmount
      lengthFrequency
      name
      paymentAmount
      paymentFrequency
      publishedAt
      status
      thumbnailImageUrl
      visitLimit
      visitLimitFrequency
      joinFee
      adminFee
      fobFee
      cancellationFee
      suspensionFee
      baseCurrency {
        code
        base
        exponent
      }
    }
    error {
      type
      message
    }
  }
}
    `;
export type AddMembershipMutationFn = Apollo.MutationFunction<AddMembershipMutation, AddMembershipMutationVariables>;

/**
 * __useAddMembershipMutation__
 *
 * To run a mutation, you first call `useAddMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembershipMutation, { data, loading, error }] = useAddMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMembershipMutation(baseOptions?: Apollo.MutationHookOptions<AddMembershipMutation, AddMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMembershipMutation, AddMembershipMutationVariables>(AddMembershipDocument, options);
      }
export type AddMembershipMutationHookResult = ReturnType<typeof useAddMembershipMutation>;
export type AddMembershipMutationResult = Apollo.MutationResult<AddMembershipMutation>;
export type AddMembershipMutationOptions = Apollo.BaseMutationOptions<AddMembershipMutation, AddMembershipMutationVariables>;
export const UpdateMembershipDocument = gql`
    mutation updateMembership($id: ID!, $input: UpdateMembershipInput!) {
  updateMembership(id: $id, input: $input) {
    availablePaymentPeriods
    coverImageUrl
    description
    id
    lengthAmount
    lengthFrequency
    name
    paymentAmount
    paymentFrequency
    publishedAt
    status
    thumbnailImageUrl
    visitLimit
    visitLimitFrequency
    joinFee
    adminFee
    fobFee
    cancellationFee
    suspensionFee
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const SetMembershipStatusDocument = gql`
    mutation setMembershipStatus($id: ID!, $status: MembershipStatus!) {
  setMembershipStatus(id: $id, status: $status) {
    availablePaymentPeriods
    coverImageUrl
    description
    id
    lengthAmount
    lengthFrequency
    name
    paymentAmount
    paymentFrequency
    publishedAt
    status
    thumbnailImageUrl
    visitLimit
    visitLimitFrequency
    joinFee
    adminFee
    fobFee
    cancellationFee
    suspensionFee
    baseCurrency {
      code
      base
      exponent
    }
  }
}
    `;
export type SetMembershipStatusMutationFn = Apollo.MutationFunction<SetMembershipStatusMutation, SetMembershipStatusMutationVariables>;

/**
 * __useSetMembershipStatusMutation__
 *
 * To run a mutation, you first call `useSetMembershipStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMembershipStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMembershipStatusMutation, { data, loading, error }] = useSetMembershipStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetMembershipStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetMembershipStatusMutation, SetMembershipStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMembershipStatusMutation, SetMembershipStatusMutationVariables>(SetMembershipStatusDocument, options);
      }
export type SetMembershipStatusMutationHookResult = ReturnType<typeof useSetMembershipStatusMutation>;
export type SetMembershipStatusMutationResult = Apollo.MutationResult<SetMembershipStatusMutation>;
export type SetMembershipStatusMutationOptions = Apollo.BaseMutationOptions<SetMembershipStatusMutation, SetMembershipStatusMutationVariables>;
export const GetMessageTemplatesDocument = gql`
    query getMessageTemplates {
  getMessageTemplates {
    id
    category
    description
  }
}
    `;

/**
 * __useGetMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMessageTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
      }
export function useGetMessageTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
        }
export type GetMessageTemplatesQueryHookResult = ReturnType<typeof useGetMessageTemplatesQuery>;
export type GetMessageTemplatesLazyQueryHookResult = ReturnType<typeof useGetMessageTemplatesLazyQuery>;
export type GetMessageTemplatesQueryResult = Apollo.QueryResult<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>;
export const GetMessageTemplateDocument = gql`
    query getMessageTemplate($templateId: MessageTemplateId!) {
  getMessageTemplate(templateId: $templateId) {
    id
    category
    description
    sms
    mergeTags {
      key
      name
      value
      sample
    }
    email {
      design
      subject
    }
  }
}
    `;

/**
 * __useGetMessageTemplateQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetMessageTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>(GetMessageTemplateDocument, options);
      }
export function useGetMessageTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>(GetMessageTemplateDocument, options);
        }
export type GetMessageTemplateQueryHookResult = ReturnType<typeof useGetMessageTemplateQuery>;
export type GetMessageTemplateLazyQueryHookResult = ReturnType<typeof useGetMessageTemplateLazyQuery>;
export type GetMessageTemplateQueryResult = Apollo.QueryResult<GetMessageTemplateQuery, GetMessageTemplateQueryVariables>;
export const UpdateMessageTemplateDocument = gql`
    mutation updateMessageTemplate($id: MessageTemplateId!, $emailInput: UpdateEmailMessageTemplateInput, $smsInput: String) {
  updateMessageTemplate(id: $id, emailInput: $emailInput, smsInput: $smsInput) {
    messageTemplate {
      id
      category
      sms
      email {
        subject
      }
    }
    errors {
      type
      message
    }
  }
}
    `;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailInput: // value for 'emailInput'
 *      smsInput: // value for 'smsInput'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UpdateMessageTemplateDocument, options);
      }
export type UpdateMessageTemplateMutationHookResult = ReturnType<typeof useUpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationResult = Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;
export const SearchDocument = gql`
    query search($input: SearchInput!) {
  search(input: $input) {
    items {
      id
      title
      detail
      thumbnailUrl
      type
      status
      email
      homeClubId
      homeClubName
    }
    page
    pageCount
    perPage
    resultCount
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const MemberSearchDocument = gql`
    query memberSearch($input: MemberSearchInput!) {
  memberSearch(input: $input) {
    items {
      id
      title
      detail
      thumbnailUrl
      type
      status
      email
      homeClubId
      homeClubName
    }
    page
    pageCount
    perPage
    resultCount
  }
}
    `;

/**
 * __useMemberSearchQuery__
 *
 * To run a query within a React component, call `useMemberSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberSearchQuery(baseOptions: Apollo.QueryHookOptions<MemberSearchQuery, MemberSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberSearchQuery, MemberSearchQueryVariables>(MemberSearchDocument, options);
      }
export function useMemberSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberSearchQuery, MemberSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberSearchQuery, MemberSearchQueryVariables>(MemberSearchDocument, options);
        }
export type MemberSearchQueryHookResult = ReturnType<typeof useMemberSearchQuery>;
export type MemberSearchLazyQueryHookResult = ReturnType<typeof useMemberSearchLazyQuery>;
export type MemberSearchQueryResult = Apollo.QueryResult<MemberSearchQuery, MemberSearchQueryVariables>;
export const GetSmsNumberDocument = gql`
    query getSmsNumber($clubId: ID!) {
  getSmsNumber(clubId: $clubId) {
    id
    phoneNumber
    providerId
    numberType
    provider
    countryCode
  }
}
    `;

/**
 * __useGetSmsNumberQuery__
 *
 * To run a query within a React component, call `useGetSmsNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsNumberQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetSmsNumberQuery(baseOptions: Apollo.QueryHookOptions<GetSmsNumberQuery, GetSmsNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsNumberQuery, GetSmsNumberQueryVariables>(GetSmsNumberDocument, options);
      }
export function useGetSmsNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsNumberQuery, GetSmsNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsNumberQuery, GetSmsNumberQueryVariables>(GetSmsNumberDocument, options);
        }
export type GetSmsNumberQueryHookResult = ReturnType<typeof useGetSmsNumberQuery>;
export type GetSmsNumberLazyQueryHookResult = ReturnType<typeof useGetSmsNumberLazyQuery>;
export type GetSmsNumberQueryResult = Apollo.QueryResult<GetSmsNumberQuery, GetSmsNumberQueryVariables>;
export const GetSmsAccountDocument = gql`
    query getSmsAccount($clubId: ID!) {
  getSmsAccount(clubId: $clubId) {
    balance
    clubId
    outboundCost
    inboundCost
    smsEnabled
    autoTopUpEnabled
    autoTopUpAmount
    autoTopUpWhenBelow
    smsNumberId
    paymentMethodId
  }
}
    `;

/**
 * __useGetSmsAccountQuery__
 *
 * To run a query within a React component, call `useGetSmsAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsAccountQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetSmsAccountQuery(baseOptions: Apollo.QueryHookOptions<GetSmsAccountQuery, GetSmsAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsAccountQuery, GetSmsAccountQueryVariables>(GetSmsAccountDocument, options);
      }
export function useGetSmsAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsAccountQuery, GetSmsAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsAccountQuery, GetSmsAccountQueryVariables>(GetSmsAccountDocument, options);
        }
export type GetSmsAccountQueryHookResult = ReturnType<typeof useGetSmsAccountQuery>;
export type GetSmsAccountLazyQueryHookResult = ReturnType<typeof useGetSmsAccountLazyQuery>;
export type GetSmsAccountQueryResult = Apollo.QueryResult<GetSmsAccountQuery, GetSmsAccountQueryVariables>;
export const GetMemberSmsDocument = gql`
    query getMemberSms($memberId: ID!, $limit: Int, $nextToken: String) {
  getMemberSms(memberId: $memberId, limit: $limit, nextToken: $nextToken) {
    memberSms {
      id
      body
      direction
      to
      sentAt
      deliveredAt
      openedAt
      clickedAt
      messageTemplateId
      templateEventId
    }
    nextToken
  }
}
    `;

/**
 * __useGetMemberSmsQuery__
 *
 * To run a query within a React component, call `useGetMemberSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberSmsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetMemberSmsQuery(baseOptions: Apollo.QueryHookOptions<GetMemberSmsQuery, GetMemberSmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberSmsQuery, GetMemberSmsQueryVariables>(GetMemberSmsDocument, options);
      }
export function useGetMemberSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberSmsQuery, GetMemberSmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberSmsQuery, GetMemberSmsQueryVariables>(GetMemberSmsDocument, options);
        }
export type GetMemberSmsQueryHookResult = ReturnType<typeof useGetMemberSmsQuery>;
export type GetMemberSmsLazyQueryHookResult = ReturnType<typeof useGetMemberSmsLazyQuery>;
export type GetMemberSmsQueryResult = Apollo.QueryResult<GetMemberSmsQuery, GetMemberSmsQueryVariables>;
export const TopUpSmsAccountDocument = gql`
    mutation topUpSmsAccount($input: SmsTopUpInput!, $clubId: ID!) {
  topUpSmsAccount(input: $input, clubId: $clubId) {
    smsAccount {
      balance
      clubId
      outboundCost
      inboundCost
      smsEnabled
      autoTopUpEnabled
      autoTopUpAmount
      smsNumberId
      autoTopUpWhenBelow
      smsNumberId
      paymentMethodId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type TopUpSmsAccountMutationFn = Apollo.MutationFunction<TopUpSmsAccountMutation, TopUpSmsAccountMutationVariables>;

/**
 * __useTopUpSmsAccountMutation__
 *
 * To run a mutation, you first call `useTopUpSmsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopUpSmsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topUpSmsAccountMutation, { data, loading, error }] = useTopUpSmsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useTopUpSmsAccountMutation(baseOptions?: Apollo.MutationHookOptions<TopUpSmsAccountMutation, TopUpSmsAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TopUpSmsAccountMutation, TopUpSmsAccountMutationVariables>(TopUpSmsAccountDocument, options);
      }
export type TopUpSmsAccountMutationHookResult = ReturnType<typeof useTopUpSmsAccountMutation>;
export type TopUpSmsAccountMutationResult = Apollo.MutationResult<TopUpSmsAccountMutation>;
export type TopUpSmsAccountMutationOptions = Apollo.BaseMutationOptions<TopUpSmsAccountMutation, TopUpSmsAccountMutationVariables>;
export const SetAutoTopUpDocument = gql`
    mutation setAutoTopUp($input: SmsAutoTopUpInput!, $clubId: ID!) {
  setAutoTopUp(input: $input, clubId: $clubId) {
    smsAccount {
      balance
      clubId
      outboundCost
      inboundCost
      smsEnabled
      autoTopUpEnabled
      autoTopUpAmount
      smsNumberId
      autoTopUpWhenBelow
      smsNumberId
      paymentMethodId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type SetAutoTopUpMutationFn = Apollo.MutationFunction<SetAutoTopUpMutation, SetAutoTopUpMutationVariables>;

/**
 * __useSetAutoTopUpMutation__
 *
 * To run a mutation, you first call `useSetAutoTopUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAutoTopUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAutoTopUpMutation, { data, loading, error }] = useSetAutoTopUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useSetAutoTopUpMutation(baseOptions?: Apollo.MutationHookOptions<SetAutoTopUpMutation, SetAutoTopUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAutoTopUpMutation, SetAutoTopUpMutationVariables>(SetAutoTopUpDocument, options);
      }
export type SetAutoTopUpMutationHookResult = ReturnType<typeof useSetAutoTopUpMutation>;
export type SetAutoTopUpMutationResult = Apollo.MutationResult<SetAutoTopUpMutation>;
export type SetAutoTopUpMutationOptions = Apollo.BaseMutationOptions<SetAutoTopUpMutation, SetAutoTopUpMutationVariables>;
export const SetSmsPaymentMethodDocument = gql`
    mutation setSmsPaymentMethod($companyPaymentMethodId: ID!, $clubId: ID!) {
  setSmsPaymentMethod(
    companyPaymentMethodId: $companyPaymentMethodId
    clubId: $clubId
  ) {
    smsAccount {
      balance
      clubId
      outboundCost
      inboundCost
      smsEnabled
      autoTopUpEnabled
      autoTopUpAmount
      smsNumberId
      autoTopUpWhenBelow
      smsNumberId
      paymentMethodId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type SetSmsPaymentMethodMutationFn = Apollo.MutationFunction<SetSmsPaymentMethodMutation, SetSmsPaymentMethodMutationVariables>;

/**
 * __useSetSmsPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetSmsPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSmsPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSmsPaymentMethodMutation, { data, loading, error }] = useSetSmsPaymentMethodMutation({
 *   variables: {
 *      companyPaymentMethodId: // value for 'companyPaymentMethodId'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useSetSmsPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetSmsPaymentMethodMutation, SetSmsPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSmsPaymentMethodMutation, SetSmsPaymentMethodMutationVariables>(SetSmsPaymentMethodDocument, options);
      }
export type SetSmsPaymentMethodMutationHookResult = ReturnType<typeof useSetSmsPaymentMethodMutation>;
export type SetSmsPaymentMethodMutationResult = Apollo.MutationResult<SetSmsPaymentMethodMutation>;
export type SetSmsPaymentMethodMutationOptions = Apollo.BaseMutationOptions<SetSmsPaymentMethodMutation, SetSmsPaymentMethodMutationVariables>;
export const SendSmsDocument = gql`
    mutation sendSms($memberId: ID!, $input: SendSmsInput!) {
  sendSms(memberId: $memberId, input: $input) {
    memberSms {
      id
      body
      direction
      to
      sentAt
      deliveredAt
      openedAt
      clickedAt
      messageTemplateId
      templateEventId
    }
    errors {
      type
      message
    }
  }
}
    `;
export type SendSmsMutationFn = Apollo.MutationFunction<SendSmsMutation, SendSmsMutationVariables>;

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsMutation, SendSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, options);
      }
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>;
export type SendSmsMutationResult = Apollo.MutationResult<SendSmsMutation>;
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<SendSmsMutation, SendSmsMutationVariables>;
export const GetMyTeamDocument = gql`
    query getMyTeam {
  getMyTeam {
    items {
      id
      displayName
      profilePictureUrl
      role
      email
      givenName
      familyName
      gender
    }
    nextToken
  }
}
    `;

/**
 * __useGetMyTeamQuery__
 *
 * To run a query within a React component, call `useGetMyTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetMyTeamQuery, GetMyTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyTeamQuery, GetMyTeamQueryVariables>(GetMyTeamDocument, options);
      }
export function useGetMyTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyTeamQuery, GetMyTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyTeamQuery, GetMyTeamQueryVariables>(GetMyTeamDocument, options);
        }
export type GetMyTeamQueryHookResult = ReturnType<typeof useGetMyTeamQuery>;
export type GetMyTeamLazyQueryHookResult = ReturnType<typeof useGetMyTeamLazyQuery>;
export type GetMyTeamQueryResult = Apollo.QueryResult<GetMyTeamQuery, GetMyTeamQueryVariables>;
export const UpdateMeStaffDocument = gql`
    mutation updateMeStaff($input: UpdateStaffInput!) {
  updateMeStaff(input: $input) {
    givenName
    familyName
    email
    mobileNumber
    profilePictureUrl
    gender
  }
}
    `;
export type UpdateMeStaffMutationFn = Apollo.MutationFunction<UpdateMeStaffMutation, UpdateMeStaffMutationVariables>;

/**
 * __useUpdateMeStaffMutation__
 *
 * To run a mutation, you first call `useUpdateMeStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeStaffMutation, { data, loading, error }] = useUpdateMeStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeStaffMutation, UpdateMeStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeStaffMutation, UpdateMeStaffMutationVariables>(UpdateMeStaffDocument, options);
      }
export type UpdateMeStaffMutationHookResult = ReturnType<typeof useUpdateMeStaffMutation>;
export type UpdateMeStaffMutationResult = Apollo.MutationResult<UpdateMeStaffMutation>;
export type UpdateMeStaffMutationOptions = Apollo.BaseMutationOptions<UpdateMeStaffMutation, UpdateMeStaffMutationVariables>;
export const GetStaffInvitesDocument = gql`
    query getStaffInvites {
  getStaffInvites {
    items {
      id
      email
      givenName
      role
      createdByName
      expiresAt
      disabledAt
      acceptedAt
      clubIds
    }
    nextToken
  }
}
    `;

/**
 * __useGetStaffInvitesQuery__
 *
 * To run a query within a React component, call `useGetStaffInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaffInvitesQuery(baseOptions?: Apollo.QueryHookOptions<GetStaffInvitesQuery, GetStaffInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffInvitesQuery, GetStaffInvitesQueryVariables>(GetStaffInvitesDocument, options);
      }
export function useGetStaffInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffInvitesQuery, GetStaffInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffInvitesQuery, GetStaffInvitesQueryVariables>(GetStaffInvitesDocument, options);
        }
export type GetStaffInvitesQueryHookResult = ReturnType<typeof useGetStaffInvitesQuery>;
export type GetStaffInvitesLazyQueryHookResult = ReturnType<typeof useGetStaffInvitesLazyQuery>;
export type GetStaffInvitesQueryResult = Apollo.QueryResult<GetStaffInvitesQuery, GetStaffInvitesQueryVariables>;
export const DisableStaffInviteDocument = gql`
    mutation disableStaffInvite($id: ID!) {
  disableStaffInvite(id: $id) {
    id
    email
    givenName
    createdByName
    expiresAt
    disabledAt
    acceptedAt
    clubIds
  }
}
    `;
export type DisableStaffInviteMutationFn = Apollo.MutationFunction<DisableStaffInviteMutation, DisableStaffInviteMutationVariables>;

/**
 * __useDisableStaffInviteMutation__
 *
 * To run a mutation, you first call `useDisableStaffInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableStaffInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableStaffInviteMutation, { data, loading, error }] = useDisableStaffInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableStaffInviteMutation(baseOptions?: Apollo.MutationHookOptions<DisableStaffInviteMutation, DisableStaffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableStaffInviteMutation, DisableStaffInviteMutationVariables>(DisableStaffInviteDocument, options);
      }
export type DisableStaffInviteMutationHookResult = ReturnType<typeof useDisableStaffInviteMutation>;
export type DisableStaffInviteMutationResult = Apollo.MutationResult<DisableStaffInviteMutation>;
export type DisableStaffInviteMutationOptions = Apollo.BaseMutationOptions<DisableStaffInviteMutation, DisableStaffInviteMutationVariables>;
export const AddStaffInviteDocument = gql`
    mutation addStaffInvite($input: AddStaffInviteInput!) {
  addStaffInvite(input: $input) {
    id
    email
    givenName
    createdByName
    expiresAt
    disabledAt
    acceptedAt
    clubIds
  }
}
    `;
export type AddStaffInviteMutationFn = Apollo.MutationFunction<AddStaffInviteMutation, AddStaffInviteMutationVariables>;

/**
 * __useAddStaffInviteMutation__
 *
 * To run a mutation, you first call `useAddStaffInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffInviteMutation, { data, loading, error }] = useAddStaffInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStaffInviteMutation(baseOptions?: Apollo.MutationHookOptions<AddStaffInviteMutation, AddStaffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStaffInviteMutation, AddStaffInviteMutationVariables>(AddStaffInviteDocument, options);
      }
export type AddStaffInviteMutationHookResult = ReturnType<typeof useAddStaffInviteMutation>;
export type AddStaffInviteMutationResult = Apollo.MutationResult<AddStaffInviteMutation>;
export type AddStaffInviteMutationOptions = Apollo.BaseMutationOptions<AddStaffInviteMutation, AddStaffInviteMutationVariables>;
export const RedeemStaffInviteDocument = gql`
    mutation redeemStaffInvite($id: ID!, $input: RedeemStaffInviteInput!) {
  redeemStaffInvite(id: $id, input: $input) {
    id
    email
    givenName
    createdByName
    expiresAt
    disabledAt
    acceptedAt
    clubIds
  }
}
    `;
export type RedeemStaffInviteMutationFn = Apollo.MutationFunction<RedeemStaffInviteMutation, RedeemStaffInviteMutationVariables>;

/**
 * __useRedeemStaffInviteMutation__
 *
 * To run a mutation, you first call `useRedeemStaffInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemStaffInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemStaffInviteMutation, { data, loading, error }] = useRedeemStaffInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemStaffInviteMutation(baseOptions?: Apollo.MutationHookOptions<RedeemStaffInviteMutation, RedeemStaffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemStaffInviteMutation, RedeemStaffInviteMutationVariables>(RedeemStaffInviteDocument, options);
      }
export type RedeemStaffInviteMutationHookResult = ReturnType<typeof useRedeemStaffInviteMutation>;
export type RedeemStaffInviteMutationResult = Apollo.MutationResult<RedeemStaffInviteMutation>;
export type RedeemStaffInviteMutationOptions = Apollo.BaseMutationOptions<RedeemStaffInviteMutation, RedeemStaffInviteMutationVariables>;